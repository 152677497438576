import React from 'react';
import {connect} from 'react-redux';

//->component imports
import RecentSearches from "./recentSearches/RecentSearches";
import Announcements from "./announcements/Announcements";
// import PostsAnalytics from "./posts/PostsAnalytics";
import Posts from "./posts/Posts";
import "./recentSearches/RecentSearches.css"
import UpcomingInterviews from './upcomingInterviews/UpcomingInterviews';


class Home extends React.Component {

    constructor(props){
        super(props);
      

        this.state = {
            isToggled: this.props.isToggled,
            
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                isToggled: nextProps.isToggled,

            });
        }
    }

    render(){
       
        return(
            <div className={"homeContainer"}>
                <div className={`homeContentsBox`}>
                    <div className={"gridBox"}>
                        <div>
                            <RecentSearches/>
                        </div>
                        <div>
                            <Announcements />
                            <Posts/>
                            <UpcomingInterviews/>
                        </div>
                    </div> 

                    {/*<div className={"gridBox"}>*/}
                    {/*    <div>*/}
                    {/*        <PostsAnalytics/>*/}
                    {/*    </div>*/}

                    {/*    <div>*/}
                    {/*        */}
                    {/*    </div>*/}
                    {/*</div>*/}
                 </div> 
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { recentSearches, recentSearchesLoading,isToggled } = state.home;
    const { profile, } = state.profile;

    return {
        profile,
        recentSearches,
        recentSearchesLoading,
        isToggled
    }
};

export default connect(mapStateToProps)(Home);