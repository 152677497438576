import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button, Modal, message } from 'antd';

import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class LinksCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            links: this.props.links,
            visible: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                links: nextProps.links
            });
        }
    }

    showModal() {
        this.setState({
            visible: true
        });
    }

    openLink(url) {
        window.open(url, "_blank");
    }

    handleOk = () => {
        this.setState({
            visible: false
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    renderLink() {
        const { links } = this.state;

        let processedResults = links.map(result => {

            const normalizeURL = (link) => {
                // Check if the URL starts with 'http://' or 'https://'
                if (!/^https?:\/\//i.test(link)) {
                    // Add 'https://' to the beginning of the URL
                    link = 'https://' + link;
                }
                return link;
            }
    
            const urlAddress = normalizeURL(result.url);

            return { urlAddress, title: result.title };
        });


        const columns = [
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                key: 'currentlyServing',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Url</CustomHeaderCard>,
                dataIndex: 'urlAddress',
                key: 'urlAddress',
                render: (_, record) => (
                    <FontAwesomeIcon
                        icon="eye"
                        color="#0793d9"
                        size="1x"
                        onClick={() => this.openLink(record.urlAddress)}
                        style={{ cursor: "pointer" }}
                    />
                ),
            },
        ];

        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" pagination={false} />
        )
    }

    render() {
        return <div>{this.renderLink()}</div>;
    }
}

export default LinksCard;