import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Table, Button, Modal, message } from 'antd';

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";
import DescriptionModal from './DescriptionModal';

class MilitaryCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            military: this.props.military,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                military: nextProps.military,
            });
        }
    }

    renderMilitary(){
        const { military } = this.state;

        let processedResults = military.map(result => {

            let seconds;
            result.serveFrom.seconds ? seconds = result.serveFrom.seconds : seconds = result.serveFrom._seconds;
            const serveFromMoment = moment.unix(seconds);
    
            let toSeconds;
            result.serveTo.seconds ? toSeconds = result.serveTo.seconds : toSeconds = result.serveTo._seconds;
            const serveToMoment = moment.unix(toSeconds);
    
            //compute the number of days
            const serveFromString = serveFromMoment.format('DD MMM YYYY');
            const serveToString = serveToMoment.format('DD MMM YYYY');



            return { ...result, serveFromString, serveToString };
        });

        const columns = [
            {
                title: <CustomHeaderCard>Current</CustomHeaderCard>,
                key: 'currentlyServing',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.currentlyServing ? "Yes" : "No"}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Branch</CustomHeaderCard>,
                dataIndex: 'branch',
                key: 'branch',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.branch}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Rank</CustomHeaderCard>,
                dataIndex: 'rank',
                key: 'rank',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.rank}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Description</CustomHeaderCard>,
                dataIndex: 'description',
                key: 'description',
                render: (_, record) => (
                    <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        <DescriptionModal record={record}/>
                    </div>
                ),
            },
            {
                title: <CustomHeaderCard>Location</CustomHeaderCard>,
                dataIndex: 'location.country',
                key: 'location.country',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.location.country}</div>
                ),
            },
            {
                title: <CustomHeaderCard>From</CustomHeaderCard>,
                dataIndex: 'serveFromString',
                key: 'serveFromString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.serveFromString}</div>
                ),
            },
            {
                title: <CustomHeaderCard>To</CustomHeaderCard>,
                key: 'serveToString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.currentlyServing ? "Currently Serving" : record.serveToString}</div>
                ),
            },
        ];

        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderMilitary()}
            </div>
        );
    }
}

export default MilitaryCard;