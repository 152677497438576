import React from 'react';
import { Modal, Button, Typography } from 'antd';

const { Text } = Typography;

const ProfileModal = ({ visible, onCancel, onConfirm }) => {
    return (
        <Modal 
            title={<div className="custom-modal-title">Profile Information</div>}
            visible={visible} 
            onCancel={onCancel} 
            footer={null} 
            centered
        >
            <div className="modal-content">
                <Text className="modal-text" style={{ color: '#000' }}>Your profile information is incomplete. Please fill out all necessary details.</Text>
                <Button className="confirm-button" onClick={onConfirm}>Go to Profile Details</Button>
            </div>
        </Modal>
    );
};

export default ProfileModal;
