import React, { useState } from "react";
import { Modal, Typography } from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Text } = Typography;

const DescriptionModal = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderText = () => {
        return (
            <FontAwesomeIcon icon={"eye"} size={"1x"} color="#0793d9" style={{ cursor: "pointer"}} onClick={handleToggleModal}/>
        );
    };

    return (
        <div>
            {renderText()}
            <Modal
                title="Payment"
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={900}
            >
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Channel:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.channel ? "Reach Out" : "Don't Reach Out"} </p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Amount:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.amount}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Phone:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.phoneNumber}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Reference ID:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.reference}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Trans ID:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.transid}</p>
                </div>
            </Modal>
        </div>
    );
};

export default DescriptionModal;