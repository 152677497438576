import React  from 'react';
import { connect } from 'react-redux';
//import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//component import
import PostCard from "../cards/PostCard";

//-> action imports
import {Spinner} from "../../common/Spinner";
import { News01Icon } from 'hugeicons-react';

class Posts extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
            posts: this.props.posts,
            postsLoading: this.props.postsLoading,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState ({
                profile: nextProps.profile,
                posts: nextProps.posts,
                postsLoading: nextProps.postsLoading,
            });
        }
    }

    renderPosts() {
        const { posts, postsLoading } = this.state;

        if (postsLoading) {
            return <Spinner/>
        } else {
            if (posts.length === 0) {
                return <h5 className={"adminAlert"}>There are currently no posts at the moment</h5>;
            } else {
                // limit fetched
                const subPosts = posts.slice(0, 4);
                return(
                    <div>
                        {
                            subPosts.map(post => {
                                return(
                                    <div key={post.postID}>
                                        <PostCard post={post}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        }
    }


    render(){
        return (
            <div className={"parentContainer"}>
                <div className={"notificationHeaderBox"}>
                    <h4><News01Icon size={30} /> Posts</h4>
                    <NavLink to={"/posts"}>
                        <div className={"notificationHeaderBoxInside"}>
                            <h4>
                                View All Posts
                            </h4>
                            <h5><FontAwesomeIcon icon={"caret-right"} style={{color: '#fff', fontSize: '1.35rem', margin: "0.3rem 0 0 0.5rem"}} /></h5>
                        </div>
                    </NavLink>
                </div>
                <div className={"trainingListHeader"}>

                </div>

                {/* Here render / map all companyTrainings (list) */}
                <div className={"trainingListHome"}>
                    {this.renderPosts()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    //-> fetch state properties from specific reducer
    const {posts, postsLoading} = state.posts;
    const {profile} = state.profile;

    return {
        posts,
        postsLoading,
        profile,
    }
};


export default connect(mapStateToProps, {} )(Posts)
