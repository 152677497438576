//Types import
import {
    CHANGE_DETAILS,
    SUBSCRIPTION_FIELD_UPDATED,

    //add subscription package
    FETCH_MONTHS,
    FETCH_MONTHS_SUCCESSFUL,
    FETCH_MONTHS_FAILED,
    FETCH_PAYMENT_STATUS,
    FETCH_PAYMENT_STATUS_SUCCESSFUL,
    FETCH_PAYMENT_STATUS_FAILED,
    FETCH_PAYMENTS_PACKAGES,
    FETCH_PAYMENTS_PACKAGES_SUCCESSFUL,
    FETCH_PAYMENTS_PACKAGES_FAILED,
    FETCH_WALLET_CARRIERS,
    FETCH_WALLET_CARRIERS_SUCCESSFUL,
    FETCH_WALLET_CARRIERS_FAILED,
    MAKE_PAYMENT,
    MAKE_PAYMENT_SUCCESSFUL,
    MAKE_PAYMENT_FAILED,
    FETCH_PAYMENTS,
    FETCH_PAYMENTS_FAILED,
    FETCH_PAYMENTS_SUCCESSFUL,
    MAKE_FOREIGN_PAYMENT,
    MAKE_FOREIGN_PAYMENT_SUCCESSFUL, MAKE_FOREIGN_PAYMENT_FAILED
} from '../actions/Types';


const INITIAL_STATE = {
    paymentPackages: [],
    paymentPackagesLoading: false,

    myPayments: [],
    myPaymentsLoading: false,

    walletCarriers: [],
    walletCarriersLoading: false,
    carrier: "",

    months: [],
    monthsLoading: false,
    paymentMonths: "",
    paymentPhone: "",

    paymentPackage: {},
    makePaymentLoading: false,

    transID: "",

    payment: {},
    paymentLoading: false,

    payments: [],
    paymentsLoading: false,

    flwUrl: "",
    packageTitle: ""
};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const SubscriptionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_DETAILS:
            return {...state, [action.payload.prop] : action.payload.value }

        case SUBSCRIPTION_FIELD_UPDATED:
            return { ...state, [action.payload.prop]: action.payload.value };


        case FETCH_MONTHS:
            return { ...state, monthsLoading: true };
        case FETCH_MONTHS_SUCCESSFUL:
            return { ...state, monthsLoading: false, months: deepClone(action.payload)};
        case FETCH_MONTHS_FAILED:
            return { ...state, monthsLoading: false };

        //make payment
        case MAKE_PAYMENT:
            return { ...state, makePaymentLoading: true, loading: true, paymentLoading: true };
        case MAKE_PAYMENT_SUCCESSFUL:
            return { ...state, makePaymentLoading: false, loading: false, transID: action.payload };
        case MAKE_PAYMENT_FAILED:
            return { ...state, makePaymentLoading: false, loading: false };

        case MAKE_FOREIGN_PAYMENT:
            return { ...state, makePaymentLoading: true };
        case MAKE_FOREIGN_PAYMENT_SUCCESSFUL:
            return { ...state, makePaymentLoading: false, flwUrl: action.payload, payment: action.payment };
        case MAKE_FOREIGN_PAYMENT_FAILED:
            return { ...state, makePaymentLoading: false };

        case FETCH_PAYMENT_STATUS:
            return { ...state, paymentLoading: true };
        case FETCH_PAYMENT_STATUS_SUCCESSFUL:
            return { ...state, paymentLoading: false, payment: deepClone(action.payload) };
        case FETCH_PAYMENT_STATUS_FAILED:
            return { ...state, paymentLoading: false };

        case FETCH_PAYMENTS:
            return { ...state, paymentsLoading: true };
        case FETCH_PAYMENTS_SUCCESSFUL:
            return { ...state, paymentsLoading: false, payments: deepClone(action.payload) };
        case FETCH_PAYMENTS_FAILED:
            return { ...state, paymentsLoading: false };

        case FETCH_PAYMENTS_PACKAGES:
            return { ...state, paymentPackagesLoading: true };
        case FETCH_PAYMENTS_PACKAGES_SUCCESSFUL:
            return { ...state, paymentPackagesLoading: true, paymentPackages: deepClone(action.payload) };
        case FETCH_PAYMENTS_PACKAGES_FAILED:
            return { ...state, paymentPackagesLoading: false };

        case FETCH_WALLET_CARRIERS:
            return { ...state, walletCarriersLoading: true };
        case FETCH_WALLET_CARRIERS_SUCCESSFUL:
            return { ...state, walletCarriersLoading: false, walletCarriers: deepClone(action.payload) };
        case FETCH_WALLET_CARRIERS_FAILED:
            return { ...state, walletCarriersLoading: false };


        default:
            return state;
       
    }

}

export default SubscriptionReducer;