import React, { useEffect, useState } from 'react';
import { Modal, Typography, Button, Row, Col } from 'antd';
import { VerifiedOutlined } from '@ant-design/icons';
import firebase from 'firebase'; // Make sure to import the correct Firebase module

const { Text } = Typography;

const VerifyEmail = () => {
    const [showModal, setShowModal] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [text, setText] = useState('Please verify your email');
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(null);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // Check if the user's email is verified
                if (!user.emailVerified) {
                    setShowModal(true);
                    const userEmail = user.email;
                    setEmail(userEmail);
                }
            }
        });

        // Cleanup the event listener when component unmounts
        return () => unsubscribe();
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleResendVerificationEmail = async () => {
        try {
            setResendLoading(true);
            await firebase.auth().currentUser.sendEmailVerification();
            setText('Verification Email Sent!');
            setEmailSent(true);
            // Show a message to the user indicating that the verification email has been sent.
        } catch (error) {
            console.log('Error sending verification email:', error);
            // Handle error in case the email could not be sent.
        } finally {
            setResendLoading(false);
        }
    };

    return (
        <Modal
            open={showModal}
            title={
                <>
                    <VerifiedOutlined style={{ marginRight: 10 }} />
                    Email Verification
                </>
            }
            onCancel={handleCloseModal}
            footer={null}
        >
            <Text style={{ textAlign: 'center', display: 'block', marginBottom: 10 }}>
                {text}
            </Text>
            {email && (
                <Text style={{ textAlign: 'center', display: 'block', color: '#ffb400', marginBottom: 10 }}>
                    {email}
                </Text>
            )}
            {emailSent ? (
                <Row justify="center">
                    <Col>
                        <Button type="primary" onClick={handleCloseModal} disabled={resendLoading}>
                            Close
                        </Button>
                    </Col>
                </Row>
            ) : (
                <>
                    {resendLoading ? (
                        <Row justify="center">
                            <Col>
                                <Button type="primary" loading>
                                    Sending Verification Email
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row justify="center">
                            <Col>
                                <Button type="primary" onClick={handleResendVerificationEmail}>
                                    Send Verification Email
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row justify="center">
                        <Col>
                            <Button onClick={handleCloseModal} disabled={resendLoading}>
                                Not now
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Modal>
    );
};

export { VerifyEmail };
