import { useEffect } from 'react';

export const useGoogleTagManager = (gtagId) => {
	useEffect(() => {
		// Create script to load gtag.js
		const script = document.createElement('script');
		script.async = true;
		script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
		document.head.appendChild(script);

		// Initialize dataLayer and gtag function
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		window.gtag = gtag;
		gtag('js', new Date());
		gtag('config', gtagId);

		// Cleanup function to remove script when component unmounts
		return () => {
			document.head.removeChild(script);
		};
	}, [gtagId]);
};
