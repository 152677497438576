import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//-> file imports
import logo from '../assets/logos/logo.png';

class IsMobile extends React.Component {
    render() {
        return (
            <div className={"mobileUnavailableContainer"}>
                <div>
                    <div className={"mobileUnavailableLogoArea"}>
                        <img src={logo} alt="Nafasi Logo" />
                    </div>

                    <div>
                        <h4>This system is unavailable on mobile devices!</h4>
                    </div>

                    <div>
                        <p>Use your personal computer or desktop.</p>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={"laptop"} size={"2x"} color={"#fbfbfb"}/>
                        <FontAwesomeIcon icon={"desktop"} size={"2x"} color={"#fbfbfb"} />
                    </div>
                </div>
            </div>
        );
    }
}

export default IsMobile;