import React from 'react'
import { HandPointingDown01Icon, LayoutLeftIcon, SkewIcon, Tick01Icon, UnfoldLessIcon, WorkflowCircle01Icon } from 'hugeicons-react'

// File imports
import './Benefits.css'
import interview from "../../../../src/assets/images/interview.svg"
import candidate from "../../../../src/assets/images/candidates2.svg"

export default function BenefitsPage() {
  return (
   
<div className="page">
			<h1 style={{textAlign:"center", color:"#ffff", marginBottom:"50px"}}>Perks for Recruiters and Job Applicants</h1>			
				
			
			<div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
				<input type="radio" name="pcss3t" checked  id="tab1"class="tab-content-first"/>
				<label for="tab1">RECRUITER <WorkflowCircle01Icon size={30} color='black' />  </label>
				
				<input type="radio" name="pcss3t" id="tab2" class="tab-content-2"/>
				<label for="tab2">JOB APPLICANT  <SkewIcon size={30} color='black' /> </label>
				
				
				
				<ul>
					<li class="tab-content tab-content-first typography">
						<h1 style={{textAlign:"center", marginBottom:"10px"}}>RECRUITER</h1>
                        <div className='recruiterContainer'>
                        <div className='recruiterTextContainer'>
                                <p> <Tick01Icon/> Advanced Search Filters</p>
                                <p> <Tick01Icon/> Insightful Analytics</p>
                                <p> <Tick01Icon/> Detailed Candidate Profiles</p>
                                <p> <Tick01Icon/> Interview Scheduling</p>
                                <p> <Tick01Icon/> Detailed Candidate Profiles</p>
                                <p> <Tick01Icon/> Auto-Generated Job Posters</p>
                            </div>
                            <img src={interview} alt="" srcset="" className="recruiterImage"  />
                        </div>


						
					</li>
					
					<li class="tab-content tab-content-2 typography">
                    <h1 style={{textAlign:"center", marginBottom:"10px"}}>JOB APPLICANT</h1>
                        <div className='recruiterContainer'>
                        <div className='recruiterTextContainer'>
                                <p> <Tick01Icon/> Visibility to Employers </p>
                                <p> <Tick01Icon/> Resume Feedback & Guidance</p>
                                <p> <Tick01Icon/> Customized Job Alerts</p>
                                <p> <Tick01Icon/> Insightful Analytics</p>
                                <p> <Tick01Icon/> Assessment Ratings</p>
                                <p> <Tick01Icon/> Effortless Application Process</p>
                            </div>
                            <img src={candidate} alt="" srcset="" className="recruiterImage"  />
                        </div>

					
					</li>
					
					
				</ul>
			</div>
			
		</div>
  )
}
