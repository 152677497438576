import React, { useState } from "react";
import { Modal, Typography } from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import PDFCV from "../PDFCV";

const { Text } = Typography;

const DescriptionModal = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderText = () => {
        return (
            <FontAwesomeIcon icon={"eye"} size={"1x"} color="#0793d9" style={{ cursor: "pointer"}} onClick={handleToggleModal}/>
        );
    };

    return (
        <div>
            {renderText()}
            <Modal
                title="Assessment"
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={900}
            >
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Name:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.firstName} {record.lastName}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Email:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.email}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Phone:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.phone}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Gender:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.gender}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Location:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.location.region}, {record.location.country}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Birth Date:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.birthday}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Attended:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.attended ? "Yes" : "No"}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Log Date:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.time}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Nafasi CV:</p>
                    <PDFCV candidate={record}/>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Uploaded CV:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}} onClick={() => window.open(`${record.cv}`,'name','width=1500,height=800')}><FontAwesomeIcon icon="eye" color="#3294ff" size="1x" /></p>
                </div>
            </Modal>
        </div>
    );
};

export default DescriptionModal;