//Types import
import {
    FETCH_ALL_TICKETS_FOR_REPORT,
    FETCH_ALL_TICKETS_FOR_REPORT_FAILED,
    FETCH_ALL_TICKETS_FOR_REPORT_SUCCESSFUL,
    FETCH_INDUSTRY_GENDER_COUNTS_SUCCESS,
    FETCH_GENDER_INTERVIEWS_DISTRIBUTION,
    FETCH_JOB_POST_STATS,
    FETCH_JOB_POST_STATS_SUCCESSFUL,
    FETCH_COMMON_SKILLS,
    FETCH_COMMON_SKILLS_SUCCESS,
    UPDATE_CANDIDATE_COUNTS,
    FETCH_CANDIDATE_COUNTS,
    FETCH_AVERAGE_TIME_TO_HIRE,
    FETCH_CANDIDATE_GENDER,
    SET_AVERAGE_RESPONSE_TIME,
    FETCH_AVERAGE_RESPONSE_TIME
} from "../actions/Types";

const INITIAL_STATE = {

    supportTickets: {},
    leaveRequests: {},
    industryCounts:{},
    commonSkills: {
        mostCommonSkills: [],
        leastCommonSkills: []
    },
    jobCounts:null,
    postsPerPosition:[],
    interviewedCandidates:null,
    offeredInterviews:null,
    fetchingGenderInfo:false,
    fetchingCommonSkills:false,
    fetchingCandidatesCount:false,
    isFetchingTime:false,
    generalAverageTimeToHire: 0,
    candidatesGender: [],
    averageResponseTime:0

};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const ReportReducer = (state = INITIAL_STATE, action) => {
    //
    switch (action.type) {
        //Support tickets
        case FETCH_ALL_TICKETS_FOR_REPORT:
            return {...state, };
        case FETCH_ALL_TICKETS_FOR_REPORT_SUCCESSFUL:
            return {...state, supportTickets: deepClone(action.payload)};
        case FETCH_ALL_TICKETS_FOR_REPORT_FAILED:
            return {...state, };
        case FETCH_INDUSTRY_GENDER_COUNTS_SUCCESS:
            return {...state,industryCounts:deepClone(action.payload) , fetchingGenderInfo:false };
        case FETCH_COMMON_SKILLS:
            return {...state,fetchingCommonSkills:true};
        case FETCH_COMMON_SKILLS_SUCCESS:
            return {...state,commonSkills: deepClone(action.payload), fetchingCommonSkills:false};

        case FETCH_JOB_POST_STATS_SUCCESSFUL: 

        return {...state,jobCounts: deepClone(action.payload.jobCount), postsPerPosition: deepClone(action.payload.postsPerPosition)};

        case FETCH_CANDIDATE_COUNTS:
            return {...state,fetchingCandidatesCount:true};
        case UPDATE_CANDIDATE_COUNTS:
            return {...state,fetchingCandidatesCount:false,interviewedCandidates:action.payload.interviewedCount, offeredInterviews: action.payload.totalCount};

        case FETCH_GENDER_INTERVIEWS_DISTRIBUTION:
            return {...state,fetchingGenderInfo: true};

        case FETCH_AVERAGE_TIME_TO_HIRE:
            return {...state, generalAverageTimeToHire: deepClone(action.payload)}
        case FETCH_CANDIDATE_GENDER:
            return {...state, candidatesGender:deepClone(action.payload)} 
        case FETCH_AVERAGE_RESPONSE_TIME:
            return {...state, isFetchingTime: true}   
        case SET_AVERAGE_RESPONSE_TIME:
            return {...state, averageResponseTime: action.payload, isFetchingTime: false}  
        default:
            return state;
    }
};

export default ReportReducer;