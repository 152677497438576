
import React from 'react';
import { Line } from '@ant-design/charts';
import { Spinner } from '../../common/Spinner';

const LineGraph = ({ mostCommonSkills, fetchingCommonSkills }) => {
  // Format the data in the required format
  const data = mostCommonSkills.map(({ skillTitle, count }) => ({
    skillTitle: skillTitle,
    count: count,
  }));

  const config = {
    data: data,
    xField: 'skillTitle',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  };

  return(
    <div >
    {fetchingCommonSkills? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner/> 
      </div>
    ) : (
      <>
        <h3 style={{ textAlign: "center" }}>Most Common Skills Distribution Among Candidates</h3>
        <Line {...config} /> 
      </>
    )}
  </div>
  );
};

export default LineGraph;
