import React from "react";
import moment from "moment";
import "./../Education.css"
import { Table, Button, Modal, message } from 'antd';


//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class EducationCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            education: this.props.education,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                education: nextProps.education,
            });
        }
    }

    renderEducation(){
        const { education } = this.state;

        let processedResults = education.map(result => {

            let seconds;
            result.timeOfStudyFrom.seconds ? seconds = result.timeOfStudyFrom.seconds : seconds = result.timeOfStudyFrom._seconds;
            const studyFrom = moment.unix(seconds);

            let toSeconds;
            result.timeOfStudyTo.seconds ? toSeconds = result.timeOfStudyTo.seconds : toSeconds = result.timeOfStudyTo._seconds;
            const studyTo = moment.unix(toSeconds);

            const studyFromString = studyFrom.format('DD MMM YYYY');
            const studyToString = studyTo.format('DD MMM YYYY');

            const formattedLocation = `${result.location.country}, ${result.location.region}`;


            return { ...result, studyFromString, studyToString, formattedLocation };
        });

        const columns = [
            {
                title: <CustomHeaderCard>Enrolled</CustomHeaderCard>,
                dataIndex: 'currentEnrollment',
                key: 'currentEnrollment',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.currentEnrollment ? "Yes" : "No"}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Institution</CustomHeaderCard>,
                dataIndex: 'currentEnrollment',
                key: 'currentEnrollment',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.institution}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Level</CustomHeaderCard>,
                dataIndex: 'educationLevel.title',
                key: 'educationLevel.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.educationLevel.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Field</CustomHeaderCard>,
                dataIndex: 'educationField',
                key: 'educationField',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.educationField}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Industry</CustomHeaderCard>,
                dataIndex: 'industry.title',
                key: 'industry.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.industry.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Location</CustomHeaderCard>,
                dataIndex: 'formattedLocation',
                key: 'formattedLocation',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.formattedLocation}</div>
                ),
            },
            {
                title: <CustomHeaderCard>From</CustomHeaderCard>,
                dataIndex: 'studyFromString',
                key: 'studyFromString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.studyFromString}</div>
                ),
            },
            {
                title: <CustomHeaderCard>To</CustomHeaderCard>,
                key: 'studyToString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.currentEnrollment ? "Pursuing" : record.studyToString}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Completed</CustomHeaderCard>,
                key: 'studyToString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.yearOfCompletion}</div>
                ),
            },
        ];


        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderEducation()}
            </div>
        );
    }
}

export default EducationCard;