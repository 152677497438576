import React from "react";
import moment from "moment";
import { Table, Button, Modal, message } from 'antd';

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class LicensesCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            licenses: this.props.licenses,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                licenses: nextProps.licenses,
            });
        }
    };


    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderLicense(){
        const { licenses } = this.state;

        let processedResults = licenses.map(result => {

            let seconds;
            result.expirationDate.seconds ? seconds = result.expirationDate.seconds : seconds = result.expirationDate._seconds;
            const expiryMoment = moment.unix(seconds);
    
            const expiryString = expiryMoment.format('DD MMM YYYY');

            return { ...result, expiryString }
        });

        const columns = [
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'licenseCategory.title',
                key: 'licenseCategory.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.licenseCategory.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Expires</CustomHeaderCard>,
                dataIndex: 'expires',
                key: 'expires',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.expires ? "Yes" : "Lifetime"}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Expiry Date</CustomHeaderCard>,
                dataIndex: 'expires',
                key: 'expires',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.expires ? record.expiryString : "No expiry date"}</div>
                ),
            }
        ];

        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" pagination={false} />
        )
    }

    render() {
        return(
            <div>
                {this.renderLicense()}
            </div>
        );
    }
}

export default LicensesCard;