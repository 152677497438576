import axios from 'axios';
import firebase from "firebase";
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

import {
    FETCH_ALL_TICKETS_FOR_REPORT, 
    FETCH_ALL_TICKETS_FOR_REPORT_FAILED, 
    FETCH_ALL_TICKETS_FOR_REPORT_SUCCESSFUL,
    FETCH_SUPPORT_TICKETS,
    FETCH_SUPPORT_TICKETS_FAILED,
    FETCH_SUPPORT_TICKETS_SUCCESSFUL,
    FETCH_INDUSTRY_GENDER_COUNTS_SUCCESS,
    FETCH_COMMON_SKILLS,
    FETCH_COMMON_SKILLS_SUCCESS,
    FETCH_JOB_POST_STATS,
    FETCH_JOB_POST_STATS_SUCCESSFUL,
    UPDATE_CANDIDATE_COUNTS,
    FETCH_GENDER_INTERVIEWS_DISTRIBUTION,
    FETCH_CANDIDATE_COUNTS,
    FETCH_CANDIDATE_GENDER,
    FETCH_AVERAGE_TIME_TO_HIRE,
    SET_AVERAGE_RESPONSE_TIME,
    FETCH_AVERAGE_RESPONSE_TIME
} from "./Types";
//Types import

const XLSX = require('xlsx');


//------------------------------------------------------------------ SUPPORT REPORTS -----------------------------------------------------------------------------------------------------------

export const fetchAllSupportTicket = ({locationID}) => {

    return (dispatch) => {
        dispatch({ type: FETCH_ALL_TICKETS_FOR_REPORT });

        let supportTickets = {};

        firebase.firestore().collection("supportTickets").where("locationID", "==", locationID)
            .onSnapshot(function(querySnapshot) {
                
                querySnapshot.forEach(function(doc) {
                    supportTickets[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_ALL_TICKETS_FOR_REPORT_SUCCESSFUL,
                    payload: supportTickets
                });

            }, function (error) {
                console.log("Error fetching support tickets");
                console.log(error);
                dispatch({ type: FETCH_ALL_TICKETS_FOR_REPORT_FAILED });
            });
    }
};

export const fetchSupportTicket = () => {

    return (dispatch) => {
        dispatch({ type: FETCH_ALL_TICKETS_FOR_REPORT });

        let supportTickets = {};

        firebase.firestore().collection("supportTickets")
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {
                    supportTickets[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_ALL_TICKETS_FOR_REPORT_SUCCESSFUL,
                    payload: supportTickets
                });

            }, function (error) {
                console.log("Error fetching support tickets");
                console.log(error);
                dispatch({ type: FETCH_ALL_TICKETS_FOR_REPORT_FAILED });
            });
    }
};



export const fetchGenderBasedInterviewOffersByIndustry = () => {
    return (dispatch) => {
         dispatch({ type: FETCH_GENDER_INTERVIEWS_DISTRIBUTION});
                  
                   
        let interviewees = [];

        firebase.firestore().collection("jobPosts")
            .onSnapshot(function(querySnapshot) {
                // Clear the interviewees array before populating it again
                interviewees = [];

                // Create an array of promises to fetch candidates from all job posts
                let promises = [];

                querySnapshot.forEach(function(doc) {
                    // Get each jobPostId
                    const jobPostId = doc.id;

                    // Add the promise to fetch candidates from this job post
                    let promise = firebase.firestore().collection("jobPosts")
                        .doc(jobPostId)
                        .collection("candidates")
                        .get()
                        .then(function(candidatesSnapshot) {
                            candidatesSnapshot.forEach(function(candidateDoc) {
                                const candidateData = candidateDoc.data().userID;
                                interviewees.push(candidateData);
                            });
                        })
                        .catch(function(error) {
                            console.log("Error fetching candidates:", error);
                        });

                    promises.push(promise);
                });

                // Wait for all promises to resolve
                Promise.all(promises).then(() => {
                    // Dispatch an action with the fetched interviewees data
                    // dispatch({
                    //     type: 'FETCH_INTERVIEWEES_SUCCESS',
                    //     payload: interviewees
                    // });

                 
                    findIndustryByGender(interviewees, dispatch)

                });
            }, function (error) {
                console.log("Error fetching job posts:", error);
            });
    }
};


function findIndustryByGender(interviewees, dispatch) {
    let industryCounts = {};

    let genderPromises = interviewees.map(intervieweeID => {
        return firebase.firestore().collection("seekers").doc(intervieweeID).get()
            .then(seekerDoc => {
                if (seekerDoc.exists) {
                    let gender = seekerDoc.data().gender;

                    // Fetch industry from the education subcollection
                    return firebase.firestore().collection("seekers").doc(intervieweeID).collection("education").get()
                        .then(educationSnapshot => {
                            educationSnapshot.forEach(educationDoc => {
                                let industry = educationDoc.data().industry.title;

                                if (!industryCounts[industry]) {
                                    industryCounts[industry] = { male: 0, female: 0 };
                                }

                                if (gender === 'Male') {
                                    industryCounts[industry].male += 1;
                                } else if (gender === 'Female') {
                                    industryCounts[industry].female += 1;
                                }
                            });
                        });
                }
            })
            .catch(error => {
                console.log("Error fetching seeker or industry:", error);
            });
    });

    // Wait for all gender and industry fetches to complete
    Promise.all(genderPromises).then(() => {
        // Dispatch an action with the industry counts
        dispatch({
            type: FETCH_INDUSTRY_GENDER_COUNTS_SUCCESS,
            payload: industryCounts
        });

      
    });
}


export const findCommonSkills = () => {
    return (dispatch) => {
    
        dispatch({
            type: FETCH_COMMON_SKILLS,
        });

    let skillCounts = {};

    firebase.firestore().collection("seekers")
        .get()
        .then(querySnapshot => {
            let promises = [];

            querySnapshot.forEach(doc => {
                let candidateId = doc.id;

                let promise = firebase.firestore().collection("seekers")
                    .doc(candidateId)
                    .collection("skills")
                    .get()
                    .then(skillsSnapshot => {
                        skillsSnapshot.forEach(skillDoc => {
                            let skillID = skillDoc.data().skillID;
                            let skillTitle = skillDoc.data().title;

                            if (skillID && skillTitle) {
                                if (!skillCounts[skillID]) {
                                    skillCounts[skillID] = { title: skillTitle, count: 0 };
                                }
                                skillCounts[skillID].count += 1;
                            }
                        });
                    })
                    .catch(error => {
                        console.log("Error fetching skills:", error);
                    });

                promises.push(promise);
            });

            return Promise.all(promises);
        })
        .then(() => {

        
            let commonSkills = Object.keys(skillCounts).map(skillID => {
                return {
                    skillID: skillID,
                    skillTitle: skillCounts[skillID].title,
                    count: skillCounts[skillID].count
                };
            });

            let mostCommonSkills = commonSkills.slice(0, 10).sort((a, b) => b.count - a.count);

            // Sort skills by count in ascending order for the second top 10
            let leastCommonSkills = commonSkills.slice(0, 10).sort((a, b) => a.count - b.count);

            // Dispatch an action with both lists of common skills
            dispatch({
                type: FETCH_COMMON_SKILLS_SUCCESS,
                payload: { mostCommonSkills: mostCommonSkills, leastCommonSkills: leastCommonSkills }
            });
              
                       
        })
        .catch(error => {
            console.log("Error fetchingcandidates:", error);
        });
    }
};


export const fetchJobPostStats = () => {
    let jobTitles = {};
    let posts = {}

    return (dispatch) => {
         dispatch({ type: FETCH_JOB_POST_STATS});
        firebase.firestore().collection('jobPosts')
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    const jobTitle = doc.data().postTitle;
                    posts[doc.id] = doc.data();
                    // If jobTitle exists, increment count by 1; otherwise, initialize count to 1
                    jobTitles[jobTitle] = (jobTitles[jobTitle] || 0) + 1;
                });

                // Convert object to array of objects
                let jobTitlesArray = Object.keys(jobTitles).map((title) => {
                    return { jobTitle: title, count: jobTitles[title] };
                });

                // Sort array in descending order based on count
                jobTitlesArray.sort((a, b) => b.count - a.count);

                const data = jobTitlesArray.slice(0,10)
                const numberOfItems = Object.keys(posts).length;

                dispatch({ type: FETCH_JOB_POST_STATS_SUCCESSFUL, payload:{ jobCount: numberOfItems, postsPerPosition: data } });

            }, function(error) {
                console.log("Error fetching all job posts:", error);
            });
    }
}


export const fetchInterviewsInfo = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_CANDIDATE_COUNTS,
          
        });
        let totalCount = 0;
        let interviewedCount = 0;

        firebase.firestore().collection("jobPosts")
            .onSnapshot((jobPostsSnapshot) => {
                totalCount = 0; // Reset the total count every time the snapshot updates
                interviewedCount = 0; // Reset the interviewed count every time the snapshot updates

                jobPostsSnapshot.forEach((jobPostDoc) => {
                    const jobPostId = jobPostDoc.id;

                    firebase.firestore().collection("jobPosts").doc(jobPostId).collection("candidates")
                        .onSnapshot((candidatesSnapshot) => {
                            totalCount += candidatesSnapshot.size; // Add the number of candidates in this subcollection
                            
                            candidatesSnapshot.forEach((candidateDoc) => {
                                const candidateData = candidateDoc.data();
                                if (candidateData.interviewed) {
                                    interviewedCount += 1;
                                }
                            });
                            dispatch({
                                type: UPDATE_CANDIDATE_COUNTS,
                                payload: {
                                    totalCount,
                                    interviewedCount
                                }
                            });
                        });
                });
            });
    };
};


export const fetchInterviewsGradingRatio = () => {
    return (dispatch) => {
        const seekersRef = firebase.firestore().collection("seekers");
        seekersRef.onSnapshot((querySnapshot) => {
            let totalScores = {
                adaptability: 0,
                creativity: 0,
                analyticalThinking: 0,
                decisionMaking: 0,
                emotionalIntelligence: 0,
                initiative: 0,
                leadership: 0,
                teamwork: 0,
                professionalism: 0,
                workEthics: 0,
                verbalCommunication: 0,
                writtenCommunication: 0,
                organizationalSkills: 0
            };
            let totalDocuments = 0;
            let promises = [];

            querySnapshot.forEach((doc) => {
                const candidateID = doc.id;
                let promise = seekersRef.doc(candidateID).collection("interviews").doc("general")
                   .get()
                   .then((interviewDoc) => {
                        if (interviewDoc.exists && interviewDoc.data().infoBox.interviewed === true) {
                            console.log("Interview id", interviewDoc.id);
                            Object.keys(totalScores).forEach((key) => {
                                totalScores[key] += interviewDoc.data()[key] || 0; // Add property value to total score
                            });
                            totalDocuments++;
                        }
                    })
                   .catch((error) => {
                        console.log("Error fetching interview:", error);
                    });

                promises.push(promise);
            });

            Promise.all(promises)
               .then(() => {
                    console.log('Total Scores:', totalScores);
                    console.log('Total Documents:', totalDocuments);
                    if (totalDocuments > 0) {
                        let averageScores = {};
                        Object.keys(totalScores).forEach((key) => {
                            averageScores[key] = totalScores[key] / totalDocuments;
                        });
                        console.log('Average Scores:', averageScores);
                    } else {
                        console.log('No interviews found.');
                    }
                    // Dispatch action with the total scores
                    // dispatch({ type: 'SET_AVERAGE_SCORES', payload: averageScores });
                })
               .catch((error) => {
                    console.log("Error resolving promises:", error);
                });
        }, (error) => {
            console.log("Error fetching seekers:", error);
        });
    }
};



export const fetchInterviewResponseTime = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_AVERAGE_RESPONSE_TIME });

        let interviewees = [];

        firebase.firestore().collection("jobPosts")
           .get()
           .then((querySnapshot) => {
                const promises = [];

                querySnapshot.forEach((doc) => {
                    const jobPostId = doc.id;
                    const promise = firebase.firestore().collection("jobPosts")
                       .doc(jobPostId)
                       .collection("candidates")
                       .where('status', 'in', ['accepted', 'rejected'])
                       .get()
                       .then((candidatesSnapshot) => {
                            candidatesSnapshot.forEach((candidateDoc) => {
                                const candidateData = candidateDoc.data().userID;
                                interviewees.push(candidateData);
                            });
                        });

                    promises.push(promise);
                });

                return Promise.all(promises);
            })
           .then(() => {
                const uniqueInterviewees = [...new Set(interviewees)];
                console.log('UNIQUE', uniqueInterviewees);

                return findResponseIntervalTime(uniqueInterviewees);
            })
           .then((averageResponseTime) => {
                if (averageResponseTime!== null) {
                    console.log('AVERAGE', averageResponseTime);
                    dispatch({ type: SET_AVERAGE_RESPONSE_TIME, payload: averageResponseTime });
                }
            })
           .catch((error) => {
                console.error("Error fetching candidates: ", error);
                // Handle error appropriately
            });
    };
};


const findResponseIntervalTime = async (candidateIds) => {
    try {
        const totalResponseIntervalsMs = [];
        const candidateAverages = {};

        for (const candidateId of candidateIds) {
            const interviewsRef = firebase.firestore().collection("seekers").doc(candidateId).collection("interviews");
            const snapshot = await interviewsRef.where("infoBox.status", "in", ["accepted", "rejected"]).get();
            const intervals = [];

            snapshot.forEach((doc) => {
                if (doc.id!== "general") { // Skip the general interview
                    const interviewData = doc.data();
                    const responseIntervalMs = interviewData.infoBox.statusDate.toMillis() - interviewData.infoBox.offeredOn.toMillis();
                    intervals.push(responseIntervalMs);
                }
            });

            if (intervals.length > 0) {
                const averageIntervalMs = intervals.reduce((a, b) => a + b, 0) / intervals.length;
                candidateAverages[candidateId] = averageIntervalMs;
                totalResponseIntervalsMs.push(averageIntervalMs);
            }
        }

        if (totalResponseIntervalsMs.length > 0) {
            const overallAverageMs = totalResponseIntervalsMs.reduce((a, b) => a + b, 0) / totalResponseIntervalsMs.length;
            const overallAverageDays = overallAverageMs / (1000 * 60 * 60 * 24); // Convert milliseconds to days
            console.log(`Overall Average Response Interval (days): ${overallAverageDays.toFixed(2)}`);
            console.log(`Candidate Averages (ms):`, candidateAverages);

            // Return the overallAverageDays
            return overallAverageDays;
        } else {
            console.log("No valid interviews found.");
            return null;
        }
    } catch (error) {
        console.error("Error calculating response intervals:", error);
        return null;
    }
};




export const averageTimeToHire = () => {
    return async (dispatch) => {
        const ref = firebase.firestore().collection("jobPosts");
        const snapshot = await ref.get();

        const candidatePromises = snapshot.docs.map(async (doc) => {
            const job = doc.data();
            return fetchCandidates(job);
        });

        let timeToHireContainer = await Promise.all(candidatePromises);
        let filteredTimeToHireContainer = timeToHireContainer.filter(item =>!(item === null || item === undefined));
        const sum = filteredTimeToHireContainer.reduce((a, c) => a + c, 0);
        const generalAverageTimeToHire = Math.round(sum/snapshot.size);
        dispatch({type: FETCH_AVERAGE_TIME_TO_HIRE, payload: generalAverageTimeToHire});
        console.log('generalAverageTimeToHire', generalAverageTimeToHire);
    }
}

async function fetchCandidates(job) {
    try {
        const ref = firebase.firestore().collection("jobPosts").doc(job.postID).collection("candidates").where("selected", "==", true);
        const snapshot = await ref.get();

        if (!snapshot.empty) {
        
            const earliestDate = snapshot.docs.map(doc => {
                const candidate = doc.data();
                if ('selectionDate' in candidate) {
                    let seconds;
                    candidate.selectionDate.seconds? seconds = candidate.selectionDate.seconds : seconds = candidate.selectionDate._seconds;
                    return moment.unix(seconds);
                }
            });

            const earliestMoment = _.min(earliestDate);

            let seconds;
            job.datePosted.seconds? seconds = job.datePosted.seconds : seconds = job.datePosted._seconds;
            const datePosted = moment.unix(seconds);

            const diffDays = earliestMoment.diff(datePosted, 'days');
            return diffDays;

        }

    } catch (e) {
        console.error(e);
        return null;
    }
}

export const myAverageTimeToHire = () => {
    return async () => {
        const userID = firebase.auth().currentUser.uid;
        const ref = firebase.firestore().collection("jobPosts").where("companyID", "==", userID);
        const snapshot = await ref.get();

        const candidatePromises = snapshot.docs.map(async (doc) => {
            const job = doc.data();
            return fetchMyCandidates(job);
        });

        let timeToHireContainer = await Promise.all(candidatePromises);
        let filteredTimeToHireContainer = timeToHireContainer.filter(item =>!(item === null || item === undefined));
        const sum = filteredTimeToHireContainer.reduce((a, c) => a + c, 0);
        const averageTimeToHire = Math.round(sum/snapshot.size);
        console.log('averageTimeToHire', averageTimeToHire);
    }
}

async function fetchMyCandidates(job) {
    try {
        const ref = firebase.firestore().collection("jobPosts").doc(job.postID).collection("candidates").where("selected", "==", true);
        const snapshot = await ref.get();

        if (!snapshot.empty) {
        
            const earliestDate = snapshot.docs.map(doc => {
                const candidate = doc.data();
                if ('selectionDate' in candidate) {
                    let seconds;
                    candidate.selectionDate.seconds? seconds = candidate.selectionDate.seconds : seconds = candidate.selectionDate._seconds;
                    return moment.unix(seconds);
                }
            });

            const earliestMoment = _.min(earliestDate);

            let seconds;
            job.datePosted.seconds? seconds = job.datePosted.seconds : seconds = job.datePosted._seconds;
            const datePosted = moment.unix(seconds);

            const diffDays = earliestMoment.diff(datePosted, 'days');
            return diffDays;

        }

    } catch (e) {
        console.error(e);
        return null;
    }
}

export const fetchCandidatesGender = () => {
    return async (dispatch) => {
        const ref = firebase.firestore().collection("jobPosts");
        const snapshot = await ref.get();

        const candidatePromises = snapshot.docs.map(async (doc) => {
            const job = doc.data();
            return fetchCandidatesSelected(job);
        });

        const genderArray = await Promise.all(candidatePromises);
        function cleanArray(arr) {
            // Filter out null and undefined values
            return arr.filter(item => item!== null && item!== undefined)
                     .map(subArr => Array.isArray(subArr)? cleanArray(subArr) : subArr);
        }

        let cleanedArray = cleanArray(genderArray);
        let obj = {};
        cleanedArray.forEach(arr => {
            arr.forEach(user => {
                obj['Male'] = {
                    gender: 'Male',
                    value: 0
                }

                obj['Female'] = {
                    gender: 'Female',
                    value: 0
                }

                obj[user.gender].value += 1;
            })
        })

        dispatch({type: FETCH_CANDIDATE_GENDER, payload: Object.values(obj)});
    }
}



async function fetchCandidatesSelected(job) {
    try {
        const ref = firebase.firestore().collection("jobPosts").doc(job.postID).collection("candidates").where("selected", "==", true);
        const snapshot = await ref.get();

        if (!snapshot.empty) {
        
            const candidatesGender = snapshot.docs.map(doc => {
                const candidate = doc.data();
                return getCandidateGender(candidate);
            });

            let result = await Promise.all(candidatesGender);
            return result;
        }
    } catch (e) {
        console.log(e);
    }
}

async function getCandidateGender(candidate) {
    try {
        const ref = firebase.firestore().collection("seekers").doc(candidate.userID);
        const doc = await ref.get();

        if (doc.exists) {
            const seeker = doc.data();
            return {userID: seeker.userID, gender: seeker.gender};
        }
    } catch (e) {
        console.log(e);
    }
}

export const skillCategories = () => {
    return async () => {
        const ref = firebase.firestore().collection("assetLists");
        const snapshot = await ref.get();

        if (snapshot.size !== 0) {
            const skills = snapshot.docs.filter(doc => doc.data().list === "skill").map(doc => doc.data());
            console.log("skills:", skills);
            exportSkillsExcel(skills);
        } else {
            console.log("No skills found");
        }
    }
}

async function exportSkillsExcel(skills){
    const worksheet = XLSX.utils.json_to_sheet(skills);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Skills");
    XLSX.writeFile(workbook, "skills.xlsx");
}

// async function exportSkillsExcell(skills){
//     //styling sheets
//     const sa2b = (s) => {
//         const buf = new ArrayBuffer(s.length);
//         const view = new Uint8Array(buf);
//         for(let i = 0; i !== s.length; ++i){
//             view[i] = s.charCodeAt(i);
//         }
//         return buf;
//     }

//     const workbook2blob = (workbook) => {
//         const wopts = {
//             bookType: 'xlsx',
//             type: 'binary'
//         }

//         const wbout = XLSX.write(workbook, wopts);
//         const blob = new Blob([sa2b(wbout)], {
//             type: 'application/octet-stream'
//         })

//         return blob;
//     }

//     let title = [{A: "SKILLS SET CATEGORIES"},{}];

//     let table1 = [
//         {
//             A: "ID",
//             B: "LIST",
//             C: "TITLE",
//         }
//     ];

//     skills.forEach(data => {
//         table1.push({
//             A: data.ID,
//             B: data.list,
//             C: data.title,
//         })
//     })

//     const finalData = [...title, ...table1];
    

//     const wb = XLSX.utils.book_new();
//     const ws = XLSX.utils.json_to_sheet(finalData, {
//         skipHeader: true,
//     })

//     ws['!cols'] = [
//         {wch:30},
//         {wch:35},
//         {wch:35},
//     ];

//     XLSX.utils.book_append_sheet(wb, ws, 'skills set');
//     const workbookBlob = workbook2blob(wb);
//     const headerIndex = [];
//     finalData.forEach((data, index) => data['A'] === 'ID' ? headerIndex.push(index) : null )


//     const dataInfo = {
//         titleCell: 'A2',
//         titleRange: 'A1:C2',
//         tbodyRange: `A2:C${finalData.length}`,
//         theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,

//     }

//     addStyles(workbookBlob, dataInfo);
// }

// const addStyles = (workbookBlob, dataInfo) => {
//     return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
//         workbook.sheets().forEach(sheet => {

//             sheet.range(dataInfo.titleRange).merged(true).style({
//                 bold: true,
//                 verticalAlignment: 'center',
//                 horizontalAlignment: 'center',
//                 fontFamily: 'Callibri',
//                 fontSize: 8
//             })

//             sheet.range(dataInfo.tbodyRange).style({
//                 horizontalAlignment: 'center',
//                 fontFamily: 'Callibri',
//                 fontSize: 8
//             })

//             sheet.range(dataInfo.theadRange).style({
//                 fill: '808080',
//                 fontColor: 'FFFFFF',
//                 bold: true,
//             })
//         })

//         workbook.outputAsync().then(workbookBlob => { 
//             const url = URL.createObjectURL(workbookBlob);
//             const downloadAnchorNode = document.createElement('a');
//             downloadAnchorNode.setAttribute('href', url);
//             downloadAnchorNode.setAttribute('download', 'skills.csv');
//             downloadAnchorNode.click();
//             downloadAnchorNode.remove();
//         })
//     })
// }

export const uploadSkillsSet = (skill) => {
    return async () => {
        const body = JSON.stringify({ skill });

        fetch("https://us-central1-nafasiio.cloudfunctions.net/importSkillsFile",  {
            method: 'POST',
            body,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log(response);
                console.log("success");
            }).catch(function(error) {
            console.log("file upload error");
            console.log(error);
        });
    }
};



