import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Table, Popconfirm, Modal, message, Switch } from 'antd';

import moment from "moment";
import PDFCV from "../PDFCV";

import CustomHeaderCard from '../../../search/cards/CustomHeaderCard';
import DescriptionModal from './DescriptionModal';

class AssessmentCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: this.props.items,
		}
	}

	render() {
		const {items} = this.state;

		let processedResults = items.map(result => {
			let seconds;
			result.logDate.seconds ? seconds = result.logDate.seconds : seconds = result.logDate._seconds;
			const time = moment.unix(seconds).format("DD/MMM/YYYY | HH:mm");
	
			let bdSeconds;
			result.birthdate.seconds ? bdSeconds = result.birthdate.seconds : bdSeconds = result.birthdate._seconds;
			const birthday = moment.unix(bdSeconds).format("DD/MMM/YYYY | HH:mm");
	
			return { ...result, time, birthday};
		})

		const columns = [
            {
                title: <CustomHeaderCard>Name</CustomHeaderCard>,
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.firstName} {record.lastName}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Gender</CustomHeaderCard>,
                dataIndex: 'gender',
                key: 'gender',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.gender}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Email</CustomHeaderCard>,
                dataIndex: 'email',
                key: 'email',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.email}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Location</CustomHeaderCard>,
                dataIndex: 'location',
                key: 'location',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.location.region}, {record.location.country}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Attended</CustomHeaderCard>,
                dataIndex: 'attended',
                key: 'attended',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.attended ? "Yes" : "No"}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Time</CustomHeaderCard>,
                dataIndex: 'time',
                key: 'time',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.time}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Assessment</CustomHeaderCard>,
                dataIndex: 'assessment',
                key: 'assessment',
                render: (_, record) => (
                    <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        <DescriptionModal record={record}/>
                    </div>
                ),
            }
        ];

		return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
		);
	}
}

export default AssessmentCard;