import React, { Component } from 'react';
import {connect} from "react-redux";
import firebase from "firebase";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//action imports
import {saveBlog, settingsFieldChanged} from "../../../actions/SettingsActions";

//common imports
import {Input} from "../../common/Input";
import {TextArea} from "../../common/TextArea";

class BlogEditor extends Component {
	constructor(props) {
		super(props);

		// Initialize with empty content
		const contentBlock = htmlToDraft('');
		let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);

		this.state = {
			editorState,
			title: "",
			description: ""
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props !== nextProps) {
			this.setState({
				editorState: nextProps.editorState,
				title: nextProps.title,
				description: nextProps.description
			});
		}
	}

	onEditorStateChange = (editorState) => {
		// this.setState({
		// 	editorState,
		// });
		this.props.settingsFieldChanged({ prop: "editorState", value: editorState })
	};

	saveContent = () => {
		const contentState = this.state.editorState.getCurrentContent();
		const html = draftToHtml(convertToRaw(contentState));
		const { title, description } = this.state;
		console.log({html, title, description });
		// Save the HTML content to your server
		//save to data base
		this.props.saveBlog({ html, title, description });
	};

	loadContent = (htmlContent) => {
		const contentBlock = htmlToDraft(htmlContent);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			// this.setState({ editorState });
			this.props.settingsFieldChanged({ prop: "editorState", value: editorState })
		}
	}

	render() {
		const { editorState } = this.state;
		return (
			<div>
				<div className={"addNotificationInfoBox"}>

					<div className={"addNotyTextArea"}>
				         <Input
							label={"Title"}
							type={'text'}
							onChange={e => this.props.settingsFieldChanged({ prop: "title", value: e.target.value })}
							value={this.state.title}
							
						 />
						<TextArea
							label={"Description"}
							onChange={e => this.props.settingsFieldChanged({ prop: "description", value: e.target.value })}
							value={this.state.description}
							style = {{width:"300px"}}
						/>
					</div>
				</div>
				<Editor
					editorState={editorState}
					wrapperClassName="demo-wrapper"
					editorClassName="demo-editor"
					onEditorStateChange={this.onEditorStateChange}
					editorStyle={{
						backgroundColor: "#ffffff"
					}}
					toolbar={{
						image: {
							uploadCallback: this.uploadImageCallBack,
							alt: { present: true, mandatory: true }
						},
					}}
				/>
				<div style = {{display:'flex', alignItems:"center", justifyContent:'center', marginTop:"20px" }}>
				<button onClick={this.saveContent} style = {{width:"300px", padding:"5px", borderRadius:"20px", marginRight:"5px"}}>Save Content</button>
				<button onClick={() => this.loadContent('<p>Example HTML content</p>')} style = {{width:"300px", padding:"5px", borderRadius:"20px"}}>Load Example Content</button>
				</div>
			</div>
		);
	}

	uploadImageCallBack = (file) => {
		console.log("entered")
		return new Promise(
			(resolve, reject) => {
				// Check if file size is greater than 2MB
				if (file.size > 2 * 1024 * 1024) {
					reject('File size cannot exceed 2MB');
					return;
				}

				console.log("file is not big");
				console.log(file.size);

				const timestamp = new Date().getTime();
				const fileName = `blogImages/${timestamp}_${file.name}`; // Using timestamp to ensure uniqueness
				console.log("work now");
				console.log(fileName);
				const uploadTask = firebase.storage.ref(fileName).put(file);

				uploadTask.on(
					firebase.storage.TaskEvent.STATE_CHANGED,
					(snapshot) => {
						// Optional: observe state change events such as progress, pause, and resume
						// Here, you could implement loading indicators or log the progress
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						console.log('Upload is ' + progress + '% done');
						switch (snapshot.state) {
							case firebase.storage.TaskState.PAUSED: // or 'paused'
								console.log('Upload is paused');
								break;
							case firebase.storage.TaskState.RUNNING: // or 'running'
								console.log('Upload is running');
								break;
							default:
						}
					},
					(error) => {
						// Handle unsuccessful uploads
						console.error("Upload error:", error);
						reject(error);
					},
					() => {
						// Handle successful uploads on complete
						// For instance, get the download URL
						uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
							console.log('File available at', downloadURL);
							resolve({ data: { link: downloadURL } });
						});
					}
				);
			}
		);
	};
}

const mapStateToProps = (state) => {
	const { title, description, editorState } = state.settings;

	return {
		title,
		description,
		editorState
	};
}

export default connect(mapStateToProps, { saveBlog, settingsFieldChanged })(BlogEditor);