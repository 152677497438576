import React from "react";
import moment from "moment";
import "./../Referees"
import { Table, Button, Modal, message } from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";
import VettingResult from "./VettingResult";

class RefereeCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            referees: this.props.referees,
			visible: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                referees: nextProps.referees,
            });
        }
    }

    handleOk = () => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};


    renderReferee(){
        const { referees } = this.state;
        const filteredReferees = referees.filter(referee => referee.vet === true);

        let processedResults = filteredReferees.map(result => {

            let seconds;
            result.sentOn.seconds ? seconds = result.sentOn.seconds : seconds = result.sentOn._seconds;
            const sentOn = moment.unix(seconds);

            const sentOnString = sentOn.format('DD MMM YYYY');

            const name = `${result.firstName}, ${result.lastName}`;

            return { ...result, sentOnString, name };
        });

        const columns = [
            {
                title: <CustomHeaderCard>Company</CustomHeaderCard>,
                dataIndex: 'company',
                key: 'company',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.company}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Name</CustomHeaderCard>,
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.name}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Email</CustomHeaderCard>,
                dataIndex: 'email',
                key: 'email',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.email}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Phone</CustomHeaderCard>,
                dataIndex: 'phone',
                key: 'phone',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.phone}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Sent On</CustomHeaderCard>,
                dataIndex: 'sentOnString',
                key: 'sentOnString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.sentOnString}</div>
                ),
            },
            {
                title: <CustomHeaderCard>View</CustomHeaderCard>,
                dataIndex: 'view',
                key: 'view',
                render: (_, record) => (
                    <div>
                        <FontAwesomeIcon icon={"eye"} size={"1x"} color="#0793d9"  onClick={() => this.showModal()}/>
						<Modal
							title={"View Vetting Result"}
							open={this.state.visible}
							onOk={this.handleOk}
							onCancel={this.handleCancel}

							footer={null}
							width={900}
						>
                            <VettingResult item={record}/>
						</Modal>
                    </div>
                ),
            },
        ];


        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderReferee()}
            </div>
        );
    }
}

export default RefereeCard;