import {
    NOTIFICATION_CHANGED,
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_SUCCESSFUL,
    FETCH_NOTIFICATIONS_FAILED,
    SAVE_NOTIFICATIONS,
    SAVE_NOTIFICATIONS_SUCCESSFUL,
    SAVE_NOTIFICATIONS_FAILED,
    CLEAR_NOTIFICATIONS_MESSAGES,
} from "../actions/Types";


const INITIAL_STATE = {
    //fetched objects
    notifications: {},
    notificationFetchLimit: 10,
    notificationLoading: false,

    //fields
    notificationTitle: '',
    description: '',
    notificationTime: '',
    url: '',
    saveNotificationLoading: false,
    saveNotificationSuccessful: false,
    saveNotificationFailed: false,
    deleteNotificationSuccessful: false,
    deleteNotificationFailed: false
};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const NotificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case NOTIFICATION_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        case SAVE_NOTIFICATIONS:
            return {...state, saveNotificationLoading: true };
        case SAVE_NOTIFICATIONS_SUCCESSFUL:
            return {...state, saveNotificationLoading: false, saveNotificationSuccessful: true, notificationTitle: '', description: '', notificationTime: '', url: ''  };
        case SAVE_NOTIFICATIONS_FAILED:
            return {...state, saveNotificationLoading: false, saveNotificationFailed: true, notificationTitle: '', description: '', notificationTime: '', };

        case CLEAR_NOTIFICATIONS_MESSAGES:
            return {...state, saveNotificationLoading: false, saveNotificationSuccessful: false, saveNotificationFailed: false, };

        //fetch reducers
        case FETCH_NOTIFICATIONS:
            return {...state, notificationLoading: true };
        case FETCH_NOTIFICATIONS_SUCCESSFUL:
            return {...state, notifications: deepClone(action.payload.fetchedNotifications), notificationFetchLimit: deepClone(action.payload.notificationFetchLimit), notificationLoading: false };
        case FETCH_NOTIFICATIONS_FAILED:
            return {...state, notificationLoading: false };
        default:
            return state;
    }
};

export default NotificationReducer;
