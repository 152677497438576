import React from 'react';
import { Card, Col, Row, Progress} from 'antd';

import { Spinner } from '../../common/Spinner';
import "./cards/JobStatsCard.css"

const fileIcon = "https://cdn.lordicon.com/wwmtsdzm.json";
const peopleIcon = "https://cdn.lordicon.com/kndkiwmf.json"
const maleIcon = "https://cdn.lordicon.com/xzalkbkz.json";
const womanIcon = "https://cdn.lordicon.com/qitvuzec.json";
const timeIcon = "https://cdn.lordicon.com/dndbceuc.json";
const giftFileIcon = "https://cdn.lordicon.com/ofwxettw.json"


export default function GeneralTab({totalJobPosts, postsPerPosition, offeredInterviews, interviewedCandidates, fetchingCandidatesCount, generalAverageTimeToHire, candidatesGender, averageResponseTime, isFetchingTime }) {
    
    let maleValue = 0;
    let femaleValue = 0;
    maleValue = candidatesGender.filter(item => item.gender === 'Male').map(item => item.value)[0];
    femaleValue = candidatesGender.filter(item => item.gender === 'Female').map(item => item.value)[0];
    const percentage = ((interviewedCandidates / offeredInterviews) * 100).toFixed(1);
    const responseDays = averageResponseTime.toFixed(0)

   
    return (
        <div>
            <Row gutter={16}>
                <Col span={8}>
                <Card title="Job Posts" bordered={true} className="custom-card">
                    <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                        <p style={{color:"black", fontSize:"20px"}}>  {totalJobPosts} </p>
                        <lord-icon
                        src={fileIcon}
                        trigger="boomerang"
                        colors="primary:#121331,secondary:#0793d9"
                        style={{width:"77px",height:"77px"}}>
                        </lord-icon>
                    </div>
                    
                </Card>
                </Col>

                <Col span={8}>
                <Card title="Interviews Offered" bordered={true} className="custom-card">
                    <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                    {fetchingCandidatesCount? (  <Spinner  />) : ( <p style={{ color: "black", fontSize: "20px" }}> {offeredInterviews} </p>  )}

                    <lord-icon
                        src={peopleIcon}
                        trigger="boomerang"
                        colors="primary:#121331,secondary:#0793d9"
                        style={{width:"77px",height:"77px"}}>
                        </lord-icon>
                    </div>
                    
                </Card>
                </Col>
                <Col span={8}>

                <Card title="Interviewed Candidates" bordered={true} className="custom-card">
                    <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                        <p style={{color:"black", fontSize:"20px"}}> {interviewedCandidates} </p>
                    
                        {fetchingCandidatesCount? (  <Spinner  />) : ( <Progress type="circle" size={70} percent={percentage} />  )}
                        
                    </div>
                    
                </Card>
                </Col>
            </Row>

            <Row gutter={16} style={{marginTop:'20px'}}>
                <Col span={8}>
                    <Card title="Average Time To Hire" bordered={true} className="custom-card">
                        <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                            <p style={{color:"black", fontSize:"20px"}}>  {generalAverageTimeToHire} </p>
                            <lord-icon
                                src={timeIcon}
                                trigger="boomerang"
                                colors="primary:#121331,secondary:#0793d9"
                                style={{width:"77px",height:"77px"}}>
                            </lord-icon>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Male Hired" bordered={true} className="custom-card">
                        <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                            <p style={{color:"black", fontSize:"20px"}}>  {maleValue} </p>
                            <lord-icon
                                src={maleIcon}
                                trigger="boomerang"
                                colors="primary:#121331,secondary:#0793d9"
                                style={{width:"77px",height:"77px"}}>
                            </lord-icon>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Female Hired" bordered={true} className="custom-card">
                        <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                            <p style={{color:"black", fontSize:"20px"}}>  {femaleValue} </p>
                            <lord-icon
                                src={womanIcon}
                                trigger="boomerang"
                                colors="primary:#121331,secondary:#0793d9"
                                style={{width:"77px",height:"77px"}}>
                            </lord-icon>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row gutter={16} style={{marginTop:'20px'}}>
                <Col span={8}>
                    <Card title="Average Candidate Response Time" bordered={true} className="custom-card">
                        <div className="div" style={{display:'flex', justifyContent:"space-evenly", alignItems:'center'}}>
                        {isFetchingTime? (  <Spinner  />) : ( <p style={{ color: "black", fontSize: "20px" }}> {responseDays} Days </p>  )}
                        <lord-icon
                            src={giftFileIcon}
                            trigger="boomerang"
                            colors="primary:#121331,secondary:#0793d9"
                            style={{width:"77px",height:"77px"}}>
                        </lord-icon>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
