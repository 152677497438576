import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, message} from 'antd';
import {BarLoader} from 'react-spinners';

//-> action imports
import {contactUsDetailsChanged, saveContactDetails} from '../../actions/LandingActions';

//-> component imports
import {Input} from '../common/Input';
import {TextArea} from '../common/TextArea';
import {isMobile} from 'react-device-detect';
import Logo from '../../assets/logos/taglineLogo.png';
import {NavLink} from 'react-router-dom';

//style imports
import './ContactUs.css';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contactName: this.props.contactName,
            contactEmail: this.props.contactEmail,
            contactPhone: this.props.contactPhone,
            contactNote: this.props.contactNote,

            contactLoading: this.props.contactLoading,
            contactLoadingSuccess: this.props.contactLoadingSuccess,
            contactLoadingFailed: this.props.contactLoadingFailed,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                contactName: nextProps.contactName,
                contactEmail: nextProps.contactEmail,
                contactPhone: nextProps.contactPhone,
                contactNote: nextProps.contactNote,
                contactLoading: nextProps.contactLoading,
                contactLoadingSuccess: nextProps.contactLoadingSuccess,
                contactLoadingFailed: nextProps.contactLoadingFailed,
            });
        }
    }

    onSend() {
        //-> extract state
        const { contactName, contactEmail, contactPhone, contactNote } = this.state;
        this.setState({loading: true});

        if (contactName && contactEmail && contactPhone && contactNote) {
            function sanitizeEmailForFirestore(e) {
                return e.replace(/[\/\.\.\_\~%]/g, '').substring(0, 1500);
            }

            const userEmail = sanitizeEmailForFirestore(contactEmail);
            this.props.saveContactDetails({ contactName, contactEmail: userEmail, contactPhone, contactNote });
        } else {
            message.warning('Please fill all the details to send us a message').then(() => null);
        }
    }

    handleSubmit() {
        const { contactLoading } = this.state;

        if (contactLoading) {
            return (
                <div>
                    <BarLoader size={20} css={{ textAlign: 'center' }} />
                </div>
            );
        } else {
            return (
                <div className="modalButton" style={{ border: "none", marginTop: "1rem"}}>
                    <Button type="default" onClick={() => this.onSend()}>
                        <span>Send Message</span>
                    </Button>
                </div>
            );
        }
    }

    renderLogo() {
        if (isMobile) {
            return (
                <NavLink to="/">
                    <img src={Logo} alt="nafasi icon logo" />
                </NavLink>
            );
        }
    }

    render() {
        return (
            <div>
                <div className="landingPageLogo">{this.renderLogo()}</div>
                <div className={"contactUsContainer"} style={{marginTop: "50px", marginBottom: '50px'}}>
                    <div className="contactUsFormBox">
                        <Form
                            name="login"
                            onFinish={this.handleSubmit}
                            className="contact-us-form"
                            scrollToFirstError
                        >
                            <img src={Logo} alt={"nafasi logo"}/>
                            <h4>Do you want to get in touch with us?</h4>
                            <p style={{ fontFamily: "ramblaBold", fontSize: "1rem" }}>The team at Nafasi is here to help... send us a message</p>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        type: 'name',
                                        message: 'The input is not valid name!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your name!',
                                    },
                                ]}
                            >
                                <Input placeholder="Name" className={"regular"}/>
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ]}
                            >
                                <Input placeholder="Email" className={"regular"}/>
                            </Form.Item>
                            <Form.Item
                                name="number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your phone number!',
                                    },
                                ]}
                            >
                                <Input placeholder="Phone Number" className={"regular"}/>
                            </Form.Item>
                            <Form.Item
                                name="text"
                                rules={[
                                    {
                                        type: 'text',
                                        message: 'The input is not valid text!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input a valid message!',
                                    },
                                ]}
                            >
                                <TextArea placeholder="Write your message here" className={"regular"}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className={"regular"} loading={this.state.contactLoading}>
                                    Send Message
                                </Button>
                            </Form.Item>
                        </Form>

                        <div className={"contactUsDetails"} style={{ margin: "2rem 0"}}>
                            <div className={"contactDetailsBox"}>
                                <FontAwesomeIcon icon="map-marker-alt" size="1x" color="#ffb400" style={{ marginTop: "0.75rem"}} />
                                <p>Mbezi Beach, Dar es Salaam</p>
                            </div>

                            <div className={"contactDetailsBox"}>
                                <FontAwesomeIcon icon="phone-alt" size="1x" color="#ffb400" style={{ marginTop: "0.8rem"}} />
                                <p>+255 22 261 7000</p>
                            </div>

                            <div className={"contactDetailsBox"}>
                                <FontAwesomeIcon icon="envelope" size="1x" color="#ffb400" style={{ marginTop: "1rem"}}/>
                                <p>support@nafasi.io<br />business@nafasi.io</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contactUsMap">
                    <div>
                        <h4>
                            Locate us <FontAwesomeIcon icon="map-marker-alt" size="1x" color="#E55A5F" />
                        </h4>
                    </div>

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.3867757654025!2d39.22812185005812!3d-6.722570167573401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c5163c81a0ca9%3A0xb51658ed8dce81dd!2sFirm%2023!5e0!3m2!1sen!2stz!4v1615366077200!5m2!1sen!2stz"
                        width="100%"
                        height="350"
                        frameBorder="0"
                        allowFullScreen=""
                        title="Contact Us Map"
                    > </iframe>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { contactName, contactEmail, contactPhone, contactNote, contactLoading, contactLoadingSuccess, contactLoadingFailed } = state.landing;

    return {
        contactName,
        contactEmail,
        contactPhone,
        contactNote,
        contactLoading,
        contactLoadingSuccess,
        contactLoadingFailed,
    };
};

export default connect(mapStateToProps, { contactUsDetailsChanged, saveContactDetails })(ContactUs);