import React from 'react';
import { message } from 'antd';
import {connect} from 'react-redux';
import moment from 'moment';
import arraySort from "array-sort";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddTermsAndConditions from "./AddTermsAndConditions";

//-> action imports

//-> component imports
import "./Legal.css"


class ViewTermsAndConditions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
        }

        // this.onSubmitPress = this.onSubmitPress.bind(this);
        this.success = this.success.bind(this);
        this.error = this.error.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps !== this.props) {
            this.setState({
                fetchedTermsAndConditions: nextProps.fetchedTermsAndConditions
            })
        }
    }

    success = () => {
        message.success('Successfully sent');
    };

    error = () => {
        message.error("There's an error occured");
    };


    renderTermsAndAgreement(){
        const { fetchedTermsAndConditions} = this.state;


        if(!(_.isEmpty(fetchedTermsAndConditions))){
            const termsAndConditionsArray = _.map(fetchedTermsAndConditions, doc => doc);
            let terms = [];
            termsAndConditionsArray.forEach(doc => {
                if (doc) {
                    if ("termsUpdateTime" in doc) {
                        terms.push(doc)
                    }
                }
            })

            return(
                <div>
                    {
                        terms.map(doc => {
                            let seconds;
                            doc.termsUpdateTime.seconds ? seconds = doc.termsUpdateTime.seconds : seconds = doc.termsUpdateTime._seconds;
                            const time = moment.unix(seconds).format("ddd, Do MMM YYYY");

                            return(
                                // <div className={'updatedTermListBox'}>
                                //     <div key={doc.docFileID} className={'updatedTermList'}>
                                //         <p style={{ fontFamily: "ramblaBold", color: "#000"}}>{doc.docTitle}</p>
                                //         <p style={{ fontFamily: "ramblaBold", color: "#000"}}>Updated on {time} </p>
                                //         <p style={{ fontFamily: "ramblaBold", color: "#000"}} onClick={() => window.open(`${doc.docFile}`,'name','width=700,height=600')}><FontAwesomeIcon icon="eye" color="#3294ff" size="1x" /></p>
                                //     </div>
                                // </div>

                                <div className="horizontal-bar-container">
                                    <div className="horizontal-bar">
                                        <span className="title"><p style={{ fontFamily: "ramblaBold", color: "#000"}}>{doc.docTitle} , Updated on {time}</p></span>
                                        <FontAwesomeIcon icon="eye" className="icon" onClick={() => window.open(`${doc.docFile}`,'name','width=700,height=600')} />
                                    </div>
                              </div>
                            )
                        })
                    }
                </div>
            )
        } else {
            return <h4 className={'adminAlert'}>No terms and agreements yet</h4>;
        }
    }



    render() {

        return (
            <div className={'clientViewTermsContainer'}>
                <div className={"clientViewTermsHeaderBox"}>
                    <h4 style={{ color: "gray", fontFamily: "ramblaBold" }}>NAFASI TERMS AND CONDITIONS AGREEMENT</h4>
                </div>

                <AddTermsAndConditions/>

                <div className={'clientViewTermsInfoBox'}>
                    <h4 style={{ color: "gray", fontFamily: "ramblaBold" }}>Click below eye icon to read the terms</h4>
                    {this.renderTermsAndAgreement()}

                    <h5 style={{ color: "gray", fontFamily: "ramblaBold" }} id={"TermsAndConditions"} >By using Nafasi you consent to all the Nafasi terms and conditions and privacy policy</h5>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { fetchedTermsAndConditions,  } = state.settings;

    return {
        fetchedTermsAndConditions
    }
};



export default  connect(mapStateToProps, { })(ViewTermsAndConditions);