import React  from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
//import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

//component import
import PostCard from '../../home/cards/PostCard';

//-> action imports
import { } from '../../../actions/SettingsActions';
import {Button} from "../../common/Button";

class AllTrainings extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
            companyTrainings: this.props.companyTrainings,
            companyTrainingsFetchLimit: this.props.companyTrainingsFetchLimit,
        }

        this.viewMoreTrainings = this.viewMoreTrainings.bind(this);
    }

    componentWillMount() {
        //extract state
        const { profile } = this.state;
        const { companyTrainingsFetchLimit } = this.state;

        if(!(_.isEmpty(profile))) {
            //
            if ('location' in profile) {
                //
                const locationID = profile.location.locationID;
                //this.props.fetchCompanyTrainings({ companyTrainingsFetchLimit, locationID });

            } else  {
                //
                //this.props.fetchAllCompanyTrainings({companyTrainingsFetchLimit });
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.companyTrainings !== this.props.companyTrainings) {
            this.setState ({companyTrainings: nextProps.companyTrainings})
        }

        if (nextProps.companyTrainingsFetchLimit !== this.props.companyTrainingsFetchLimit) {
            this.setState ({companyTrainingsFetchLimit: nextProps.companyTrainingsFetchLimit})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState ({profile: nextProps.profile})
        }
    }

    viewMoreTrainings () {
        //extract state
        const { profile } = this.state;
        const { companyTrainingsFetchLimit } = this.state;

        if(!(_.isEmpty(profile))) {
            const locationID = profile.location.locationID;
            const addedLimit = companyTrainingsFetchLimit + 10;
            //this.props.fetchAllCompanyTrainings({ companyTrainingsFetchLimit: addedLimit, locationID });
        }
    }

    renderCompanyTrainings() {
        const { companyTrainings } = this.state;

        if (!(_.isEmpty(companyTrainings))) {
            const training = _.map(companyTrainings, training => training);

            return(
                <div className={"allTrainingsCardBox"}>
                    {
                        training.map(training => {
                            return(
                                <div className={"trainingListHome"} key={training.trainingID}>
                                    <PostCard training={training}/>
                                </div>
                            )
                        })
                    }
                </div>
            )
        } else {
            return <h5 className={"adminAlert"}>There are currently no company trainings at the moment</h5>;
        }

    }


    render(){
        return (
            <div className={"allTrainingsContainer"}>
                <div className={"allCelebrationsBoxHeader"}>
                    <h4 style={{textAlign: 'center'}}><FontAwesomeIcon icon={"graduation-cap"} style={{fontSize: '1.2rem'}} /> Trainings </h4>
                </div>

                {/* Here render / map all companyTrainings (list) */}
                <div className={"trainingListHome"}>
                    {this.renderCompanyTrainings()}
                </div>

                <div className={"allCelebrationsButtonBox"}>
                    <Button
                        text={"View More"}
                        onPress={() => this.viewMoreTrainings()}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    //-> fetch state properties from specific reducer
    const {companyTrainings, companyTrainingsFetchLimit} = state.settings;
    const {profile} = state.profile;

    return {
        companyTrainings,
        companyTrainingsFetchLimit,
        profile,
    }
};


export default connect(mapStateToProps, {} )(AllTrainings)
