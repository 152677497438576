import {
    ASSETS_DETAILS_CHANGED,
    BENEFITS_DEPENDENTS_DETAILS_CHANGED,
    FETCH_EMPLOYEE_ASSETS,
    FETCH_EMPLOYEE_ASSETS_FAILED,
    FETCH_EMPLOYEE_ASSETS_SUCCESSFUL,
    FETCH_EMPLOYEE_BENEFITS,
    FETCH_EMPLOYEE_BENEFITS_FAILED,
    FETCH_EMPLOYEE_BENEFITS_SUCCESSFUL,
    FETCH_MY_PROFILE_DAYS_OFF,
    FETCH_MY_PROFILE_DAYS_OFF_SUCCESSFUL,
    FETCH_MY_PROFILE_DAYS_OFF_FAILED,
    FETCH_MY_LEAVE_REQUESTS_SUCCESSFUL,
    FETCH_EMPLOYEE_DEPENDENTS,
    FETCH_EMPLOYEE_DEPENDENTS_FAILED,
    FETCH_EMPLOYEE_DEPENDENTS_SUCCESSFUL,
    FETCH_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_DETAILS_FAILED,
    FETCH_EMPLOYEE_DETAILS_SUCCESSFUL,
    FETCH_EMPLOYEE_DOCUMENTS,
    FETCH_EMPLOYEE_DOCUMENTS_FAILED,
    FETCH_EMPLOYEE_DOCUMENTS_SUCCESSFUL,
    FETCH_MY_PROFILE_SUCCESSFUL,
    FETCH_EMPLOYEE_TRAININGS,
    FETCH_EMPLOYEE_TRAININGS_SUCCESSFUL, FETCH_EMPLOYEE_TRAININGS_FAILED,

    EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED,
    EDIT_PROFILE_EMPLOYEE_DETAILS,
    EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL,
    EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED,

    FETCH_SELECTED_PROFILE_EMPLOYEE,
    FETCH_SELECTED_PROFILE_EMPLOYEE_SUCCESSFUL,
    FETCH_SELECTED_PROFILE_EMPLOYEE_FAILED,

} from "../actions/Types";


const INITIAL_STATE = {

    //profile object
    profile: {},
    myProfileDaysOff: {},

    //fetched objects
    employeeDetails: {},
    employeeBenefits: {},
    employeeTrainings: {},
    employeeDependents: {},
    employeeAssets: {},
    employeeDocuments: {},
    employeeDaysOff: {},

    //add education
    assetCategory: '',
    assetDescription: '',
    serialNumber: '',
    dateAssigned: '',
    dateReturned: '',

    fetchProfile: {},


    bankName:'',
    accountName: '',
    accountNumber: '', 
    maritalStatus: '', 
    maritalDate: '', 
    address: '', 
    phone: '', 
    homePhone: '', 
    workEmail: '', 
    email: '', 
    emergencyName: '', 
    emergencyPhoneNumber: '', 
    emergencyAddress: '', 
    emergencyRelationship: '',
    linkedinUrl: '',

    profileLoading: false,
    profileLoadingSuccess: false,
    profileLoadingFailed: false,



};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const ProfileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ASSETS_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        case EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        //adding the props
        case EDIT_PROFILE_EMPLOYEE_DETAILS:
            return {...state, profileLoading: true,};
        case EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL:
            return {...state,profileLoading: false,profileLoadingSuccess: true,  bankName:'',accountName: '',accountNumber: '', maritalStatus: '', maritalDate: '', address: '', phone: '', homePhone: '', email: '', emergencyName: '', emergencyPhoneNumber: '', emergencyAddress: '', emergencyRelationship: '', linkedinUrl: '' };
        case EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED:
            return {...state,profileLoading: false, profileLoadingFailed: true, }

        case FETCH_SELECTED_PROFILE_EMPLOYEE:
            return {...state, };
        case FETCH_SELECTED_PROFILE_EMPLOYEE_SUCCESSFUL:
            return {...state,  fetchProfile: deepClone(action.payload) };
        case FETCH_SELECTED_PROFILE_EMPLOYEE_FAILED:
            return {...state, }


            //fetching reducer
        case FETCH_MY_PROFILE_SUCCESSFUL:
            return { ...state, profile: deepClone(action.payload)};
        case FETCH_EMPLOYEE_DETAILS:
            return {...state, };
        case FETCH_EMPLOYEE_DETAILS_SUCCESSFUL:
            return {...state, employeeDetails: deepClone(action.payload), };
        case FETCH_EMPLOYEE_DETAILS_FAILED:
            return {...state, };

        case FETCH_EMPLOYEE_BENEFITS:
            return {...state, };
        case FETCH_EMPLOYEE_BENEFITS_SUCCESSFUL:
            return {...state, employeeBenefits: deepClone(action.payload), };
        case FETCH_EMPLOYEE_BENEFITS_FAILED:
            return {...state, };

        case FETCH_EMPLOYEE_DEPENDENTS:
            return {...state, };
        case FETCH_EMPLOYEE_DEPENDENTS_SUCCESSFUL:
            return {...state, employeeDependents: deepClone(action.payload) };
        case FETCH_EMPLOYEE_DEPENDENTS_FAILED:
            return {...state, };

        case FETCH_EMPLOYEE_ASSETS:
            return {...state, };
        case FETCH_EMPLOYEE_ASSETS_SUCCESSFUL:
            return {...state, employeeAssets: deepClone(action.payload), };
        case FETCH_EMPLOYEE_ASSETS_FAILED:
            return {...state, };

        case FETCH_EMPLOYEE_DOCUMENTS:
            return {...state, };
        case FETCH_EMPLOYEE_DOCUMENTS_SUCCESSFUL:
            return {...state, employeeDocuments: deepClone(action.payload), };
        case FETCH_EMPLOYEE_DOCUMENTS_FAILED:
            return {...state, };

        case FETCH_EMPLOYEE_TRAININGS:
            return {...state, };
        case FETCH_EMPLOYEE_TRAININGS_SUCCESSFUL:
            return {...state, employeeTrainings: deepClone(action.payload), };
        case FETCH_EMPLOYEE_TRAININGS_FAILED:
            return {...state, };

        case FETCH_MY_PROFILE_DAYS_OFF:
            return {...state, };
        case FETCH_MY_PROFILE_DAYS_OFF_SUCCESSFUL:
            return {...state, myProfileDaysOff: deepClone(action.payload), };
        case FETCH_MY_PROFILE_DAYS_OFF_FAILED:
            return {...state, };

        case FETCH_MY_LEAVE_REQUESTS_SUCCESSFUL:
            return { ...state, myLeaveRequests: deepClone(action.payload) };


        default:
            return state;
    }
};

export default ProfileReducer;