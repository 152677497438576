import React from 'react'
import { ArrowAllDirectionIcon, BoundingBoxIcon, ChartBubble02Icon, ChartRelationshipIcon, ColumnInsertIcon, DashboardCircleRemoveIcon, DeliverySecure01Icon, DistributeHorizontalRightIcon } from 'hugeicons-react'

//-> file imports
import "./RoadMap.css"

export default function RoadMap() {
  return (
    <div className="roadmap">
    <div className="point">
      <div className="point-index">  <DashboardCircleRemoveIcon size={30} color='gold'/> </div>
      <div className="point-label">Centralize Applications</div>
      <div className="point-text">Organize all your email applications in one central platform.</div>
    </div>
    <div className="point" style={{textAlign:"right"}}>
    <div className="point-index">  <DistributeHorizontalRightIcon size={30} color='gold'/> </div>
      <div className="point-label">Smart Matching Algorithms</div>
      <div className="point-text" >Get matched with the best options based on your criteria or preferences.</div>
    </div>
    <div className="point">
    <div className="point-index">  <ArrowAllDirectionIcon size={30} color='gold'/> </div>
      <div className="point-label">Automated Processes</div>
      <div className="point-text">Automate repetitive tasks to focus on what matters most</div>
    </div>
    <div className="point" style={{textAlign:"right"}}>
    <div className="point-index"> <BoundingBoxIcon size={30} color='gold'/> </div>
      <div className="point-label">Performance Tracking</div>
      <div className="point-text">Monitor your application or recruitment performance with detailed metrics.</div>
    </div>
    <div className="point">
    <div className="point-index">  <ChartBubble02Icon size={30} color='gold'/> </div>
      <div className="point-label">Interview Management</div>
      <div className="point-text">Manage all your interviews in one place</div>
    </div>
    <div className="point" style={{textAlign:"right"}}>
    <div className="point-index">  <ColumnInsertIcon size={30} color='gold'/> </div>
      <div className="point-label">Career Guidance</div>
      <div className="point-text">Access resources and tools for career planning, development, and advancement.</div>
    </div>
  </div>
  )
}
