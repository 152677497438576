import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

//actions import
import { credentialsChanged } from "../../actions/AuthenticationActions";
import ChangePassword from "./subComponents/ChangePassword";
import { Button } from 'antd';

//-> component imports
import "./Profile.css"
import { BankIcon, Calendar02Icon, CallIcon, Image01Icon, Image02Icon, ImageDelete01Icon, Location01Icon, Mail02Icon, PackageDeliveredIcon, ProfileIcon, UserAccountIcon, UserSharingIcon } from 'hugeicons-react';
import ProfileModal from './subComponents/ProfileModal';

class ProfileBase extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
            imgLoaded:false,
            imgLoaded: false,
            showModal: false
        };

        this.renderDueDate = this.renderDueDate.bind(this);
        this.renderEditProfile = this.renderEditProfile.bind(this);
        this.showProfileModal = this.showProfileModal.bind(this);
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.profile) &&!this.checkRequiredFields(this.props.profile)) {
            this.showProfileModal();
        } else {
            this.showProfileModal();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile
            })
        }
    }

    checkRequiredFields(profile) {
        const requiredKeys = ['firstName', 'lastName', 'phone', 'companyName', 'companyPhone', 'companyEmail', 'logo', 'companyRegDocument', 'adminIDs', 'companyLocation'];
        return requiredKeys.every(key => profile.hasOwnProperty(key));
    }
    

    handleImageError = () => {
        this.setState({ imgLoaded: false });
      };
    
      handleImageLoad = () => {
        this.setState({ imgLoaded: true });
      };

    renderDueDate() {
        //extract state
        const { profile, imgLoaded  } = this.state;

        if (!(_.isEmpty(profile))) {

            //compute hire date
            if ("paymentPackage" in profile) {
                let seconds;
                profile.dueDate.seconds ? seconds = profile.dueDate.seconds : seconds = profile.dueDate._seconds;
                const date = moment.unix(seconds);
                const dueDate = date.format("MMMM Do YYYY");
                //const years = date.fromNow(true);

                return(

                    <div>
                        <div className="input-with-icon">
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={profile.paymentPackage.title}
                                disabled
                                placeholder="Company"
                            />
                            <span className="icon">
                                <PackageDeliveredIcon size={20} color='#0793d9' />
                            </span>
                        </div>

                        <div className="input-with-icon">
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={dueDate}
                                disabled
                                placeholder="Company"
                            />
                            <span className="icon">
                                <Calendar02Icon size={20} color='#0793d9' />
                            </span>
                        </div>
                    </div>
                );
            }
        }
    }

    renderBar() {
        //extract state
        const {profile} = this.state;

        return (
            <div>
                <div className={"profileInfoList"}>
                    <h2 style={{ fontFamily: "ramblaBold", color: "#0793d9"}}>Company</h2>
                    <img src={profile.logo} style={{ height: "3rem", width: "3rem", margin: "2rem 0" }} alt={"logo"}/>
                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="phone" style={{opacity: '.8', fontSize: '1.1rem', margin: '0.6rem 0.3rem 0 0'}} flip="horizontal" color={"gray"}/>
                        <p>{profile.companyPhone}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="envelope" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.companyEmail}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="map-marker-alt" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.companyLocation.country}</p>
                        <p>{profile.companyLocation.region}</p>
                        <p>{profile.companyLocation.streetAddress}</p>
                    </div>
                </div>

                <div className={"profileInfoList"}>
                    { this.renderDueDate() }
                </div>

                <div className={"profileInfoList"}>
                    <h2 style={{ fontFamily: "ramblaBold", color: "#0793d9"}}>Admin</h2>
                    <p style={{ fontFamily: "ramblaBold", fontSize: "1.2rem", textAlign: "left",color: "#181515"  }}>{profile.firstName} {profile.lastName}</p>
                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="phone" style={{opacity: '.8', fontSize: '1.1rem', margin: '0.6rem 0.3rem 0 0', }} flip="horizontal" color={"gray"}/>
                        <p >{profile.phone}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="envelope" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.email}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="map-marker-alt" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.companyLocation.country}</p>
                        <p>{profile.companyLocation.region}</p>
                        <p>{profile.companyLocation.streetAddress}</p>
                    </div>
                </div>
            </div>
        );
    }


    renderEditProfile(){
        const { profile } = this.state;
        if(!(_.isEmpty(profile))){
            this.props.credentialsChanged({prop: 'firstName', value: profile.firstName});
            this.props.credentialsChanged({prop: 'lastName', value: profile.lastName});
            this.props.credentialsChanged({prop: 'phone', value: profile.phone});
            this.props.credentialsChanged({prop: 'companyName', value: profile.companyName});
            this.props.credentialsChanged({prop: 'companyPhone', value: profile.companyPhone});
            this.props.credentialsChanged({prop: 'companyEmail', value: profile.companyEmail});
            this.props.credentialsChanged({prop: 'logo', value: profile.logo});
            this.props.credentialsChanged({prop: 'companyRegDocument', value: profile.companyRegDocument});
            this.props.credentialsChanged({prop: 'adminIDs', value: profile.adminIDs});
            this.props.credentialsChanged({prop: 'country', value: { countryName: profile.companyLocation.country, countryShortCode: profile.companyLocation.countryCode }});
            this.props.credentialsChanged({prop: 'region', value: { name: profile.companyLocation.region, shortCode: profile.companyLocation.regionCode }});
            this.props.credentialsChanged({prop: 'address', value: profile.companyLocation.streetAddress});
            this.props.credentialsChanged({prop: 'postalCode', value: profile.companyLocation.postalCode});
            this.props.navigate('/profile-details');
        }
    }

    showProfileModal = () => {
        this.setState({ showModal: true });
    };
    
    hideProfileModal = () => {
        this.setState({ showModal: false });
    };
    
    handleNavigation = () => {
        this.hideProfileModal();
        this.renderEditProfile();
    };

    render() {
        const {profile} = this.state;

        if (this.state.showModal) {
            return (
                <div>
                    <ProfileModal
                        visible={this.state.showModal}
                        // onCancel={this.hideProfileModal}
                        onConfirm={this.handleNavigation}
                    />
                </div>
            );
        }

        if(!(_.isEmpty(profile))) {
            if (profile.role === "admin" || profile.role === "superAdmin") {
                return (
                    <div className="container-fluid">
                        <div className="companyContainer">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Company</h4>
                                </div>

                                <div className="card-body">
                                    <div className="row" style={{display:'flex', justifyContent:'space-evenly'}}>
                                        <div className="companyInfoContainer">
                                            <div className="form-group">
                                                <label>Company Details</label>
                                                <div className="input-with-icon">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={profile.companyPhone}
                                                        disabled
                                                        placeholder="Company"
                                                    />
                                                    <span className="icon">
                                                        <CallIcon size={20} color='#0793d9'/>
                                                    </span>
                                                </div>

                                                <div className="input-with-icon">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={profile.companyEmail}
                                                        disabled
                                                        placeholder="Company"
                                                    />
                                                    <span className="icon">
                                                        <Mail02Icon size={20} color='#0793d9'/>
                                                    </span>
                                                </div>

                                                <div className="input-with-icon">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={`${profile.companyLocation.country}, ${profile.companyLocation.region}, ${profile.companyLocation.streetAddress}`}
                                                        disabled
                                                        placeholder="Company"
                                                    />
                                                    <span className="icon">
                                                        <Location01Icon size={20} color='#0793d9'/>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>Due Date</label>
                                                {this.renderDueDate()}
                                            </div>
                                        </div>
                                        <div className="companyLogoContainer">
                                            {profile.logo ? (
                                            <img
                                                src={profile.logo}
                                                style={{ height: '3rem', width: '3rem', margin: '2rem 0' }}
                                                alt="logo"
                                                onError={this.handleImageError}
                                                onLoad={this.handleImageLoad}
                                            />
                                            ) : (
                                            
                                            <div>
                                                <Image01Icon size={88} color="#ff4747" />
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        <div className="adminContainer">
                            <div className="card">
                                <div className="card-image">
                                        <div style={{ height:"70px", width:"100%", backgroundColor:'#0793d9'}}></div>
                                </div>
                                <div className="card-body">
                                    <div className="adminInfo">
                                        <div className="userSharingIcon"><UserSharingIcon size={50}  color='white' /></div>
                                            <h2 style={{ fontFamily: "ramblaBold", color: "#0793d9", marginTop:"20px"}}>Admin</h2>

                                            <div className="input-with-icon">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={`${profile.firstName} ${profile.lastName}`}
                                                    disabled
                                                    placeholder="Company"
                                                />
                                                <span className="icon">
                                                    <ProfileIcon size={20} color='#0793d9'/>
                                                </span>
                                            </div>

                                            <div className="input-with-icon">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={profile.phone}
                                                    disabled
                                                    placeholder="Company"
                                                />
                                                <span className="icon">
                                                    <CallIcon size={20} color='#0793d9'/>
                                                </span>
                                            </div>

                                            <div className="input-with-icon">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={profile.email}
                                                    disabled
                                                    placeholder="Company"
                                                />
                                                <span className="icon">
                                                    <Mail02Icon size={20} color='#0793d9'/>
                                                </span>
                                            </div>

                                            <div className="input-with-icon">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={`${profile.companyLocation.country}, ${profile.companyLocation.region}, ${profile.companyLocation.streetAddress}`}
                                                    disabled
                                                    placeholder="Company"
                                                />
                                                <span className="icon">
                                                    <Location01Icon size={20} color='#0793d9'/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                <hr />
                                <div style={{display:"flex", justifyContent:"space-evenly" }}>
                                    <ChangePassword />
                                    <Button type="link" onClick={this.showModal}>
                                        <span style={{ fontFamily: "ramblaBold" }} onClick={() => this.renderEditProfile()}>Update Profile</span>
                                    </Button>
                                </div>
                            </div>
                        </div> 
                    </div>
                )
            } else {
                return (
                        <div style={{margin: '1.2rem 1.2rem 0.5rem 1.2rem'}}>
                            <p style={{ color: "#fff"}}>Unauthorized access</p>
                        </div>
                    );
            }
        }
    }
}

const mapStateToProps = (state) => {

    const {profile} = state.profile;

    return {
        profile
    }
};

// Wrapper functional component to use hooks
function Profile(props) {
    const navigate = useNavigate();

    return <ProfileBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { credentialsChanged }) (Profile);
