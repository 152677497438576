import React from 'react';


export class TextArea extends React.Component {
    render() {
        return (
            <div className={"inputTypeTextArea"}>
                <div>
                    <p style={{ color: "#000", fontFamily: "ramblaBold" }}>
                        { this.props.label }
                    </p>
                </div>
                <textarea
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    name={ this.props.name }
                    maxLength={this.props.maxLength}
                />
            </div>
        );
    }
}