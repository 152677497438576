import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import countryRegionData from "country-region-data/dist/data-umd";

//action imports
import { searchFieldChanged } from "../../../actions/SearchActions";

const { Option } = Select;

class CountryRegionPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: this.props.country,
            region: this.props.region
        }

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                country: nextProps.country,
                region: nextProps.region,
            });
        }
    }

    handleCountryChange = (value) => {
        if (value!== undefined && value!== null) {
            const selectedCountry = JSON.parse(value);
            this.props.searchFieldChanged({ prop: "country", value: selectedCountry });
            this.props.searchFieldChanged({ prop: "region", value: null });
        } else {
            // Clear action detected
            this.props.searchFieldChanged({ prop: "country", value: null });
            this.props.searchFieldChanged({ prop: "region", value: null });
        }
    };

    handleRegionChange = (value) => {
        if (value!== undefined && value!== null) {
            const selectedRegion = JSON.parse(value);
            this.props.searchFieldChanged({ prop: "region", value: selectedRegion });
        } else {
            // Clear action detected
            this.props.searchFieldChanged({ prop: "region", value: null });
        }
    };

    render() {
        const { country, region } = this.state;
        const isCountrySelected = country !== null;
        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Country & Region</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Select
                        value={JSON.stringify(country)}
                        onChange={this.handleCountryChange}
                        placeholder="Select Country"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%", borderColor: '#000' }}
                        className="custom-select-border"
                    >
                        {countryRegionData.map((country) => (
                            <Option
                                key={country.countryName}
                                value={JSON.stringify(country)}
                            >
                                {country.countryName} ({country.countryCode})
                            </Option>
                        ))}
                    </Select>

                    <Select
                        value={region ? JSON.stringify(region) : ""}
                        onChange={this.handleRegionChange}
                        placeholder="Region"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        disabled={!isCountrySelected}
                        style={{ width: "100%", borderColor: '#000' }}
                        className="custom-select-border"
                    >
                        <Option key={null} value={null}>
                            All
                        </Option>
                        {country &&
                        country.regions &&
                        country.regions.map((region) => (
                            <Option key={region.name} value={JSON.stringify(region)}>
                                {region.name} ({region.shortCode})
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { country, region } = state.search;

    return {
        country,
        region,
    };
};

export default connect(mapStateToProps, {
    searchFieldChanged
})(CountryRegionPicker);
