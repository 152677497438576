import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

//actions import
import { fetchVettingResult } from '../../../../actions/PostsActions';

//components import
import {Spinner} from "../../../common/Spinner";

export class VettingResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            vettingResult: this.props.vettingResult,
            vettingLoading: this.props.vettingLoading,
        }

        this.renderVetting = this.renderVetting.bind(this);
    }

    componentDidMount() {
        //extract state
        const {item} = this.props;
        if(!(_.isEmpty(item))) {
            this.props.fetchVettingResult(item)
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                vettingResult: nextProps.vettingResult,
                vettingLoading: nextProps.vettingLoading,
            })
        }
    }


    renderVetting() {
        const {vettingResult, vettingLoading} = this.state;

        if (vettingLoading) {
            return <Spinner/>
        } else {
            if(!(_.isEmpty(vettingResult))) {
                return(
                    <div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Attitude:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.attitude} </p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Communication:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.communication}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Creativity:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.creativity}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Leadership:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.leadership}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Relationship:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.relationship}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Reliability:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.reliability}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Eq:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.eq}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Duration:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.duration}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Overall Impression:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.overallImpression}</p>
                        </div>
                        <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                            <p style={{ color: "white", fontFamily: "ramblaBold"}}>Comments:</p>
                            <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{vettingResult.comments}</p>
                        </div>
                    </div>
                )
            } else {
                return <p style={{ color: "#000" }}>There is no vetting result</p>;
            }
        }
    }

    render() {
        return (
            <div>{this.renderVetting()}</div>
        )
    }
}

const mapStateToProps = (state) => {

    const { vettingResult, vettingLoading } = state.posts;

    return{
        vettingResult,
        vettingLoading,
    }
}

export default connect(mapStateToProps, {fetchVettingResult})(VettingResult);