import React from "react";
import { connect } from "react-redux";

import { fetchCandidatesYearOfExperience } from "../../../actions/PostsActions";

import ExperienceChart from "./ExperienceChart";
import EducationChart from "./EducationChart";

import { Bar } from '@ant-design/charts';
import { Spinner } from "../../common/Spinner";

class DistributionLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateExperience: this.props.candidateExperience,
            candidateLevel: this.props.candidateLevel,
            distributionLoad: this.props.distributionLoad

        };
    }

    componentDidMount() {
        this.props.fetchCandidatesYearOfExperience();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                candidateExperience: nextProps.candidateExperience,
                candidateLevel: nextProps.candidateLevel,
                distributionLoad: nextProps.distributionLoad
            })
        }
    }

    render() {
        const {candidateExperience, candidateLevel, distributionLoad} = this.state;
        return (
            <div>
                {
                    distributionLoad?
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}><Spinner/></div> :
                    <div>
                        <div><ExperienceChart candidateExperience={candidateExperience} /></div>
                        <div><EducationChart candidateLevel={candidateLevel} /></div>
    
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { candidateExperience, candidateLevel, distributionLoad } = state.posts;

    return {
        candidateExperience, candidateLevel, distributionLoad
    }
};

export default connect(mapStateToProps, {fetchCandidatesYearOfExperience})(DistributionLevel);

