import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import arraySort from "array-sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//action imports
import { fetchSupportTickets, fetchAllSupportTickets } from "../../actions/SupportActions";

//component imports
import TicketCard from "./subComponents/cards/TicketCard";

class AllTickets extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            supportTickets: this.props.supportTickets,
            profile: this.props.profile,
        };
    }

    componentWillMount() {
        //extract state
        const { profile } = this.state;

        //make sure profile is not empty
        if (!(_.isEmpty(profile))) {
            if('location' in profile) {

                const locationID = profile.location.locationID;
                this.props.fetchSupportTickets({ locationID });

            } else {
                //
                this.props.fetchAllSupportTickets();
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.supportTickets !== this.props.supportTickets) {
            this.setState({ supportTickets: nextProps.supportTickets });
        }

        if(nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    renderSupportTickets() {
        //extract state
        const { supportTickets } = this.state;

        //check that there are my support tickets
        if (!(_.isEmpty(supportTickets))) {
            //support tickets available
            //put them in an array
            const tickets = _.map(supportTickets, ticket => ticket);

            //sort the tickets
            const sortedTickets = arraySort(tickets, "openTime", { reverse: true });

            //loop over the tickets
            return (
                <div className={"ticketBox"}>
                    {
                        sortedTickets.map(ticket => {
                            return(
                                <div key={ticket.ticketID}>
                                    <TicketCard ticket={ticket} admin={true}/>
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            //support tickets not available
            return <h3 className={"adminAlert"}>No support tickets are logged</h3>;
        }
    }

    render() {
        return(
            <div className={"ticketsContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <FontAwesomeIcon icon="ticket-alt" style={{fontSize: '1rem'}} />
                        <h2>Support Tickets</h2>
                    </div>
                </div>

                <div>
                    { this.renderSupportTickets() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {

    const { supportTickets } = state.support;
    const { profile } = state.profile;

    return {
        supportTickets,
        profile,
    }
};

export default connect(mapStateToProps, { fetchSupportTickets, fetchAllSupportTickets })(AllTickets);