import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import arraySort from "array-sort";
import moment from "moment";
import { Table, Button, Modal, message, Popconfirm } from 'antd';


//action import
import { deletePost, fetchPostCandidates, selectPost } from "../../../actions/PostsActions";
import EditJobPost from "./EditJobPost";
import { PropertyDeleteIcon } from 'hugeicons-react';
import CustomHeaderCard from '../../search/cards/CustomHeaderCard';
import Analysis from './Analysis';
//component imports


class JobPostsBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            posts: this.props.posts,
            postsLoading: this.props.postsLoading,
            jobPostDeleted: this.props.jobPostDeleted,
            jobPostDeletedFailed: this.props.jobPostDeletedFailed,

            profile: this.props.profile,
        };

        this.renderPosts = this.renderPosts.bind(this);
        this.onDeletePost = this.onDeletePost.bind(this);
        this.onSelectPost = this.onSelectPost.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                posts: nextProps.posts,
                postsLoading: nextProps.postsLoading,
                jobPostDeleted: nextProps.jobPostDeleted,
                jobPostDeletedFailed: nextProps.jobPostDeletedFailed,
                profile: nextProps.profile
            })
        }
    }

    
    onDeletePost(postID) {
        this.props.deletePost({postID});
    }

    onCancel() {
        message.info("Deletion is canceled");
    }

    renderSuccessMessage() {
        const {jobPostDeleted} = this.state;

        if (jobPostDeleted) {
            return <p>{message.success("Post is deleted successfully")}</p>
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {jobPostDeletedFailed} = this.state;

        if (jobPostDeletedFailed) {
            return <p>{message.error("Post is not deleted ")}</p>
        } else {
            return null;
        }
    }

    onSelectPost(post) {
        if(!(_.isEmpty(post))) {
            //

            const {navigate} = this.props;
            const postID = post.postID;

            this.props.selectPost(post);
            this.props.fetchPostCandidates({ postID });
            navigate('/post-review');
        }
    }

    checkCandidatesAccess(post){
        //-> extract state
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === 'superAdmin'){
                return(
                    <div onClick={ () => this.onSelectPost(post)}>
                        <p style={{color: 'rgba(50, 148, 255)', cursor: 'pointer'}}>Candidates</p>
                    </div>
                )
            } else {
                return null;
            }
        }
    };

    checkDeleteAccess(post){
        //-> extract state
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === 'superAdmin'){
                return(
                    <Popconfirm
                        title="Are you sure to delete this post?"
                        onConfirm={() => this.onDeletePost(post.postID)}
                        onCancel={() => this.onCancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{textAlign: 'center'}}><PropertyDeleteIcon size={20} className={"trashIcon2"}/></div>
                    </Popconfirm>
                )
            } else {
                return null;
            }
        }
    };

    checkEditAccess(post){
        //-> extract state
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === 'superAdmin'){
                return(
                    <div style={{textAlign: 'center'}}>
                        <EditJobPost post={post}/>
                    </div>
                )
            } else {
                return null;
            }
        }
    };

    renderPosts() {
        //extract state
        const { posts } = this.state;

        //check that there are open posts
        if (!(_.isEmpty(posts))) {

            const sortedPosts = arraySort(posts, "deadline", { reverse: true });

            let processedPosts = sortedPosts.map(post => {
                let seconds;
                post.deadline.seconds ? seconds = post.deadline.seconds : seconds = post.deadline._seconds;
                let time = moment.unix(seconds).format('ddd, Do MMM YYYY');
                
                const formattedLocation = `${post.companyLocation.country}, ${post.companyLocation.region}`;
                
                return { ...post, formattedLocation, time};
            });

            const columns = [
                {
                    title: <CustomHeaderCard>Company</CustomHeaderCard>,
                    dataIndex: 'companyName',
                    key: 'companyName',
                    render: (_, record) => (
                        <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.companyName}</div>
                    ),
                },
                {
                    title: <CustomHeaderCard>Title</CustomHeaderCard>,
                    dataIndex: 'postTitle',
                    key: 'postTitle',
                    render: (_, record) => (
                        <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.postTitle}</div>
                    ),
                },
                {
                    title: <CustomHeaderCard>Deadline On</CustomHeaderCard>,
                    dataIndex: 'time',
                    key: 'time',
                    render: (_, record) => (
                        <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.time}</div>
                    ),
                },
                {
                    title: <CustomHeaderCard>Type</CustomHeaderCard>,
                    dataIndex: 'jobType',
                    key: 'jobType',
                    render: (_, record) => (
                        <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.jobType}</div>
                    ),
                },
                {
                    title: <CustomHeaderCard>Status</CustomHeaderCard>,
                    dataIndex: 'status',
                    key: 'status',
                    render: (_, record) => (
                        <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.status}</div>
                    ),
                },
                {
                    title: <CustomHeaderCard>Location</CustomHeaderCard>,
                    dataIndex: 'formattedLocation',
                    key: 'formattedLocation',
                    render: (_, record) => (
                        <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.formattedLocation}</div>
                    ),
                },
                // {
                //     title: <CustomHeaderCard>Analysis</CustomHeaderCard>,
                //     dataIndex: 'analysis',
                //     key: 'analysis',
                //     render: (_, record) => (
                //         <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                //             <Analysis record={record}/>
                //         </div>
                //     ),
                // },
                {
                    title: <CustomHeaderCard>Review</CustomHeaderCard>,
                    key: 'review',
                    render: (_, record) => {
                        const { profile } = this.state;
                        if (isEmpty(profile)) {
                            return null;
                        } else {
                            const { role } = profile;
                            if (role === 'admin' || role === 'superAdmin') {
                                return (
                                    <div style={{ color: 'rgba(50, 148, 255)', fontFamily: 'ramblaRegular', cursor: 'pointer' }} onClick={() => this.onSelectPost(record)}>Candidates</div>
                                );
                            } else {
                                return null;
                            }
                        }
                    }
                },
                {
                    title: <CustomHeaderCard>Edit</CustomHeaderCard>,
                    key: 'edit',
                    render: (_, record) => {
                        const { profile } = this.state;
                        if (isEmpty(profile)) {
                            return null;
                        } else {
                            const { role } = profile;
                            if (role === 'admin' || role === 'superAdmin') {
                                return (
                                    <div style={{ cursor: 'pointer' }}><EditJobPost post={record}/></div>
                                );
                            } else {
                                return null;
                            }
                        }
                    }
                },
                {
                    title: <CustomHeaderCard>Delete</CustomHeaderCard>,
                    key: 'delete',
                    render: (_, record) => {
                        const { profile } = this.state;
                        if (isEmpty(profile)) {
                            return null;
                        } else {
                            const { role } = profile;
                            if (role === 'admin' || role === 'superAdmin') {
                                return (
                                    <Popconfirm
                                    title="Are you sure to delete this post?"
                                    onConfirm={() => this.onDeletePost(record.postID)}
                                    onCancel={() => this.onCancel()}
                                    okText="Yes"
                                    cancelText="No"
                                    >
                                        <div style={{ textAlign: 'center' }}>
                                            <PropertyDeleteIcon color="red" size={20} style={{ cursor: 'pointer' }} />
                                        </div>
                                    </Popconfirm>
                                );
                            } else {
                                return null;
                            }
                        }
                    }
                },
            ];

            return (
                <>
                    <Table dataSource={processedPosts} columns={columns} rowKey="id" />
                </>
            );

        } else {
            return <h2 className={"adminAlert"}>There are currently no job posts, add a job post to start</h2>;
        }
    }

    checkHeadersAccess(){
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'superAdmin' || role === 'admin'){
                return(
                    <div className={"jobOpeningListHeaderBox"}>
                        <div><h5>Company</h5></div>
                        <div><h5>Title</h5></div>
                        <div><h5>Deadline On</h5></div>
                        <div><h5>Type</h5></div>
                        <div><h5>Status</h5></div>
                        <div><h5>Location</h5></div>
                        <div><h5>Review</h5></div>
                        <div><h5>Edit</h5></div>
                        <div><h5>Delete</h5></div>
                    </div>
                )
            }
        }
    }

    render() {
        return(
            <div className={"viewJobOpeningListBox"}>
                <h4 style={{ color: "black", fontFamily: "ramblaBold" }}>Job Post List</h4>

                {/* {this.checkHeadersAccess()} */}

                <div>
                    { this.renderPosts() }
                    { this.renderSuccessMessage() }
                    { this.renderFailedMessage() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.profile;
    const { posts, postsLoading, jobPostDeleted, jobPostDeletedFailed } = state.posts;

    return {
        profile,

        posts,
        postsLoading,
        jobPostDeleted,
        jobPostDeletedFailed,

    }
};

// Wrapper functional component to use hooks
function JobPosts(props) {
    const navigate = useNavigate();

    return <JobPostsBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, {deletePost, fetchPostCandidates, selectPost })(JobPosts);