import React, { useState } from "react";
import { Modal, Typography } from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Text } = Typography;

const DescriptionModal = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderText = () => {
        return (
            <FontAwesomeIcon icon={"eye"} size={"1x"} color="#0793d9" style={{ cursor: "pointer"}} onClick={handleToggleModal}/>
        );
    };

    return (
        <div>
            {renderText()}
            <Modal
                title="Job Description"
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={900}
            >
                <p style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.description}</p>
            </Modal>
        </div>
    );
};

export default DescriptionModal;