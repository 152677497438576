
import React from "react";
import {connect} from "react-redux";
import { Modal, Typography, Popconfirm, message, Switch } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { updateVerification } from "../../../../actions/SettingsActions";

class DescriptionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            verifyVisible: false,
            checked: false
        };

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleVerifyCancel = this.handleVerifyCancel.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.renderText = this.renderText.bind(this);
    }

    handleToggleModal() {
        this.setState(prevState => ({
            isModalVisible:!prevState.isModalVisible
        }));
    };

    handleToggle() {
        this.setState({ verifyVisible: true });
    };

    handleVerifyCancel() {
        this.setState({ verifyVisible: false });
    };

    handleConfirm(item) {
        this.setState(prevState => ({
            verifyVisible: false,
            checked:!prevState.checked
        }), () => {
            this.props.updateVerification({ recruiter: item, verified: this.state.checked })
              .then(() => {
                    message.success(`Account has been ${this.state.checked? 'unverified' : 'verified'}.`);
                })
              .catch((error) => {
                    console.error('Failed to update verification:', error);
                    message.error('An error occurred while updating the verification status.');
                });
        });
    };

    renderText() {
        return (
            <FontAwesomeIcon icon={"eye"} size={"1x"} color="#0793d9" style={{ cursor: "pointer" }} onClick={this.handleToggleModal} />
        );
    };

    render() {
        const { record } = this.props;
        return (
            <div>
                {this.renderText()}
                <Modal
                    title="Recruitment"
                    visible={this.state.isModalVisible}
                    onOk={this.handleToggleModal}
                    onCancel={this.handleToggleModal}
                    width={900}
                >
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Verified:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.verified ? "Verified" : "Not Verified"}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Company Name:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.companyName}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Location:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>P.O Box {record.companyLocation.postalCode} | {record.companyLocation.streetAddress} {record.companyLocation.region}, {record.companyLocation.country}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Admin Name</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.firstName} {record.lastName}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Admin Email</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.email}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Admin Phone</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.phone}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Company Email:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.companyEmail}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Company Phone:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.companyPhone}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Active:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.active ? "Active" : "Not Active"}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Disabled:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.disabled ? "Disabled" : "Not Disabled"}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Paid:</p>
                        <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.paid ? "Paid" : "Not Paid"}</p>
                    </div>
                    <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                        <p style={{ color: "white", fontFamily: "ramblaBold"}}>Verify:</p>
                        <Popconfirm
                            title={`Are you sure you want to ${this.state.checked? 'unverify' :'verify'} this account?`}
                            visible={this.state.verifyVisible}
                            onConfirm={() => this.handleConfirm(record)}
                            onCancel={this.handleVerifyCancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Switch
                                checked={this.state.checked}
                                onChange={this.handleToggle}
                                checkedChildren="Verify"
                                unCheckedChildren="Unverify"
                            />
                        </Popconfirm>

                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateTopProps = state => {

    return {

    }
};

export default connect(mapStateTopProps, { updateVerification })(DescriptionModal);
