import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {message, Button, Input } from "antd";
import {ClipLoader} from "react-spinners";

//actions import
import {candidateSelection, postsFieldChanged} from "../../../actions/PostsActions";

//components import

//property declarations
const { TextArea } = Input;


class SelectCandidate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            selectedCandidate: this.props.selectedCandidate,
            selectedPost: this.props.selectedPost,
            profile: this.props.profile,

            selectionComment: this.props.selectionComment,
            selectionLoading: this.props.selectionLoading,
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                selectedPost: nextProps.selectedPost,
                profile: nextProps.profile,

                selectionComment: nextProps.selectionComment,
                selectionLoading: nextProps.selectionLoading
            })
        }
    }

    renderButton() {
        const {selectionLoading} = this.state;
        //
        if (selectionLoading){
            return (
                <div style={{align: 'center', marginLeft: 330 }}>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return (
                <div className={"modalButton"}>
                    <Button type="default" onClick={() => this.onAssign(true)}>
                        <span style={{ marginTop: "0.15rem", fontFamily: "ramblaRegular"}}>Select Candidate</span>
                    </Button>
                    <Button type="default" onClick={() => this.onAssign(false)} style={{ margin: "0 1rem 0 1rem", backgroundColor: "red" }}>
                        <span style={{ marginTop: "0.15rem", fontFamily: "ramblaRegular"}}>Unsuccessful Interview</span>
                    </Button>
                </div>
            );
        }
    }

    onAssign(selected) {
        let { selectedCandidate, profile, selectedPost, selectionComment } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || (profile.role === "superAdmin")) {
                //check if selected employee is not empty
                if (!(_.isEmpty(selectedCandidate))) {
                    const userID = selectedCandidate.userID;
                    const firstName = selectedCandidate.firstName;
                    //check if fetched asset is not empty
                    if (!(_.isEmpty(selectedPost))) {
                        const postID = selectedPost.postID;
                        const postTitle = selectedPost.postTitle;
                        const companyName = selectedPost.companyName;
                        this.props.candidateSelection({
                            firstName, postTitle, companyName, selected, selectionDate: new Date(), postID, userID, selectionComment
                        });
                    } else {
                        message.warning('Please select a vacancy post');
                    }
                } else {
                    message.warning("There is no candidate to select");
                }
            } else {
                message.warning("Un-Authorized Operation");
            }
        }
    }

    render() {
        const { selectedCandidate, selectedPost } = this.state;
        return (
            <div>
                <div className={"modalDetails"}>
                    <h3>Inform {selectedCandidate.firstName} on {selectedPost.postTitle} Selection</h3>
                </div>

                <div style={{ margin: "1rem 0"}}>
                    <p style={{ fontFamily: "ramblaBold", color: "#000" }}>Add in any short comment</p>
                    <TextArea
                        rows={3}
                        onChange={(event) => this.props.postsFieldChanged({ prop: "selectionComment", value: event.target.value })}
                        placeholder="Max length is 200 characters" maxLength={200}
                    />
                </div>

                {this.renderButton()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const {selectedCandidate, selectedPost, selectionLoading, selectionComment } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        selectedPost,
        selectionLoading,
        profile,
        selectionComment
    }
};

export default connect(mapStateToProps, {candidateSelection, postsFieldChanged})(SelectCandidate);