import React from "react";
import {
    isMobile,
    isAndroid,
    isIOS,
} from "react-device-detect";
import Nafasi from "../../assets/logos/taglineLogo.png";
import {Spinner} from "../common/Spinner";

class Download extends React.Component {
    componentDidMount() {
        if (isMobile) {
            if(isIOS) {
                window.location.href = "https://apps.apple.com/tz/app/nafasi/id6450264936";
            }

            if(isAndroid) {
                window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.nafasi.io";
            }
        } else {
            alert("View Download Link From Mobile Phone");
        }
    }

    render() {
        return(
            <div style={{ display: "grid", justifyContent: "center", alignItems: "center", margin: "4rem"}}>
                <img src={Nafasi} style={{ height: "9rem", width: "10rem", margin: "0 auto" }}/>
                <h3 style={{
                    textAlign: "center",
                    fontFamily: "ramblaBold",
                    color: "#000",
                    margin: "2rem",
                    padding: "2rem",
                    backgroundColor: "#ffb400"
                }}>Downloading</h3>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spinner/>
                </div>
            </div>
        );
    }
}

export default Download;