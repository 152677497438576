import React from "react";
import {connect} from 'react-redux';
import _ from 'lodash';

//actions import
import { } from "../../../actions/SettingsActions";

//components import
import AddAssets from "./AddAssets";


class Assets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.profile !== this.props.profile ) {
            this.setState({ profile: nextProps.profile })
        }
    }


    render() {
        return (
            <div>
                <AddAssets/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //-> extract state
    const { profile } = state.profile;
    const {trainingTitle, description, dateRange,} = state.settings;

    return {
        trainingTitle,
        description,
        dateRange,
        profile
    }
};

export default connect(mapStateToProps, { })(Assets);
