import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import arraySort from "array-sort";
import {message} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useNavigate} from "react-router-dom";

//actions import

//components import
import PostReviewCard from "../cards/PostReviewCard";

class PostReviewBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPost: this.props.selectedPost,
            candidates: this.props.candidates,
            candidateWithdrawn: this.props.candidateWithdrawn,
            candidateWithdrawnFailed: this.props.candidateWithdrawnFailed,
        };
        //
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                selectedPost: nextProps.selectedPost,
                candidates: nextProps.candidates,
                candidateWithdrawn: nextProps.candidateWithdrawn,
                candidateWithdrawnFailed: nextProps.candidateWithdrawnFailed
            });
        }

    }

    renderSuccessMessage() {
        const {candidateWithdrawn} = this.state;

        if (candidateWithdrawn) {
            return <p>{message.success("Candidate has been withdrawn successfully")}</p>
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {candidateWithdrawnFailed} = this.state;

        if (candidateWithdrawnFailed) {
            return <p>{message.error("Offer not withdrawn ")}</p>
        } else {
            return null;
        }
    }

    renderCandidateList() {
        //extract state
        const { candidates } = this.state;

        //check if candidate list is not empty
        if (!(_.isEmpty(candidates))) {
            //its loaded
            //sort by name
            const candidateArray = _.map(candidates, candidate => {
                return candidate;
            });

            let candidatesRectangle = [];
            //validate elements in candidate array
            for (let i = 0; i < candidateArray.length; i++) {
                // check if array object is not undefined
                if (typeof(candidateArray[i])!=='undefined') {

                    //
                    const candidate = candidateArray[i];
                    candidatesRectangle.push(candidate);
                }
            }

            const sortedCandidateList = arraySort(candidatesRectangle, "name", { reverse: true });

            return (
                <div><PostReviewCard candidates={sortedCandidateList}/></div>
            );
        } else {
            return <h5 className={"adminAlert"} >Currently, there are no candidates! Invite candidates to interview from your<span style={{ color: "#ffb400", cursor: "pointer"}}onClick={() => this.props.navigate("/shortlist")}> Shortlist</span></h5>;
        }
    }

    render() {
        return (
            <div className={"jobOpeningReviewContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <FontAwesomeIcon icon="file-signature" style={{fontSize: '1rem'}} />
                        <h2 style={{ color: "#fff", fontFamily: "ramblaBold" }}>Job Post Review</h2>
                    </div>
                </div>
                <div className={"candidateListBox"}>
                    <div>
                        <h4 style={{ color: "black", fontFamily: "ramblaBold" }}>Candidates List</h4>
                    </div>

                    {/* <div className={"candidatesHeaderBox"}>
                        <div><h5>Name</h5></div>
                        <div><h5>Status</h5></div>
                        <div><h5>Status Date</h5></div>
                        <div><h5>Interview Date</h5></div>
                        <div><h5>Interviewed</h5></div>
                        <div><h5>Selected</h5></div>
                        <div><h5>Acc. Int</h5></div>
                        <div><h5>Rej. Int</h5></div>
                        <div><h5>I.A.S Rating</h5></div>
                        <div><h5>Profile</h5></div>
                        <div><h5>Withdraw</h5></div>
                    </div> */}

                    <div>
                        { this.renderCandidateList() }
                    </div>
                    { this.renderSuccessMessage() }
                    { this.renderFailedMessage() }
                </div>
            </div>
        );
    }
}

const mapStateTopProps = state => {
    const { candidates, selectedPost, candidateWithdrawn, candidateWithdrawnFailed } = state.posts;

    return {
        candidates,
        selectedPost,
        candidateWithdrawn,
        candidateWithdrawnFailed,
    }
};

// Wrapper functional component to use hooks
function PostReview(props) {
    const navigate = useNavigate();

    return <PostReviewBase {...props} navigate={navigate} />;
}

export default connect(mapStateTopProps, { })(PostReview);
