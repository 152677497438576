import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

//actions import

//components import
import Avatar from 'antd/lib/avatar/avatar';

//file imports

class RecentSearches extends React.Component {

    constructor(props){
        super(props);
        this.scrollRef = React.createRef();

        this.state = {
            profile: this.props.profile,
            recentSearches: this.props.recentSearches,
            recentSearchesLoading: this.props.recentSearchesLoading,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                recentSearches: nextProps.recentSearches,
                recentSearchesLoading: nextProps.recentSearchesLoading,
            });
        }
    }

    handleScrollUp = () => {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollTop -= 100;
        }
    };

    handleScrollDown = () => {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollTop += 100;
        }
    };

    renderRecentSearches() {
        //extract state
        const { recentSearches } = this.state;

        if (recentSearches.length === 0) {
            return(
                <div className={"employeeDaysSummaryBox"}>
                    <p style={{ fontFamily: "ramblaBold", color: "#000"}}>Your recent searches will appear here</p>
                </div>
            );
        } else {
            return (
                <div className={"employeeDaysSummaryBox"}>
                    <div className="scroll-list" ref={this.scrollRef}>
                        <div className="scroll-content">
                            <div className="nice-list">
                                {recentSearches.map((item, index) => (
                                    <div key={index} className="list-item">
                                        <p style={{ fontFamily: 'ramblaBold', color: '#000', margin: ' 0px' }}>{item.country ? item.country : 'All Countries'}</p>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <p style={{ fontFamily: 'ramblaRegular', color: '#000', margin: '0px ' }}>{item.skill ? item.skill : 'Any Skills'}</p>
                                            <p style={{ fontFamily: 'ramblaRegular', color: '#000', margin: '0px ' }}>{item.certificateID ? `, ${item.certificateID}` : ', Any Certification'}</p>
                                       </div>
                                       <p style={{ fontFamily: 'ramblaBold', color: '#46494A', margin: '0px' }}>{item.industryID ? item.industryID : 'Any Industry'}</p>
                                   </div>
                                ))}
                                
                             </div> 
                             
                        </div>

                        <div className="scroll-icons">
                            <div className="scroll-icon scroll-up" onClick={this.handleScrollUp}>
                                <FaChevronUp size={24} color="#ffb400" />
                            </div>
                            <div className="scroll-icon scroll-down" onClick={this.handleScrollDown}>
                                <FaChevronDown size={24} color="#ffb400" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        //extract state
        const { profile } = this.state;

        let companyName = '';
        //check that my profile is not empty
        if (!(_.isEmpty(profile))) {
            //check that initials is present
            if (`companyName` in profile) {
                companyName = profile.companyName;
            }
        }

        return (
            <div className={"employeeSummaryContainer"}>
                <div className={"employeeInfoBox"}>
                    <div className={"employeeNameBox"}>
                        {/* include employees name and display photo */}
                        <Avatar style={{backgroundColor: '#056899',  margin: "1rem 0" }}>
                            {companyName[0]}
                        </Avatar>
                        <h4 style={{  margin: "1.3rem 0" }}>Recent Search</h4>
                    </div>

                    {this.renderRecentSearches()}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { recentSearches, recentSearchesLoading } = state.home;
    const { profile } = state.profile;

    return {
        profile,
        recentSearches,
        recentSearchesLoading
    }
};

export default connect(mapStateToProps)(RecentSearches);