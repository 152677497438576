import React from 'react';
import { Select } from 'antd';


class SelectEmploymentType extends React.Component {


    render() {

        const { Option } = Select;

        return (
            <div className={"inputTypeSelect"}>
                <div>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="fullTime">Full Time</Option>
                    <Option value="partTime">Part Time</Option>
                    <Option value="contract">Contractor</Option>
                    <Option value="temporary">Temporary</Option>
                    <Option value="internship">Internship</Option>
                    <Option value="field">Field</Option>
                </Select>

            </div>
        );
    }
}

export default (SelectEmploymentType);
