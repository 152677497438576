import React from 'react';

export const Button = ({text, onPress, disabled }) => {
    return (
        <div className={"buttonBox"}>
            <button onClick={onPress} disabled={disabled}>
                <span style={{ marginTop: "0.05rem", fontFamily: "ramblaRegular"}}>{text}</span>
            </button>
        </div> 
    );
};
