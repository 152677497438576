import React from "react";
import { Table, Button, Modal, message } from 'antd';

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class LanguageCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            languages: this.props.languages,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                languages: nextProps.languages,
            });
        }
    }

    renderLanguage(){
        const { languages } = this.state;

        const columns = [
            {
                title: <CustomHeaderCard>Language</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Proficiency</CustomHeaderCard>,
                dataIndex: 'proficiency.title',
                key: 'proficiency.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.proficiency.title}</div>
                ),
            },
        ];

        return (
            <Table dataSource={languages} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderLanguage()}
            </div>
        );
    }
}

export default LanguageCard;