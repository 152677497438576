import React, { useState } from "react";
import { Modal, Typography } from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Text } = Typography;

const DescriptionModal = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderText = () => {
        return (
            <FontAwesomeIcon icon={"eye"} size={"1x"} color="#0793d9" style={{ cursor: "pointer"}} onClick={handleToggleModal}/>
        );
    };

    return (
        <div>
            {renderText()}
            <Modal
                title="Feedback"
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={900}
            >
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Consent:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.consent ? "Reach Out" : "Don't Reach Out"} </p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Name:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.name}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Gender:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.gender}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Ease of Use:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.easeOfUse.title}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Overall Experience:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.overallExperience.title}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Registration Process:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.registrationProcess.title}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Email:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.email}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Phone:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.phone}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>First Impression:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.firstImpression}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Most Liked Feature:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.mostLikedFeature}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Least Liked Feature:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.leastLikedFeature}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Suggestions:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.suggestions}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Additional Feature:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.additionalFeature}</p>
                </div>
                <div style={{ backgroundColor: "black", margin: "0.5em", padding: '0.5rem' }}>
                    <p style={{ color: "white", fontFamily: "ramblaBold"}}>Additional Comments:</p>
                    <p style={{ color: "white", fontFamily: "ramblaRegular"}}>{record.additionalComments}</p>
                </div>
            </Modal>
        </div>
    );
};

export default DescriptionModal;