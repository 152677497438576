import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {useNavigate} from "react-router-dom";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import arraySort from "array-sort";
import moment from "moment";
import { Table, Button, Modal, message, Switch } from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//action import
import { updateGraphicSent } from "../../../../actions/PostsActions"

//component imports
import CustomHeaderCard from '../../../search/cards/CustomHeaderCard';
import DescriptionModal from './DescriptionModal';


class AdminJobPosts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            adminJobs: this.props.adminJobs,
            visible: false,
        };

        this.renderPosts = this.renderPosts.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.adminJobs !== this.props.adminJobs) {
            this.setState ({adminJobs: nextProps.adminJobs})
        }
	}
    
    onDeletePost(postID) {
        this.props.deletePost({postID});
    }

    onCancel() {
        message.info("Deletion is canceled");
    }

    onSelectPost(post) {
        if(!(_.isEmpty(post))) {
            const {navigate} = this.props;
            const postID = post.postID;
            console.log('fetch candidates', postID)


            this.props.selectPost(post);
            this.props.fetchPostCandidates({ postID });
            navigate('/post-review');
        }
    }


    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };


    handleSwitchChange(isChecked, record) {
        if (record.graphicSent) {
            this.props.updateGraphicSent({record, graphicSent: false});
        } else {
            this.props.updateGraphicSent({record, graphicSent: true});
        }

    }
    

    renderPosts() {
        //extract state
        const { adminJobs } = this.state;

        const sortedPosts = arraySort(adminJobs, "deadline", { reverse: true });

        let processedPosts = sortedPosts.map(post => {
            let seconds;
            post.deadline.seconds ? seconds = post.deadline.seconds : seconds = post.deadline._seconds;
            let time = moment.unix(seconds).format('ddd, Do MMM YYYY');
            
            const formattedLocation = `${post.companyLocation.country}, ${post.companyLocation.region}`;
            
            return { ...post, formattedLocation, time};
        });

        const columns = [
            {
                title: <CustomHeaderCard>Company</CustomHeaderCard>,
                dataIndex: 'companyName',
                key: 'companyName',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.companyName}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'postTitle',
                key: 'postTitle',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.postTitle}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Deadline On</CustomHeaderCard>,
                dataIndex: 'time',
                key: 'time',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.time}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Type</CustomHeaderCard>,
                dataIndex: 'jobType',
                key: 'jobType',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.jobType}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Status</CustomHeaderCard>,
                dataIndex: 'status',
                key: 'status',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.status}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Location</CustomHeaderCard>,
                dataIndex: 'formattedLocation',
                key: 'formattedLocation',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.formattedLocation}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Description</CustomHeaderCard>,
                dataIndex: 'description',
                key: 'description',
                render: (_, record) => (
                    <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        <DescriptionModal record={record}/>
                    </div>
                ),
            },
            {
                title: <CustomHeaderCard>Graphic Sent</CustomHeaderCard>,
                dataIndex: 'graphicSent',
                key: 'graphicSent',
                render: (_, record) => (
                    'graphicSent' in record && (
                        <Switch 
                            checked={record.graphicSent} 
                            onChange={(e) => this.handleSwitchChange(e, record)}
                        />
                    )
                    || <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>Not Initialized</div>
                ),
            },
        ];

        return (
            <>
                <Table dataSource={processedPosts} columns={columns} rowKey="id" />
            </>
        );
    }


    render() {
        return(
            <div className={"viewJobOpeningListBox"}>
                <div>{ this.renderPosts() }</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
	//-> extract state
	const { adminJobs } = state.settings;

	return{
		adminJobs
	}
}

export default connect(mapStateToProps, { updateGraphicSent })(AdminJobPosts);