import {
    SAVE_TERMS_AND_CONDITIONS,
    SAVE_TERMS_AND_CONDITIONS_SUCCESSFUL,
    SAVE_TERMS_AND_CONDITIONS_FAILED,
    DELETE_COMPANY_ASSETS,
    DELETE_COMPANY_ASSETS_FAILED,
    DELETE_COMPANY_ASSETS_SUCCESSFUL,
    ACCOUNT_INFO_CHANGED,
    ASSETS_DETAILS_CHANGED,
    FETCH_ACCOUNT_INFO,
    FETCH_ACCOUNT_INFO_FAILED,
    FETCH_ACCOUNT_INFO_SUCCESSFUL,
    FETCH_COMPANY_ASSETS,
    FETCH_COMPANY_ASSETS_FAILED,
    FETCH_COMPANY_ASSETS_SUCCESSFUL,
    FETCH_COMPANY_EMPLOYEES,
    FETCH_COMPANY_EMPLOYEES_FAILED,
    FETCH_COMPANY_EMPLOYEES_SUCCESSFUL,
    FETCH_COMPANY_TRAININGS,
    FETCH_COMPANY_TRAININGS_FAILED,
    FETCH_COMPANY_TRAININGS_SUCCESSFUL,
    FETCH_HOLIDAYS,
    FETCH_HOLIDAYS_FAILED,
    FETCH_HOLIDAYS_SUCCESSFUL,
    HOLIDAY_DETAILS_CHANGED,
    SAVE_COMPANY_ASSETS,
    SAVE_COMPANY_ASSETS_FAILED,
    SAVE_COMPANY_ASSETS_SUCCESSFUL,
    SAVE_NEW_TRAINING,
    SAVE_NEW_TRAINING_FAILED,
    SAVE_NEW_TRAINING_SUCCESSFUL,
    SELECTED_TRAINEES_CHANGED,
    SELECTED_TRAINING,
    TERMS_AND_CONDITIONS_CHANGED,
    TRAINING_DETAILS_CHANGED,
    TRAINING_EMPLOYEE_DETAILS_CHANGED,
    SAVE_ACCOUNT_INFO,

    FETCH_TERMS_AND_CONDITIONS,
    FETCH_TERMS_AND_CONDITIONS_SUCCESSFUL,
    FETCH_TERMS_AND_CONDITIONS_FAILED,

    SAVE_ACCOUNT_INFO_SUCCESSFUL,
    SAVE_ACCOUNT_INFO_FAILED,
    SETTINGS_FIELD_CHANGED,
    FETCH_BENEFIT_CATEGORIES_SUCCESSFUL,
    SAVE_BENEFIT_CATEGORY,
    SAVE_BENEFIT_CATEGORY_SUCCESSFUL,
    SAVE_BENEFIT_CATEGORY_FAILED,


    //delete posts
    DELETE_COMPANY_TRAINING,
    DELETE_COMPANY_TRAINING_SUCCESSFUL,
    DELETE_COMPANY_TRAINING_FAILED,

    CLEAR_COMPANY_TRAINING_MESSAGES,

    FETCH_TRAINEES,
    FETCH_TRAINEES_SUCCESSFUL,
    FETCH_TRAINEES_FAILED,
    CLEAR_COMPANY_ASSET_MESSAGE,
    DELETE_BENEFIT_CATEGORY,
    DELETE_BENEFIT_CATEGORY_SUCCESSFUL,
    DELETE_BENEFIT_CATEGORY_FAILED,
    CLEAR_COMPANY_BENEFIT_MESSAGE,

    //location
    SAVE_LOCATION,
    SAVE_LOCATION_SUCCESSFUL,
    SAVE_LOCATION_FAILED,

    //fetch
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_SUCCESSFUL,
    FETCH_LOCATIONS_FAILED,
    SAVE_PAYMENT_TYPE,
    SAVE_PAYMENT_TYPE_SUCCESSFUL,
    SAVE_PAYMENT_TYPE_FAILED,
    SAVE_PROMO_CODE,
    SAVE_PROMO_CODE_SUCCESSFUL,
    SAVE_PROMO_CODE_FAILED,
    SAVE_BLOG,
    SAVE_BLOG_SUCCESSFUL,
    SAVE_BLOG_FAILED,
    EXTRACT_DATA,
    EXTRACT_DATA_SUCCESSFUL,
    EXTRACT_DATA_FAILED,
    FETCH_FEEDBACK,
    FETCH_FEEDBACK_SUCCESSFUL,
    FETCH_FEEDBACK_FAILED,
    FETCH_ASSESSMENT,
    FETCH_ASSESSMENT_SUCCESSFUL,
    FETCH_ASSESSMENT_FAILED,
    FETCH_RECRUITERS_REQUESTS,
    FETCH_RECRUITERS_REQUESTS_SUCCESSFUL, FETCH_RECRUITERS_REQUESTS_FAILED,
    FETCH_ADMIN_POSTS,
    FETCH_ADMIN_POSTS_SUCCESS,
    FETCH_ADMIN_POSTS_FAIL,

} from "../actions/Types";


const INITIAL_STATE = {
    loading: false,

    //fetched objects
    companyEmployees: {},
    holidays: {},

    //terms and conditions
    docFile: '',
    docTitle: '',
    fetchedTermsAndConditions: {},
    termsSuccess: false,
    termsLoading: false,
    termsFailed: false,

    //trainings
    companyTrainings: {},
    trainees: {},
    companyTrainingsFetchLimit: 10,

    //company education
    assets: {},

    //add accounts to package
    numberOfAccount: '',


    //account info
    accountInfo: {},
    companyName: "",
    companyEmail: "",
    website: "",
    companyAddress: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    accountInfoLoading: false,

    //add new posts
    trainingTitle: '',
    trainingDescription: '',
    dateRange: '',
    employeeNumber: '',
    employeeName: '',
    employeeDepartment: '',
    selectedTrainees: [],
    selectedTraining: '',
    saveTrainingLoading: false,
    saveTrainingSuccess:false,
    saveTrainingFailed:false,
    assignTraineeLoading: false,

    deleteTrainingSuccess:false,
    deleteTrainingFailed: false,

    //add company education
    assetCategory: '',
    assetTitle: '',
    serialNumber: '',
    saveAssetLoading: false,
    saveAssetSuccessful: false,
    saveAssetFailed: false,
    deleteAssetLoading: false,
    deleteAssetSuccessful: false,
    deleteAssetFailed: false,

    //holiday
    holidayTitle: '',
    holidayDate: '',
    saveHolidayLoading: false,

    //benefits
    benefits: {},
    benefitName: "",
    benefitCategoryLoading: false,
    benefitCategorySaved: false,
    benefitCategoryFailed: false,
    benefitCategoryDeleteLoading: false,
    benefitCategoryDeletedSuccessful: false,
    benefitCategoryDeletedFailed: false,

    //location
    locations: {},
    locationName: "",
    locationCountry: "",
    locationRegion: "",

    //payments
    paymentTypeTitle: "",
    savePaymentTypeLoading: false,

    //promo code
    email: "",
    affiliateCode: "",
    bankName: "",
    bankCode: "",
    accountNumber: "",
    accountName: "",
    commissionPercent: "",
    promoCodeLoading: false,

    adminPostLoading: false,
    adminJobs: [],

    title: "",
    description: "",
    editorState: "",

    feedback: [],
    assessment: [],
    recruitersRequests: []

};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const SettingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SETTINGS_FIELD_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value }

        case ACCOUNT_INFO_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case TRAINING_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case HOLIDAY_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case TRAINING_EMPLOYEE_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case ASSETS_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case TERMS_AND_CONDITIONS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        case SAVE_ACCOUNT_INFO:
            return {...state, accountInfoLoading: true };
        case SAVE_ACCOUNT_INFO_SUCCESSFUL:
            return {...state,  companyName: "", companyEmail: "", website: "", accountInfoLoading: false, };
        case SAVE_ACCOUNT_INFO_FAILED:
            return {...state, companyName: "", companyEmail: "", website: "", accountInfoLoading: false, };

        case SAVE_TERMS_AND_CONDITIONS:
            return {...state, termsLoading: true };
        case SAVE_TERMS_AND_CONDITIONS_SUCCESSFUL:
            return {...state, termsLoading: false, termsSuccess: true };
        case SAVE_TERMS_AND_CONDITIONS_FAILED:
            return {...state, docFile: {}, docTitle: "", termsLoading: false, termsFailed: true};

        case FETCH_ADMIN_POSTS:
            return {...state, adminPostLoading: true};
        case FETCH_ADMIN_POSTS_SUCCESS:
            return {...state, adminPostLoading: false, adminJobs: deepClone(action.payload) };
        case FETCH_ADMIN_POSTS_FAIL:
            return {...state, adminPostLoading: false};

        case SAVE_COMPANY_ASSETS:
            return {...state, saveAssetLoading: true };
        case SAVE_COMPANY_ASSETS_SUCCESSFUL:
            return {...state, assetTitle: "", serialNumber: "", saveAssetLoading: false, saveAssetSuccessful: true };
        case SAVE_COMPANY_ASSETS_FAILED:
            return {...state, serialNumber: "", saveAssetLoading: false, saveAssetFailed: true };

        case SAVE_NEW_TRAINING:
            return {...state, saveTrainingLoading: true };
        case SAVE_NEW_TRAINING_SUCCESSFUL:
            return {...state, saveTrainingLoading: false, saveTrainingSuccess: true, trainingTitle: "", trainingDescription: "",  dateRange: "" };
        case SAVE_NEW_TRAINING_FAILED:
            return {...state, saveTrainingLoading: false, saveTrainingFailed: true, trainingTitle: "", trainingDescription: "",  dateRange: "" };

        case SAVE_BENEFIT_CATEGORY:
            return {...state, benefitCategoryLoading: true };
        case SAVE_BENEFIT_CATEGORY_SUCCESSFUL:
            return {...state, benefitCategoryLoading: false, benefitCategorySaved: true, benefitName: "" };
        case SAVE_BENEFIT_CATEGORY_FAILED:
            return {...state, benefitCategoryLoading: false, benefitCategoryFailed: true,};

            //fetch reducers
        case FETCH_ACCOUNT_INFO:
            return { ...state, };
        case FETCH_ACCOUNT_INFO_SUCCESSFUL:
            return { ...state, accountInfo: deepClone(action.payload)};
        case FETCH_ACCOUNT_INFO_FAILED:
            return { ...state, };

        case FETCH_COMPANY_EMPLOYEES:
            return {...state, };
        case FETCH_COMPANY_EMPLOYEES_SUCCESSFUL:
            return {...state, companyEmployees: deepClone(action.payload) };
        case FETCH_COMPANY_EMPLOYEES_FAILED:
            return {...state, };

        case FETCH_HOLIDAYS:
            return {...state, };
        case FETCH_HOLIDAYS_SUCCESSFUL:
            return {...state, holidays: deepClone(action.payload) };
        case FETCH_HOLIDAYS_FAILED:
            return {...state, };

        case FETCH_COMPANY_TRAININGS:
            return {...state, };
        case FETCH_COMPANY_TRAININGS_SUCCESSFUL:
            return {...state, companyTrainings: deepClone(action.payload.fetchedCompanyTrainings), companyTrainingsFetchLimit:deepClone(action.payload.companyTrainingsFetchLimit)};
        case FETCH_COMPANY_TRAININGS_FAILED:
            return {...state, };

        case FETCH_TRAINEES:
            return {...state, };
        case FETCH_TRAINEES_SUCCESSFUL:
            return {...state, trainees: deepClone(action.payload)  };
        case FETCH_TRAINEES_FAILED:
            return {...state, };

        case SELECTED_TRAINEES_CHANGED:
            return {...state, selectedTrainees: action.payload };
        case SELECTED_TRAINING:
            return {...state, selectedTraining: action.payload };

        case FETCH_COMPANY_ASSETS:
            return {...state, };
        case FETCH_COMPANY_ASSETS_SUCCESSFUL:
            return {...state, assets: deepClone(action.payload) };
           
        case FETCH_COMPANY_ASSETS_FAILED:
            return {...state, };

        case FETCH_TERMS_AND_CONDITIONS:
            return {...state, };
        case FETCH_TERMS_AND_CONDITIONS_SUCCESSFUL:
            return {...state, fetchedTermsAndConditions: deepClone(action.payload) };
        case FETCH_TERMS_AND_CONDITIONS_FAILED:
            return {...state, };
        //delete posts
        case DELETE_COMPANY_TRAINING:
            return {...state, deleteTrainingLoading:true,};
        case DELETE_COMPANY_TRAINING_SUCCESSFUL:
            return {...state, deleteTrainingLoading:false, deleteTrainingSuccess:true,};
        case DELETE_COMPANY_TRAINING_FAILED:
            return {...state, deleteTrainingLoading:false, deleteTrainingFailed:true,};

        case CLEAR_COMPANY_TRAINING_MESSAGES:
            return {...state, saveTrainingSuccess:false, saveTrainingFailed:false, deleteTrainingSuccess:false, deleteTrainingFailed:false,};

        case DELETE_COMPANY_ASSETS:
            return {...state, deleteAssetLoading: true };
        case DELETE_COMPANY_ASSETS_SUCCESSFUL:
            return {...state, deleteAssetLoading: false, deleteAssetSuccessful: true };
        case DELETE_COMPANY_ASSETS_FAILED:
            return {...state, deleteAssetLoading: false, deleteAssetFailed: true };
        case CLEAR_COMPANY_ASSET_MESSAGE:
            return {...state, deleteAssetLoading: false, deleteAssetSuccessful: false, deleteAssetFailed: false, saveAssetLoading: false, saveAssetSuccessful: false, saveAssetFailed: false };

        //delete benefit
        case DELETE_BENEFIT_CATEGORY:
            return {...state, benefitCategoryDeleteLoading: true };
        case DELETE_BENEFIT_CATEGORY_SUCCESSFUL:
            return {...state, benefitCategoryDeleteLoading: false, benefitCategoryDeletedSuccessful: true, };
        case DELETE_BENEFIT_CATEGORY_FAILED:
            return {...state, benefitCategoryDeleteLoading: false, benefitCategoryDeletedFailed: true, };
        case CLEAR_COMPANY_BENEFIT_MESSAGE:
            return {...state,  benefitCategoryDeleteLoading: false, benefitCategoryDeletedSuccessful: false, benefitCategoryDeletedFailed: false, benefitCategoryLoading: false, benefitCategorySaved: false, benefitCategoryFailed: false };


        case FETCH_BENEFIT_CATEGORIES_SUCCESSFUL:
            return { ...state, benefits: deepClone(action.payload)};

        case SAVE_LOCATION:
            return {...state, };
        case SAVE_LOCATION_SUCCESSFUL:
                return {...state, locationName: "", locationCountry: "", locationRegion: "", };
        case SAVE_LOCATION_FAILED:
            return {...state, };

        case FETCH_LOCATIONS:
            return { ...state, };
        case FETCH_LOCATIONS_SUCCESSFUL:
            return { ...state, locations: deepClone(action.payload) };
        case FETCH_LOCATIONS_FAILED:
            return { ...state, };

            //payment types
        case SAVE_PAYMENT_TYPE:
            return { ...state, savePaymentTypeLoading: true };
        case SAVE_PAYMENT_TYPE_SUCCESSFUL:
            return { ...state, savePaymentTypeLoading: false, paymentTypeTitle: "" };
        case SAVE_PAYMENT_TYPE_FAILED:
            return { ...state, savePaymentTypeLoading: false };

        case SAVE_PROMO_CODE:
            return { ...state, promoCodeLoading: true };
        case SAVE_PROMO_CODE_SUCCESSFUL:
            return { ...state, promoCodeLoading: false, email: "", affiliateCode: "", bankName: "", bankCode: "", accountNumber: "", accountName: "", commissionPercent: "" };
        case SAVE_PROMO_CODE_FAILED:
            return { ...state, promoCodeLoading: false };

        case SAVE_BLOG:
            return { ...state, blogLoading: true };
        case SAVE_BLOG_SUCCESSFUL:
            return { ...state, blogLoading: false, title: "", description: "", editorState: "" };
        case SAVE_BLOG_FAILED:
            return { ...state, blogLoading: false };

        case EXTRACT_DATA:
            return { ...state, loading: true };
        case EXTRACT_DATA_SUCCESSFUL:
            return { ...state, loading: false };
        case EXTRACT_DATA_FAILED:
            return { ...state, loading: false };


            //feedback
        case FETCH_FEEDBACK:
            return { ...state, loading: true };
        case FETCH_FEEDBACK_SUCCESSFUL:
            return { ...state, loading: false, feedback: deepClone(action.payload) };
        case FETCH_FEEDBACK_FAILED:
            return { ...state, loading: false };

            //assessment
        case FETCH_ASSESSMENT:
            return { ...state, loading: true };
        case FETCH_ASSESSMENT_SUCCESSFUL:
            return { ...state, loading: false, assessment: deepClone(action.payload) };
        case FETCH_ASSESSMENT_FAILED:
            return { ...state, loading: false };

            //admin recruiters
        case FETCH_RECRUITERS_REQUESTS:
            return { ...state, loading: true };
        case FETCH_RECRUITERS_REQUESTS_SUCCESSFUL:
            return { ...state, loading: false, recruitersRequests: deepClone(action.payload)};
        case FETCH_RECRUITERS_REQUESTS_FAILED:
            return { ...state, loading: false };

        default:
            return state;
    }
};

export default SettingReducer;
