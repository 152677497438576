import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { Tabs } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//action imports

//components import
import Assets from "./assets/Assets";
import Subscriptions from "./subscriptions/Subscriptions";
import Payments from "./payments/Payments";
import ViewTermsAndConditions from "./legal/ViewTermsAndConditions";
import PromoCodes from "./promoCodes/PromoCodes";
import BlogEditor from "./blogs/BlogEditor";
import ViewBlogs from "./blogs/ViewBlogs";
import Extracts from "./extracts/Extracts";
import Feedback from "./feedback/Feedback";
import RecruitersRequests from "./recruitersRequests/RecruitersRequests";
import Assessment from "./assessment/Assessment";
import AdminPost from "./posts/AdminPost";
import {AccountSetting03Icon} from "hugeicons-react";


class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.profile !== this.props.profile){
            this.setState({profile: nextProps.profile})
        }
    }

    callBack() {};

    checkAccess(){
        //-> extract state
        const { profile } = this.state;

        const { TabPane } = Tabs;

        if(!(_.isEmpty(profile))) {

            const {role} = this.state.profile;

            //-> check access level
            if (role === 'admin' || role === "superAdmin") {
                return (
                    <div className="settingsTab">
                        <Tabs
                            onChange={this.callBack()}
                            defaultActiveKey="1"
                            type="line"
                            tabBarStyle={{
                                height: '10vh',
                                backgroundColor: '#ecedff',
                                borderBottom: '0px',
                                fontFamily: 'ramblaBold',
                                color: '#2d7cbd',
                                padding: '0.1rem 0 0.1rem 0',
                            }}
                            tabBarGutter="1"
                            size="small"
                            tabPosition='top'
                            animated={{inkBar: true}}
                        >
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Assets</span>} key="1">
                                <Assets/>
                            </TabPane> : null}
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Payments</span>} key="2">
                                <Payments/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Subscriptions</span>} key="3">
                                <Subscriptions/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Legal</span>} key="4">
                                <ViewTermsAndConditions/>
                            </TabPane>
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>PromoCodes</span>} key="5">
                                <PromoCodes/>
                            </TabPane> : null}
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Blogs</span>} key="6">
                                <BlogEditor/>
                                <ViewBlogs/>
                            </TabPane> : null}
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Extracts</span>} key="7">
                                <Extracts/>
                            </TabPane> : null}
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Feedback</span>} key="8">
                                <Feedback/>
                            </TabPane> : null}
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>RecruitersRequests</span>} key="9">
                                <RecruitersRequests/>
                            </TabPane> : null}
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Assessment</span>} key="10">
                                <Assessment/>
                            </TabPane> : null}
                            { role === "superAdmin" ? 
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Job Posts</span>} key="11">
                                <AdminPost/>
                            </TabPane> : null}
                        </Tabs>
                    </div>
                )
            }
        }
    };

    render() {
        return (
            <div className={"settingsContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <AccountSetting03Icon size={30} style={{color: '#000'}} />
                        <h3>Settings</h3>
                    </div>
                </div>

                <div>
                    {this.checkAccess()}
                </div>
                <p style={{ textAlign: "center", fontSize: "0.8rem", fontFamily: "ramblaBold" }}>v1.0.0h</p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { profile } = state.profile;

    return {
        profile,
    }
};

export default connect(mapStateToProps, { })(Settings);
