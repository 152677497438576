import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Table, Popconfirm, Modal, message } from 'antd';
import moment from "moment";

import DescriptionModal from './DescriptionModal';
import CustomHeaderCard from '../../../search/cards/CustomHeaderCard';

class FeedbackCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: this.props.items,
			visible: false
		}
	}


	render() {
		const { items } = this.state;
		let processedResults = items.map(result => {
			let seconds;
			result.timestamp.seconds ? seconds = result.timestamp.seconds : seconds = result.timestamp._seconds;
			const time = moment.unix(seconds).format("DD/MM/YYYY | HH:mm");
	
			return { ...result, time};
		})

		const columns = [
            {
                title: <CustomHeaderCard>Name</CustomHeaderCard>,
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.name}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Gender</CustomHeaderCard>,
                dataIndex: 'gender',
                key: 'gender',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.gender}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Ease of Use</CustomHeaderCard>,
                dataIndex: 'easeOfUse.title',
                key: 'easeOfUse.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.easeOfUse.title}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Overall Experience</CustomHeaderCard>,
                dataIndex: 'overallExperience.title',
                key: 'overallExperience.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.overallExperience.title}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Registration Process</CustomHeaderCard>,
                dataIndex: 'registrationProcess.title',
                key: 'registrationProcess.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.registrationProcess.title}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Time</CustomHeaderCard>,
                dataIndex: 'time',
                key: 'time',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.time}</div>
                ),
            },
            {
                title: <CustomHeaderCard>View</CustomHeaderCard>,
                dataIndex: 'view',
                key: 'view',
                render: (_, record) => (
					<div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
						<DescriptionModal record={record}/>
					</div>
                ),
            },
        ];

		return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
		);
	}
}

export default FeedbackCard;