import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const smileyIcon = () => {
    return (
        <FontAwesomeIcon icon={"smile"} size={"1x"} color={"#22bb33"}/>
    )
};

export const sendIcon = () => {
    return (
        <FontAwesomeIcon icon={[ 'fab', 'telegram-plane']} style={{fontSize: '1.2rem'}} color={"#22bb33"}/>
    )
};

export const addUserIcon = () => {
    return (
        <FontAwesomeIcon icon={"user-edit"} size={"1x"} color={"green"}/>
    )
};

export const fileUploadIcon = () => {
    return (
        <FontAwesomeIcon icon={"file-upload"} size={"1x"} color={"green"}/>
    )
};

export const notificationUploadIcon = () => {
    return(
        <FontAwesomeIcon icon={"bell"} size={"1x"} color={"green"} pulse />
    )
};

export const addInfoIcon = () => {
    return (
        <FontAwesomeIcon icon={"check-square"} size={"1x"} color={"green"} />
    )
};

export const deleteInfoIcon = () => {
    return(
        <FontAwesomeIcon icon={"trash"} size={"1x"} color={"green"} className={"trashIcon2"} />
    )
};

export const fillWarning = () => {
    return(
        <FontAwesomeIcon icon={"exclamation-circle"} style={{fontSize: '1.2rem', marginRight: '0.8rem'}} color={"#ffcc00"} />
    )
};

