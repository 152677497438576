import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Table, Button, Modal, message } from 'antd';

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";
import DescriptionModal from './DescriptionModal';

class AwardsCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            awards: this.props.awards,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                awards: nextProps.awards,
            });
        }
    }

    renderAward(){
        const { awards } = this.state;

        let processedResults = awards.map(result => {

            let seconds;
            result.dateAwarded.seconds ? seconds = result.dateAwarded.seconds : seconds = result.dateAwarded._seconds;
            const awarded = moment.unix(seconds);
    
            const awardedString = awarded.format('DD MMM YYYY');


            return { ...result, awardedString };
        });

        const columns = [
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Description</CustomHeaderCard>,
                dataIndex: 'description',
                key: 'description',
                render: (_, record) => (
                    <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        <DescriptionModal record={record}/>
                    </div>
                ),
            },
            {
                title: <CustomHeaderCard>Date</CustomHeaderCard>,
                dataIndex: 'awardedString',
                key: 'awardedString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.awardedString}</div>
                ),
            }
        ];

         return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
        )

        //extract state
        const { title, description, dateAwarded } = award;


        return (
            <div className={"awardsValuesBox"}>
                <div style={{color:'black'}}><p>{title}</p></div>
                <div>
                    <div>
                        <p><FontAwesomeIcon icon="eye" color="#0793d9" size="1x" onClick={() => this.showModal()} style={{cursor: 'pointer'}} /></p>
                    </div>
                    <Modal
                        title={"Award Description"}
                        open={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}

                        footer={null}
                        width={900}
                    >
                        <p style={{color:'black'}}>{description}</p>
                    </Modal>
                </div>
                <div><p style={{color:'black'}}>{awardedString}</p></div>
            </div>
        )
    }

    render() {
        return(
            <div>
                {this.renderAward()}
            </div>
        );
    }
}

export default AwardsCard;