import firebase from "firebase";
import moment from "moment";

import {
    SUBSCRIPTION_FIELD_UPDATED,
    CHANGE_DETAILS,
    PACKAGE_CHOSEN,
    FETCH_MONTHS,
    FETCH_MONTHS_SUCCESSFUL,
    FETCH_MONTHS_FAILED,
    FETCH_PAYMENT_STATUS,
    FETCH_PAYMENT_STATUS_SUCCESSFUL,
    FETCH_PAYMENT_STATUS_FAILED,
    MAKE_PAYMENT_SUCCESSFUL,
    MAKE_PAYMENT,
    MAKE_PAYMENT_FAILED,
    FETCH_PAYMENTS,
    FETCH_PAYMENTS_FAILED,
    FETCH_PAYMENTS_SUCCESSFUL,
    MAKE_FOREIGN_PAYMENT, MAKE_FOREIGN_PAYMENT_SUCCESSFUL, MAKE_FOREIGN_PAYMENT_FAILED
} from "./Types";
import {message} from "antd";

export const subscriptionFieldUpdated = ({ prop, value }) => {
    return {
        type: SUBSCRIPTION_FIELD_UPDATED,
        payload: { prop, value },
    };
};

//AccountToPackage
export const changeAddAccountToPackage = ({ prop, value }) => {
    return{
            type: CHANGE_DETAILS,
            payload: { prop, value },
    };
};

export const packageChosen = ({ subscription, price, rate, employees }) => {
    return {
        type: PACKAGE_CHOSEN,
        payload: { subscription, price, rate, employees }
    };
};

export const fetchMonths = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_MONTHS });
        firebase.firestore().collection("adminPackageMonths")
            .onSnapshot(snapshot => {
                if (snapshot.size !== 0) {
                    let months = [];
                    snapshot.forEach(doc => {
                        const data = doc.data();
                        months.push(data);
                    });

                    dispatch({
                        type: FETCH_MONTHS_SUCCESSFUL,
                        payload: months
                    })
                }
            }, function (error) {
                console.log(error);
                dispatch({ type: FETCH_MONTHS_FAILED });
            })
    };
};


export const makePayment = ({ name, email, phone, paymentPackage, paymentMonths, amount, processingFee, carrier, paymentPhone, paymentDate, location }) => {
    return(dispatch) => {
        dispatch({ type: MAKE_PAYMENT });
        const code = Math.random().toString(36).substring(6).toUpperCase();
        const dateMoment = moment(paymentDate);
        const hourCode = dateMoment.format("HHmm");
        const referenceNumber = `ANF${hourCode}${code}`;

        const userID = firebase.auth().currentUser.uid;

        const data = JSON.stringify({
            name, email, phone, paymentPackage, paymentMonths, amount, processingFee, carrier, paymentPhone, paymentDate, referenceNumber, location, userID
        });

        //invoke custom database function
        fetch(`https://us-central1-nafasiio.cloudfunctions.net/adminPayment`, {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: data
        }).then(() => {
            message.success("Complete payment on your phone");
            dispatch({
                type: MAKE_PAYMENT_SUCCESSFUL,
                payload: referenceNumber
            });

        }).catch((error) => {
            console.log(error);
            dispatch({
                type: MAKE_PAYMENT_FAILED
            });
        });
    }
}

export const makeForeignPayment = ({ name, email, phone, paymentPackage, paymentMonths, amount, processingFee, paymentPhone, paymentDate, location, currency }) => {

    const code = Math.random().toString(36).substring(6).toUpperCase();
    const dateMoment = moment();
    const hourCode = dateMoment.format("HHmm");
    const referenceNumber = `PNF${hourCode}${code}`;

    const userID = firebase.auth().currentUser.uid;


    const payment = {
        name,
        email,
        phone,
        paymentPackage,
        paymentMonths,
        amount,
        processingFee,
        carrier: {
            carrierID: "paystack",
            title: "Paystack"
        },
        paymentPhone,
        paymentDate,
        currency,
        transactionID: referenceNumber,
        referenceNumber,
        location,
        userID,
    };

    return(dispatch) => {
        dispatch({ type: MAKE_FOREIGN_PAYMENT });

        console.log("entered");
        //invoke custom database function
        fetch(`https://us-central1-nafasiio.cloudfunctions.net/adminForeignPayment`, {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payment)
        })
            .then(response => response.json())
            .then((response) => {
                console.log("Here's your response");
                console.log(response);

                dispatch({
                    type: MAKE_FOREIGN_PAYMENT_SUCCESSFUL,
                    payload: response.data.data.authorization_url,
                    payment
                });

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: MAKE_FOREIGN_PAYMENT_FAILED });
        })
    }
};


export const fetchPaymentStatus = ({ transactionID }) => {

    return (dispatch) => {
        dispatch({ type: FETCH_PAYMENT_STATUS });
        firebase.firestore().collection("adminSubscriptionTransactions").doc(transactionID)
            .onSnapshot(snapshot => {
                if (snapshot.exists) {
                    const payment = snapshot.data();

                    dispatch({
                        type: FETCH_PAYMENT_STATUS_SUCCESSFUL,
                        payload: payment
                    });
                } else {
                    dispatch({ type: FETCH_PAYMENT_STATUS_FAILED });
                }
            }, function (e) {
                console.log(e);
                dispatch({ type: FETCH_PAYMENT_STATUS_FAILED });
            });
    }
};

export const fetchPayments = () => {

    return (dispatch) => {
        dispatch({ type: FETCH_PAYMENTS });
        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("users").doc("admins").collection(userID).doc("public").collection("payments")
            .orderBy("paymentDate", "desc")
            .onSnapshot(snapshot => {
                if (snapshot.size === 0) {
                    dispatch({ type: FETCH_PAYMENTS_FAILED });
                } else {
                    let payments = [];
                    snapshot.forEach(doc => {
                        const payment = doc.data();
                        payments.push(payment);
                    });

                    dispatch({
                        type: FETCH_PAYMENTS_SUCCESSFUL,
                        payload: payments
                    });
                }
            }, function (e) {
                console.log(e);
                dispatch({ type: FETCH_PAYMENTS_FAILED });
            });
    }
};