import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";

//action imports
import { fetchPosts } from "../../actions/PostsActions";

//component imports
import AddJobPost from "./subComponents/AddJobPost";
import JobPosts from "./subComponents/JobPosts";
import {LicenseDraftIcon} from "hugeicons-react";


class Posts extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
        }
    }

    componentWillMount() {
        const { profile } = this.state;

        if(!(_.isEmpty(profile))){
            this.props.fetchPosts();
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            this.setState({
                profile: nextProps.profile
            })
        }
    }

    render() {
        return(
            <div className={"hiringContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <LicenseDraftIcon size={30} style={{ color: "#000"}} />
                        <h3>Your Job Posts</h3>
                    </div>
                </div>

                <div className={"manageJobOpeningsBox"}>
                    <AddJobPost/>
                </div>

                <div className={"viewJobOpeningsBox"}>
                    <JobPosts/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { profile } = state.profile;

    return {
        profile,
    }
}

export default connect(mapStateToProps, { fetchPosts })(Posts);