import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import arraySort from "array-sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerService02Icon } from "hugeicons-react";

//action imports
import { fetchMySupportTickets } from "../../actions/SupportActions";

//component imports
// import OpenTicket from "./announcements/OpenTicket";
import TicketCard from "./subComponents/cards/TicketCard";

class Support extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mySupportTickets: this.props.mySupportTickets,
            profile: this.props.profile,
        };
    }

    componentWillMount() {
        this.props.fetchMySupportTickets();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.mySupportTickets !== this.props.mySupportTickets) {
            this.setState({ mySupportTickets: nextProps.mySupportTickets });
        }

        if(nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    renderMySupportTickets() {
        //extract state
        const { mySupportTickets } = this.state;

        //check that there are my support tickets
        if (!(_.isEmpty(mySupportTickets))) {
            //support tickets available
            //put them in an array
            const supportTickets = _.map(mySupportTickets, ticket => ticket);

            //sort the tickets
            const sortedTickets = arraySort(supportTickets, "openTime", { reverse: true });

            //loop over the tickets
            return (
                <div className={"ticketBox"}>
                    {
                        sortedTickets.map(ticket => {
                            return(
                                <div key={ticket.ticketID}>
                                    <TicketCard ticket={ticket} admin={false}/>
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            //support tickets not available
            return <h3 className={"adminAlert"}>You have no support tickets available</h3>;
        }
    }

    render() {
        return(
            <div className={"supportTicketsContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <CustomerService02Icon size={30} style={{color: '#000'}} />
                        <h3>Support Tickets</h3>
                    </div>
                </div>
                {/*<div className={"openTicketBox"}>*/}
                {/*    <OpenTicket/>*/}
                {/*</div>*/}

                <div className={"mySupportTicketsBox"}>
                    <div>
                        <h4 style={{color:'black'}}>My support tickets</h4>
                    </div>

                    <div>
                        { this.renderMySupportTickets() }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { mySupportTickets } = state.support;
    const { profile } = state.profile;

    return {
        mySupportTickets,
        profile,
    }
};

export default connect(mapStateToProps, { fetchMySupportTickets })(Support);