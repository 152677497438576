import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

//actions import
import {fetchAllSupportTicket, fetchSupportTicket } from "../../../actions/ReportActions";
import arraySort from "array-sort";

//components import


class SupportTickets extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

           supportTickets: this.props.supportTickets,
           profile: this.props.profile,
        }
    }

    componentWillMount() {
        //
       const { profile} = this.state;
         if(!(_.isEmpty(profile))){
             if('location' in profile){

                const locationID = profile.location.locationID;
                this.props.fetchAllSupportTicket({locationID});
                
             } else {
                //
                 this.props.fetchSupportTicket()
             }
           
       }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //
        if (nextProps.supportTickets !== this.props.supportTickets) {
            this.setState({supportTickets: nextProps.supportTickets})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }
    }

    renderTicketsReport() {
        //
        const {supportTickets} = this.state;
        // console.log({ supportTickets });

        //check if support tickets obj is not empty
        if (!(_.isEmpty(supportTickets))) {
            //
            const ticketsArray = _.map(supportTickets, ticket => {
                return ticket;
            });


            function groupBy(objectArray, property) {
                return objectArray.reduce(function (acc, obj) {
                    let key = obj[property];

                    if (!acc[key]) {
                        acc[key] = {
                            tickets: [],
                            name: obj.employeeName,
                            department: obj.employeeDepartment,
                        };
                    }
                    acc[key].tickets.push(obj);

                    return acc ;
                }, {});
            }

            const groupedTickets = groupBy(ticketsArray, 'userID');

            let ticketsLengthArray = [];

                _.map(groupedTickets, ticket => {

                    const indexes = ticket.tickets.length;
                    if (indexes !== 0) {
                        ticketsLengthArray.push({index: indexes, name: ticket.name, department: ticket.department});
                    }
                })

            const sortedTicketsList = arraySort(ticketsLengthArray, "index", { reverse: true });


            if (sortedTicketsList.length !== 0) {
                return (
                    <div>
                        {
                            sortedTicketsList.map(list => {
                                const index = sortedTicketsList.indexOf(list);
                                const position = index + 1;
                                return(
                                    <div key={list.ID} className={'ticketReportValues'}>
                                        <div><p style={{ color: "black"}}>{position}</p></div>
                                        <div><p style={{ color: "black"}}>{list.name}</p></div>
                                        <div><p style={{textTransform: 'capitalize', color: "black"}}>{list.department}</p></div>
                                        <div><p style={{ color: "black"}}>{list.index}</p></div>
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            } else {
                return null;

            }
        }
    }

    render() {
        return (
            <div className={'ticketReportContainer'}>
                <div><h4 style={{ color: "black"}}>Support Tickets Report</h4></div>
                <div className={'ticketReportHeaders'}>
                    <div><h5 style={{ color: "black"}}>#</h5></div>
                    <div><h5 style={{ color: "black"}}>Employee Name</h5></div>
                    <div><h5 style={{ color: "black"}}>Employee Department</h5></div>
                    <div><h5 style={{ color: "black"}}>Total Tickets</h5></div>
                </div>
                {this.renderTicketsReport()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  //
  const {supportTickets, } = state.reports;
  const { profile } = state.profile;

  return {
      supportTickets,
      profile,
  }

};

export default connect(mapStateToProps, {fetchAllSupportTicket, fetchSupportTicket })(SupportTickets);