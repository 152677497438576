import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";

//actions import

//components import
import InterviewsCard from "./cards/InterviewsCard";
import {Spinner} from "../../common/Spinner";

class InterviewsBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            interviews: this.props.interviews,
            myInterviews: this.props.myInterviews,
            interviewsLoading: this.props.interviewsLoading,
            selectedPost: this.props.selectedPost,
            profile: this.props.profile,
        }

        this.renderInterviews = this.renderInterviews.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                interviews: nextProps.interviews,
                myInterviews: nextProps.myInterviews,
                interviewsLoading: nextProps.interviewsLoading,
                selectedPost: nextProps.selectedPost,
                profile: nextProps.profile,
            })
        }
    }

    onPerformInterview() {
        const {navigate} = this.props;
        navigate('/perform-interview');
    }

    checkForInterview() {
        const {selectedCandidate, selectedPost, myInterviews} = this.state;
        if (selectedPost && !(_.isEmpty(selectedCandidate))) {
            let interviews = [];
            if (myInterviews.length !== 0) {
                myInterviews.forEach(interview => {
                    if (!interview.infoBox.general) {
                        interviews.push(interview);
                    }
                });

                const status = interviews.some(interview => interview.infoBox.postID === selectedPost.postID);
                if (status) {
                    return(
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                onClick={() => this.onPerformInterview()}
                                style={{ width: "30rem", margin: "1rem auto", backgroundColor: "#ffb400" }}
                            >
                                <span color={"#000"} style={{ fontFamily: "ramblaBold" }}>Perform {selectedPost.postTitle} Interview Rating</span>
                            </Button>
                        </div>
                    );
                }
            }
        }
    }

    renderInterviews() {
        const {interviews, interviewsLoading, selectedCandidate} = this.state;

        if (interviewsLoading) {
            return <Spinner/>
        } else {
            if (!(_.isEmpty(selectedCandidate))) {
                const externalInterviews = [];
                let generalInterview = {};

                interviews.forEach(interview => {
                    if (interview.infoBox.general) {
                        generalInterview = interview;
                    } else {
                        externalInterviews.push(interview);
                    }
                });

                return (
                    <div>
                        <InterviewsCard interview={generalInterview} general={true}/>
                        {
                            externalInterviews.map(item => {
                                return <InterviewsCard interview={item} general={false}/>
                            })
                        }
                    </div>
                );
            } else {
                return <h2 style={{ color: "#000", margin: "2rem 0"  }}>Select a candidate to view</h2>;
            }
        }
    }


    checkUser() {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                return (
                    <div className={"infoBox"}>
                        <div className={"assetsDetailsBox"}>
                            <div className={"interviewsHeaderBox"}>
                                <div><h5>I.A.S Rating</h5></div>
                            </div>
                            <div>
                                {this.checkForInterview()}
                            </div>
                            <div>
                                {this.renderInterviews()}
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <h2 style={{ color: "#fff" }}>Sign in to view</h2>;
        }
    }

    render() {
        return (
            <div className={'employeeToolContainer'}>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { selectedCandidate, selectedPost, interviews, myInterviews, interviewsLoading } = state.posts;
    const { profile } = state.profile;

    return{
        selectedCandidate,
        interviews,
        myInterviews,
        interviewsLoading,
        profile,
        selectedPost
    }
}

// Wrapper functional component to use hooks
function Interviews(props) {
    const navigate = useNavigate();

    return <InterviewsBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, {})(Interviews);