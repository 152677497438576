import React from 'react';
import { Bar } from '@ant-design/charts';

function ExperienceChart({candidateExperience}) {

    const data = candidateExperience.map(({number, years}) => ({
        years: years,
        number: number
    }))
    
    const config = {
        data,
        xField: 'number',
        yField: 'years',
        title: {
            text: 'Years of Experience Distribution',
        },
    };
    return (
        <div>
                <Bar {...config} />
                <p style={{color: '#000'}}>Years of Experience Distribution</p>
        </div>
    );
}

export default ExperienceChart;
