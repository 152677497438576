import React from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";


class SelectInterviewRating extends React.Component {
    render() {
        const { Option } = Select;
        return (
            <div className={"inputTypeSelect"}>
                <Select

                    style={{ width: 120, fontFamily: "ramblaBold", color: "#0793d9" }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                </Select>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { departments } = state.posts;

    return {
        departments
    };
}

export default connect(mapStateToProps)(SelectInterviewRating);
