import React from "react";
import { connect } from "react-redux";
// import _ from "lodash";
import {useNavigate} from "react-router-dom";

//actions import
import {fetchSelectedSeeker
} from "../../../actions/PostsActions";

//components import
import ShortlistCard from "../cards/ShortlistCard";


class ShortlistCandidatesBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shortlist: this.props.shortlist,
            profile: this.props.profile,
           
        };

        this.renderShortlist = this.renderShortlist.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                shortlist: nextProps.shortlist,
                profile: nextProps.profile,
                
            });
        }
    }

    renderShortlist() {
        //extract state
        const { shortlist, profile } = this.state;

        //check if employee list is not empty
        if (shortlist.length !== 0) {

            // const sortedEmployeeList = arraySort(filteredEmployeeList, "name", { reverse: true });

            return (
                <div>
                    <ShortlistCard shortlist={shortlist} profile={profile}/>
                </div>
            );
        } else {
            return <p className={"adminAlert"}>You currently have no shortlisted candidates</p>;
        }
    };

    render() {
        //extract state
        return (
            <div>
                {/* <div className={"manageLeaveRequestsHeaderBox"}>
                    <div><h5>Rank</h5></div>
                    <div><h5>Name</h5></div>
                    <div><h5>Gender</h5></div>
                    <div><h5>Age</h5></div>
                    <div><h5>Location</h5></div>
                    <div><h5>Experience</h5></div>
                    <div><h5>Industries</h5></div>
                    <div><h5>Profile</h5></div>
                    <div><h5>Remove</h5></div>
                </div> */}

                <div>
                    { this.renderShortlist() }
                </div>
            </div>
        );
    }
}

const mapStateTopProps = state => {
    const { shortlist } = state.posts;
    const { profile } = state.profile;

    return {
        shortlist,
        profile
    }
};

// Wrapper functional component to use hooks
function ShortlistCandidates(props) {
    const navigate = useNavigate();

    return <ShortlistCandidatesBase {...props} navigate={navigate} />;
}

export default connect(mapStateTopProps, {fetchSelectedSeeker})(ShortlistCandidates);
