import React from "react";
import {connect} from "react-redux";
import { Select } from 'antd';
import countryRegionData from "country-region-data/dist/data-umd";

//action imports
import { credentialsChanged } from "../../actions/AuthenticationActions";
import { postsFieldChanged } from "../../actions/PostsActions";

class CountryRegionPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: "",
            region: "",
        };

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props !== nextProps) {
            this.setState({
                country: nextProps.country,
                region: nextProps.region,
            });
        }
    }

    handleCountryChange = (value) => {
        const selectedCountry = JSON.parse(value);
        this.props.credentialsChanged({prop: 'country', value: selectedCountry });
        this.props.credentialsChanged({prop: 'region', value: selectedCountry.regions && selectedCountry.regions.length > 0 ? selectedCountry.regions[0] : "" });
        this.props.postsFieldChanged({prop: 'country', value: selectedCountry });
        this.props.postsFieldChanged({prop: 'region', value: selectedCountry.regions && selectedCountry.regions.length > 0 ? selectedCountry.regions[0] : "" });
    };

    handleRegionChange = (value) => {
        const selectedRegion = JSON.parse(value);

        this.props.credentialsChanged({prop: 'region', value: selectedRegion});
        this.props.postsFieldChanged({prop: 'region', value: selectedRegion});
    };

    render() {
        const { Option } = Select;
        const { country, region } = this.state;

        return (
            <div style={{ marginTop: "1.5rem", textAlign:"center"}}>
                <label style={{ fontSize: '0.9rem', marginLeft: '0.5rem'}}>Select Country:</label>
                <Select
                    style={{ width: "100%", marginBottom: '0.5rem' }}
                    value={country ? JSON.stringify(country) : ""}
                    onChange={this.handleCountryChange}
                    placeholder="-- Select Country --"
                >
                    {countryRegionData.map((country) => (
                        <Option key={country.countryName} value={JSON.stringify(country)}>
                            {country.countryName} ({country.countryCode})
                        </Option>
                    ))}
                </Select>

                {country && country.regions && (
                    <div>
                        <label style={{ fontSize: '0.9rem', marginLeft: '0.5rem'}}>Select Region:</label>
                        <Select
                            style={{ width: 400, marginBottom: '0.5rem' }}
                            value={region ? JSON.stringify(region) : ""}
                            onChange={this.handleRegionChange}
                            placeholder="-- Select Region --"
                        >
                            {country.regions.map((region) => (
                                <Option key={region.name} value={JSON.stringify(region)}>
                                    {region.name} ({region.shortCode})
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { country, region } = state.authentication;

    return {
        country,
        region
    }
}
export default connect(mapStateToProps, { credentialsChanged, postsFieldChanged })(CountryRegionPicker);