import React from 'react';
import { connect } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import {Form, Input, Button, Checkbox, Select, Tooltip, message} from 'antd';
import {GoogleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom"; // Import Ant Design styles
import {isMobile} from "react-device-detect";

// //-> action imports
import { userSignUp } from '../../actions/AuthenticationActions';

//file import
import Logo from "../../assets/logos/taglineLogo.png";
import AppleBadge from "../../assets/img/app-store-badge.svg";
import GoogleBadge from "../../assets/img/google-play-badge.svg";

import './SignUp.css';
import firebase from "firebase";

const { Option } = Select;

class SignUpBase extends React.Component {
    formRef = React.createRef(); // Initialize form reference

    constructor(props) {
        super(props);

        this.state = {
            confirmDirty: false,
            showPasswordRules: false,
            loading: this.props.loading,

            //polyfill data
            firstName: '',
            lastName: '',
            phone: '',
            companyName: '',
            companyPhone: '',
            companyEmail: '',
            logo: '',
            country: null,
            region: null,
            address: '',
            postalCode: '',

            fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                fetchedTermsAndConditions: nextProps.fetchedTermsAndConditions,
                loading: nextProps.loading
            });
        }
    };

    handleSubmit = (values) => {
        //-> handle login
        const { firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, country, region, address, postalCode } = this.state;
        const { email, password } = values;
        const { navigate } = this.props;

        //-> checking if fields are not empty
        if (email && password) {
            const companyLocation = {
                country: '',
                countryCode: '',
                region: '',
                regionCode: '',
                streetAddress: '',
                postalCode
            }

            //call login action from actions
            this.props.userSignUp({ email, password, firstName, lastName, phone, companyName, companyLocation, companyPhone, companyEmail, logo, navigate, google: false, login: false, loginUser: null });
        } else {
            message.warning('Fill all required fields').then(() => null);
        }
    }

    handleGoogleSignUp = async () => {
        try {
            const { firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, country, region, address, postalCode } = this.state;
            const { navigate } = this.props;

            const companyLocation = {
                country: '',
                countryCode: '',
                region: '',
                regionCode: '',
                streetAddress: '',
                postalCode
            }

            //call login action from actions
            this.props.userSignUp({ email: '', password: '', firstName, lastName, phone, companyName, companyLocation, companyPhone, companyEmail, logo, navigate, google: true, login: false });
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    validatePassword = (_, value) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
        if (!value) {
            return Promise.reject(new Error('Please input your password!'));
        } else if (!passwordRegex.test(value)) {
            return Promise.reject(new Error('Password must have at least 6 characters, one uppercase letter, and one special character.'));
        }
        return Promise.resolve();
    };

    handlePasswordFocus = () => {
        this.setState({ showPasswordRules: true });
    };

    handlePasswordBlur = () => {
        this.setState({ showPasswordRules: false });
    };

    handleConfirmBlur = () => {
        this.setState({ confirmDirty: this.state.confirmDirty });
    };

    compareToFirstPassword = (rule, value) => {
        // Access the current form using the ref
        const form = this.formRef.current;
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };

    validateToNextPassword = (rule, value) => {
        // Access the current form using the ref
        const form = this.formRef.current;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm']);
        }
        return Promise.resolve();
    };

    renderTermsAndAgreement(){
        const { fetchedTermsAndConditions} = this.state;

        if(!(_.isEmpty(fetchedTermsAndConditions))){
            const termsAndConditionsArray = _.map(fetchedTermsAndConditions, doc => doc);
            let privacy = "";
            let terms = "";


            termsAndConditionsArray.forEach(doc => {
                if (doc) {
                    switch (doc.docFileID) {
                        case "nafasiPrivacyPolicy":
                            privacy = doc.docFile;
                            break;
                        case "termsAndCondition":
                            terms = doc.docFile;
                            break;
                        default:
                            break;
                    }
                }
            });

            return(
                <Checkbox className={"regular"}>
                    I have read the <span style={{ color: "#0793d9"}} onClick={() => window.open(`${terms}`,'name','width=700,height=600')}>terms and conditions</span> and <span style={{ color: "#0793d9"}} onClick={() => window.open(`${privacy}`,'name','width=700,height=600')}>privacy policy</span>
                </Checkbox>
            )
        } else {
            return <h4 className={'adminAlert'}>terms and conditions loading....</h4>;
        }
    };

    render() {
        const { showPasswordRules } = this.state;
        const { navigate } = this.props;
        if(isMobile) {
            return (
                <div className={"signup-container"}  >
                    <h3 className={"bold black"} style={{textAlign: "left",  color: "#fff"}}>For Organizations</h3>
                    <p style={{fontFamily: "ramblaBold", color: "#fff"}}>Complete registration from a computer,<br/>
                        <span
                            style={{color: "#0793d9" }}
                            onClick={() => navigate('/pricing')}
                        > go back</span>
                    </p>
                    <h4 className={"bold black"} style={{textAlign: "left",  color: "#fff", fontFamily: "ramblaBold"}}>For Job Applicants</h4>
                    <div style={{display:'flex', alignItems:'center'}}>
                       
                       <a className="me-lg-3 mb-lg-0" href={"https://play.google.com/store/apps/details?id=com.nafasi.io"} target={"_blank"}>
                           <img 
                                src={GoogleBadge}
                                alt="..." height={140} width={160}/>
                       </a>

                       <a href={"https://apps.apple.com/tz/app/nafasi/id6450264936"} target={"_blank"}>
                           <img 
                                src={AppleBadge}
                                alt="..." height={140} width={160}/>
                                
                       </a>
                   </div>

                </div>
            );
        } else {
            return (
                <div className={"signup-container"} style={{ marginTop:"100px",  marginBottom:'80px'}}>
                    <Form
                        name="register"
                        onFinish={this.handleSubmit}
                        className="signup-form"
                        scrollToFirstError
                        ref={this.formRef} // Use this formRef
                    >
                        <img src={Logo} alt={"nafasi logo"}/>
                        <p className={"bold black"} style={{textAlign: "left"}}>For Organizations</p>

                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" className={"regular"}/>
                        </Form.Item>

                        <Form.Item
                            name={"password"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {
                                    validator: this.validatePassword,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder="Password" className={"regular"}/>
                        </Form.Item>

                        {showPasswordRules && (
                            <div style={{marginBottom: '16px', color: 'gray'}}>
                                <Tooltip
                                    title="Password must have at least 6 characters, one uppercase letter, and one special character."
                                    className={"regular"}>
                                    <InfoCircleOutlined style={{marginRight: '8px'}}/>
                                    Password Requirements
                                </Tooltip>
                            </div>
                        )}

                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                {
                                    validator: this.compareToFirstPassword,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder="Confirm Password" onBlur={this.handleConfirmBlur}
                                            className={"regular"}/>
                        </Form.Item>

                        <Form.Item
                            className={"regular"}
                            name="agreement"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions')),
                                },
                            ]}
                        >
                            {this.renderTermsAndAgreement()}
                        </Form.Item>

                        <Form.Item>
                            <Button type={"primary"} htmlType="submit" className={"regular"}
                                    loading={this.state.loading}>
                                Sign Up
                            </Button>
                            <Button type="default" icon={<GoogleOutlined/>} style={{marginLeft: 10}}
                                    className={"regular"} onClick={this.handleGoogleSignUp}>
                                Sign up with Google
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Link to="/login">
                                <p type="link" style={{textAlign: 'left'}} className={"bold black"}>Already Registered ?
                                    Sign in</p>
                            </Link>
                        </Form.Item>
                        <div>
                            <div style={{ borderTop: "1px solid silver", margin: "0 0 1rem 0" }}/>
                        <span style={{ textAlign: "left", color:"black", fontSize:"14px",  fontFamily: "ramblaBold" }}>For Job Applicants</span>
                                   <div style={{display:'flex', alignItems:'center'}}>
                       
                                        <a className="me-lg-3 mb-lg-0" href={"https://play.google.com/store/apps/details?id=com.nafasi.io"} target={"_blank"}>
                                            <img 
                                                 src={GoogleBadge}
                                                 alt="..." height={140} width={160}/>
                                        </a>

                                        <a href={"https://apps.apple.com/tz/app/nafasi/id6450264936"} target={"_blank"}>
                                            <img 
                                                 src={AppleBadge}
                                                 alt="..." height={140} width={160}/>
                                                 
                                        </a>
                                    </div>

                    </div>
                    </Form>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { loading } = state.authentication;
    const {fetchedTermsAndConditions} = state.settings;
    return {
        loading,
        fetchedTermsAndConditions
    }
};

// // Wrapper functional component to use hooks
function SignUp(props) {
    const navigate = useNavigate();

    return <SignUpBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { userSignUp })(SignUp);


// import React, { createRef } from 'react';
// import { connect } from 'react-redux';
// import {message} from "antd";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import 'antd/dist/antd.css';
// import {NavLink, useNavigate} from 'react-router-dom';
// import _ from "lodash";
//
// //-> action imports
// import { credentialsChanged, userSignUp } from '../../actions/AuthenticationActions';
//
// //-> component imports
// import {Input} from '../common/Input';
// import {Button} from '../common/Button';
//
// //-> file import
// import logo from '../../assets/logos/logo.png';
// import typography from "../../assets/images/typographyLogo.png";
// import backgroundImage from "../../assets/images/transparent/blue-flower.png";
// import { BarLoader } from 'react-spinners';
// import CountryRegionPicker from "../common/CountryRegionPicker";
// import {isMobile} from "react-device-detect";
//
// class SignUpBase extends React.Component {
//
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             firstName: this.props.firstName,
//             lastName: this.props.lastName,
//             email: this.props.email,
//             password: this.props.password,
//             phone: this.props.phone,
//             companyName: this.props.companyName,
//             companyPhone: this.props.companyPhone,
//             companyEmail: this.props.companyEmail,
//             logo: this.props.logo,
//             country: this.props.country,
//             region: this.props.region,
//             address: this.props.address,
//             postalCode: this.props.postalCode,
//
//             signUpError: this.props.signUpError,
//             loading: this.props.loading,
//             fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
//             isPasswordVisible: false,
//             isActive: true
//         };
//
//         this.onSignUpPress = this.onSignUpPress.bind(this);
//         this.renderSignUpError = this.renderSignUpError.bind(this);
//         this.handleCheck = this.handleCheck.bind(this);
//         this.handleOrganizationLogo = this.handleOrganizationLogo.bind(this);
//         this.fileInputRef = createRef(); // Step 1: Create a ref
//     };
//
//     componentWillReceiveProps(nextProps, nextContext) {
//         if (nextProps !== this.props) {
//             this.setState({
//                 firstName: nextProps.firstName,
//                 lastName: nextProps.lastName,
//                 email: nextProps.email,
//                 password: nextProps.password,
//                 phone: nextProps.phone,
//                 companyName: nextProps.companyName,
//                 companyPhone: nextProps.companyPhone,
//                 companyEmail: nextProps.companyEmail,
//                 logo: nextProps.logo,
//                 country: nextProps.country,
//                 region: nextProps.region,
//                 address: nextProps.address,
//                 postalCode: nextProps.postalCode,
//                 signUpError: nextProps.signUpError,
//                 fetchedTermsAndConditions: nextProps.fetchedTermsAndConditions,
//                 loading: nextProps.loading
//             });
//         }
//
//
//         if (nextProps.loading !== this.props.loading) {
//             this.setState({  });
//         }
//     };
//
//     onSignUpPress() {
//         //-> handle login
//         const { email, password, firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, country, region, address, postalCode } = this.state;
//         const { navigate } = this.props;
//
//         console.log(logo);
//         //-> checking if fields are not empty
//         if (email && password && firstName && lastName && phone && companyName && !(_.isEmpty(country)) && !(_.isEmpty(region)) && address && companyPhone && companyEmail) {
//             const companyLocation = {
//                 country: country.countryName,
//                 countryCode: country.countryShortCode,
//                 region: region.name,
//                 regionCode: region.shortCode,
//                 streetAddress: address,
//                 postalCode
//             }
//
//             //call login action from actions
//             this.props.userSignUp({ email, password, firstName, lastName, phone, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
//         } else {
//             message.warning('Fill all required fields').then(() => null);
//         }
//     }
//
//     renderSignUpError() {
//         //extract state
//         const { signUpError } = this.state;
//
//         if (signUpError) {
//             return (
//                 <div>
//                     <p className={"adminAlertError"}><FontAwesomeIcon icon="exclamation-circle" size="1x" color="#EA3224" /> {signUpError}</p>
//                 </div>
//             );
//         }
//     }
//
//     keyPressed = (e) => {
//         //it triggers by pressing the enter key
//         if (e.key === 'Enter') {
//             this.onSignUpPress();
//         }
//     };
//
//     renderSignUpButton() {
//         const {loading} = this.state;
//
//         if(loading){
//             return (
//                 <div>
//                     <BarLoader
//                         size={20}
//                     />
//                 </div>
//             );
//         }else{
//             return (
//                 <div style={{marginTop: '1rem'}}>
//                     <Button
//                         text={"Sign up"}
//                         onPress={() => this.onSignUpPress()}
//                         disabled={this.state.isActive}
//                     />
//                 </div>
//             );
//         }
//     }
//
//     handleOrganizationLogo(event) {
//         //files is an array, so pick first element
//         const file = event.target.files[0];
//         const maxSize = 2 * 1024 * 1024; // 2MB maximum size (in bytes)
//
//         if (file && file.size > maxSize) {
//             message.info('File size exceeds the maximum limit of 2MB.').then(() => null);
//             // Optionally, you can reset the file input value
//             event.target.value = null;
//         } else {
//             // File size is within the limit, you can proceed with the upload
//             // ...
//             //this.props.credentialsChanged({ prop: 'logo', value: file });
//             this.setState({ logo: file });
//         }
//     }
//
//     handleCheck(event){
//         this.setState({ isActive: !this.state.isActive });
//     }
//
//     renderTermsAndAgreement(){
//         const { fetchedTermsAndConditions} = this.state;
//
//         if(!(_.isEmpty(fetchedTermsAndConditions))){
//             const termsAndConditionsArray = _.map(fetchedTermsAndConditions, doc => doc);
//             let privacy = "";
//             let terms = "";
//
//
//             termsAndConditionsArray.forEach(doc => {
//                 if (doc) {
//                     switch (doc.docFileID) {
//                         case "nafasiPrivacyPolicy":
//                             privacy = doc.docFile;
//                             break;
//                         case "termsAndCondition":
//                             terms = doc.docFile;
//                             break;
//                         default:
//                             break;
//                     }
//                 }
//             });
//
//             return(
//                 <div>
//                     <p style={{ fontFamily: "myriadText", fontSize: "0.8rem", marginTop: "2.1rem", color: "#000"}}>
//                         I agree to the <span style={{ color: "#0793d9"}} onClick={() => window.open(`${terms}`,'name','width=700,height=600')}> Terms & Conditions</span>, and
//                         <span style={{ color: "#0793d9"}} onClick={() => window.open(`${privacy}`,'name','width=700,height=600')}> Privacy Policy</span>
//                     </p>
//                 </div>
//             )
//         } else {
//             return <h4 className={'adminAlert'}>terms and conditions loading....</h4>;
//         }
//     };
//
//     handleButtonClick = () => {
//         this.fileInputRef.current.click(); // Simulate file input click
//     }
//
//     render(){
//         const { navigate } = this.props;
//         if(isMobile) {
//             return (
//                 <div style={{ display: "grid", justifyContent: "center", alignItems: "center", height: "75vh" }}>
//                     <p style={{fontFamily: "ramblaBold", color: "#fff"}}>Complete registration from a computer,<br/>
//                         <span
//                             style={{color: "#0793d9" }}
//                             onClick={() => navigate('/pricing')}
//                         > go back</span>
//                     </p>
//                 </div>
//             );
//         } else {
//             return (
//                 <div className={"loginLogoutContainer"}>
//                     <div className={"topLogoContainer"}>
//                         <img src={typography} alt="nafasi typography Logo" style={{margin: "2rem"}}/>
//                     </div>
//                     <div className={"loginBackgroundImageContainer"}>
//                         <img src={backgroundImage} alt="background artwork" style={{width: "100%", zIndex: 1}}/>
//                     </div>
//                     <div className={"loginLogoutBox"}>
//                         <div className={"logoArea"}>
//                             <img src={logo} alt="Nafasi Logo"/>
//                             <p style={{fontFamily: "ramblaBold", color: "#000"}}>For organizations</p>
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Admin First Name'}
//                                 onChange={e => this.props.credentialsChanged({
//                                     prop: 'firstName',
//                                     value: e.target.value
//                                 })}
//                                 value={this.state.firstName}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Admin Last Name'}
//                                 onChange={e => this.props.credentialsChanged({prop: 'lastName', value: e.target.value})}
//                                 value={this.state.lastName}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Admin Phone'}
//                                 onChange={e => this.props.credentialsChanged({prop: 'phone', value: e.target.value})}
//                                 value={this.state.phone}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Email'}
//                                 onChange={e => this.props.credentialsChanged({prop: 'email', value: e.target.value})}
//                                 value={this.state.email}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div className={"passwordBox"}>
//                             {this.state.isPasswordVisible ?
//                                 <Input
//                                     type='text'
//                                     onChange={(e) => this.setState({password: e.target.value})}
//                                     value={this.state.password}
//                                     onKeyDown={this.keyPressed}
//                                 />
//                                 :
//                                 <Input
//                                     type={'password'}
//                                     placeholder={'Password'}
//                                     onChange={e => this.props.credentialsChanged({
//                                         prop: 'password',
//                                         value: e.target.value
//                                     })}
//                                     value={this.state.password}
//                                     onKeyDown={this.keyPressed}
//                                 />
//                             }
//                             <div
//                                 className={"showPasswordBox"}
//                                 onClick={() => this.setState({isPasswordVisible: !this.state.isPasswordVisible})}
//                             >
//                                 {this.state.isPasswordVisible ?
//                                     <FontAwesomeIcon icon={"eye-slash"} size={"1x"} color={"#3295ff"}/> :
//                                     <FontAwesomeIcon icon={"eye"} size={"1x"} color={"#3295ff"}/>}
//                             </div>
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Company Name'}
//                                 onChange={e => this.props.credentialsChanged({
//                                     prop: 'companyName',
//                                     value: e.target.value
//                                 })}
//                                 value={this.state.companyName}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Company Phone'}
//                                 onChange={e => this.props.credentialsChanged({
//                                     prop: 'companyPhone',
//                                     value: e.target.value
//                                 })}
//                                 value={this.state.companyPhone}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Company Email'}
//                                 onChange={e => this.props.credentialsChanged({
//                                     prop: 'companyEmail',
//                                     value: e.target.value
//                                 })}
//                                 value={this.state.companyEmail}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div>
//                             <CountryRegionPicker/>
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Company Address'}
//                                 onChange={e => this.props.credentialsChanged({prop: 'address', value: e.target.value})}
//                                 value={this.state.address}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div>
//                             <Input
//                                 type={'text'}
//                                 placeholder={'Postal Code'}
//                                 onChange={e => this.props.credentialsChanged({
//                                     prop: 'postalCode',
//                                     value: e.target.value
//                                 })}
//                                 value={this.state.postalCode}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         </div>
//
//                         <div style={{marginTop: "1rem"}}>
//                             <label style={{fontSize: '0.9rem', marginLeft: '0.5rem'}}>Organization Logo</label>
//                             <div>
//                                 <input
//                                     type="file"
//                                     onChange={this.handleOrganizationLogo}
//                                     accept="image/png"
//                                     style={{display: 'none'}}
//                                     id="organization-logo"
//                                     ref={this.fileInputRef} // Step 2: Attach the ref to the input
//                                 />
//                                 <label htmlFor="organization-logo">
//                                     <div style={{width: "27.8rem"}}>
//                                         <Button
//                                             text={"Upload Organization Logo"}
//                                             onPress={this.handleButtonClick} //
//                                         />
//                                     </div>
//                                 </label>
//                             </div>
//                         </div>
//
//                         <div className={'joinTermsAgree'}>
//                             <div>
//                                 <Input
//                                     type={'checkbox'}
//                                     onChange={(event) => this.handleCheck(event)}
//                                     checked={false}
//                                 />
//                             </div>
//                             <div>
//                                 {this.renderTermsAndAgreement()}
//                             </div>
//                         </div>
//
//                         {this.renderSignUpError()}
//
//                         {this.renderSignUpButton()}
//
//                         <h5 style={{textAlign: 'center'}}><NavLink to={"/login"}>Already Registered ? Sign in</NavLink>
//                         </h5>
//                     </div>
//                 </div>
//             )
//         }
//     }
// }
//
// const mapStateToProps = (state) => {
//     const {
//         firstName, lastName, email, password, phone, companyName, companyPhone, companyEmail,
//         logo, country, region, address, postalCode, signUpError, loading } = state.authentication;
//     const {fetchedTermsAndConditions} = state.settings;
//     return {
//         firstName,
//         lastName,
//         email,
//         password,
//         phone,
//         companyName,
//         companyPhone,
//         companyEmail,
//         logo,
//         country,
//         region,
//         address,
//         postalCode,
//         fetchedTermsAndConditions,
//
//         signUpError,
//         loading,
//
//     }
// };
//
// // Wrapper functional component to use hooks
// function SignUp(props) {
//     const navigate = useNavigate();
//
//     return <SignUpBase {...props} navigate={navigate} />;
// }
//
// export default connect(mapStateToProps, { credentialsChanged, userSignUp })(SignUp);