import React from 'react';
import { Input, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchLicenses } from '../../../actions/SearchActions';

class LicensesFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            licensesSearchQuery: this.props.licensesSearchQuery,
            licenses: this.props.licenses
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                licensesSearchQuery: nextProps.licensesSearchQuery,
                licenses: nextProps.licenses
            });
        }
    }

    handleSearch = (value) => {
        this.props.searchFieldChanged({ prop: "licensesSearchQuery", value });
        this.props.fetchLicenses({ searchQuery: value });
    };

    handleSelect = (value, option) => {
        const { objectID } = option.data;
        // Proceed as usual when an option is selected
        this.props.searchFieldChanged({ prop: "licensesSearchQuery", value });
        this.props.searchFieldChanged({ prop: "license", value: objectID });
    };

    handleClear = () => {
        // Handle the case when the clear icon is clicked
        this.props.searchFieldChanged({ prop: "licensesSearchQuery", value: "" });
        this.props.searchFieldChanged({ prop: "license", value: null });
	}

    render() {
        const { licensesSearchQuery, licenses } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Licenses</p>
                <AutoComplete
                    value={licensesSearchQuery}
                    onChange={this.handleSearch}
                    onSelect={this.handleSelect}
                    onClear={this.handleClear}
                    allowClear
                    options={licenses.map((result) => ({
                        value: result.title,
                        label: result.title,
                        data: {
                            title: result.title,
                            objectID: result.objectID,
                        },
                    }))} // Convert search results to options format
                    style={{ width: "100%", borderColor: '#000' }}
                >
                    <Input.Search placeholder="Search Licenses" />
                </AutoComplete>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { licensesSearchQuery, licenses } = state.search;
    return {
        licensesSearchQuery, licenses
    };
};

const mapDispatchToProps = {
    searchFieldChanged, fetchLicenses,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensesFilter);
