import React, { Component } from 'react'
import { Collapse , Table} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, NavLink} from 'react-router-dom';
import {isMobile} from "react-device-detect";
import { connect } from 'react-redux';
import {Space, Alert} from 'antd';
import _ from 'lodash';
import { HandPointingDown01Icon, LayoutLeftIcon, SkewIcon, Tick01Icon, UnfoldLessIcon, WorkflowCircle01Icon } from 'hugeicons-react'



//component imports
import RegistrationModal from './cards/RegistrationModal';
import LoginModal from './cards/LoginModal';
import "./Developers.css"


//file imports
import Logo from "../../assets/logos/logo.png";


 class Developers extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataFound:this.props.dataFound,
            fetchedData:this.props.fetchedData
            
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                dataFound: nextProps.dataFound,
                fetchedData:nextProps.fetchedData

            });
        }
    }

    renderLogo() {
        if(isMobile){
            return  <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"}/></NavLink>;
        }
    }

    renderUserInfo(){
    const {dataFound, fetchedData} = this.state
    const apiKey = fetchedData.apiKey
    if (!_.isEmpty(this.state.fetchedData)) {

        return (
            <Space direction="vertical" style={{ width: '75%' }}>
                      <Alert message="YOUR API KEY"  type="success"        
                        description={apiKey}
                       />
            </Space>
        )    
    }


 }

 getSampleResponse() {
    return `jobs:[
 {
    "postID": <span class="api-number">163827</span>,
    "jobType": <span class="api-string">"Internship"</span>,
    "postTitle": <span class="api-string">"Cybersecurity Consultant"</span>,
    "companyName": <span class="api-string">"State Government"</span>,
    "minimumExperience:<span class="api-string">"1"</span>,
    "status": <span class="api-string">"open"</span>,
     ...
  }
]`;
};

getSampleScript () {
    return `const axios = require('axios');

const options = {
  method: <span class="api-string">'GET'</span>,
  url: <span class="api-url">'https://nafasi.io/api/jobs'</span>,
  headers: {
    'Content-Type': <span class="api-string">'application/json'</span>,
    'x-api-key': <span class="api-string">'YOUR_API_KEY'</span>
  }
};

axios.request(options)
  .then(response => {
    <span class="api-url">console.log(response.data);</span> 
  })
  .catch(error => {
    console.error(<span class="api-keyword">error</span>) 
  });`;
  };

getSamplePHPScript() {
    return `&lt;?php
  $curl = curl_init();
  curl_setopt_array($curl, array(
    CURLOPT_URL => <span class="api-url">'https://nafasi.io/api/jobs'</span>,
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => <span class="api-string">'GET'</span>,
    CURLOPT_HTTPHEADER => array(
      'Content-Type: ' . <span class="api-string">'application/json'</span>,
      'x-api-key ' . <span class="api-string">'YOUR_API_KEY'</span>
    ),
  ));
  
  $response = curl_exec($curl);
  
  curl_close($curl);
  echo $response;
  
  ?&gt;`;
  }

   getSampleJavaScriptScript() {
    return `const url = <span class="api-url">'https://nafasi.io/api/jobs'</span>;
  
  const options = {
    method: <span class="api-string">'GET'</span>,
    headers: {
      'Content-Type': <span class="api-string">'application/json'</span>,
      'x-api-key': <span class="api-string">'YOUR_API_KEY'</span>
    }
  };
  
  fetch(url, options)
    .then(response => response.json())
    .then(data => {
      <span class="api-url">console.log(data);</span>
    })
    .catch(error => {
      console.error(<span class="api-keyword">error</span>);
    });`;
  }
  
  
  

  render(){
        const { Panel } = Collapse;
        const {dataFound, fetchedData} = this.state
        console.log('fetched data', fetchedData)

        return (
            <div className={'developerContainer'}>

                <div className={'landingPageLogo'}>
                    {this.renderLogo()}
                </div>

                <div className={'faqIntroBox'} style={{ alignItems: "center", textAlign: "center" }}>

                    {_.isEmpty(fetchedData) && (
                        <div>
                            <p style={{color:'gold', fontSize:'25px', fontFamily:"ramblaRegular"}}>Nafasi Jobs API</p>

                            <RegistrationModal />
                            <LoginModal />
                        </div>
                    )}

                    {this.renderUserInfo()}
                </div>

                <div className={'faqQnBox'}>
                    <h5 style={{color:'whitesmoke', margin: "1rem"}}>Job Posts</h5>
                    <Collapse
                        accordion
                        bordered={false}
                        defaultActiveKey={['2']}
                        expandIcon={({ isActive }) =>
                            isActive ? <FontAwesomeIcon icon={'minus'} size={"2x"} color={'#3294ff'} /> : <FontAwesomeIcon icon={'plus'} size={"2x"} color={'#3294ff'} />
                        }
                        className="collapseItem"
                    >
                        <Panel header="Fetch Job Posts" key="2" className="faqCollapsePanel2">
                            <div style={{backgroundColor:"#58d156", margin: "0 0 0.4rem 0"}}>
                                <p style={{ padding: "0.3rem 0 0.1rem 0", fontFamily: "ramblaBold"}}>GET</p>
                            </div>
                            <div style={{backgroundColor:"lightgray"}}>
                                <p style={{ padding: "0.3rem 0 0.1rem 0", fontFamily: "ramblaBold"}}>https://nafasi.io/api/jobs</p>
                            </div>
                            <div>
                                <h6>Headers</h6>
                            </div>
                            <div className="faqAnswerBox">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    className="customTable"
                                />
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <div className="api-container">
                  <section className="api-section">
                    <h2 className="api-heading">Sample Script</h2>
                    <p className="api-paragraph">
                      Below is a sample script for making a GET request to our API endpoint:
                    </p>
                    {/* <pre className="api-code-block">
                      <code className="api-code-text" dangerouslySetInnerHTML={{ __html: this.getSampleScript() }} />
                    </pre> */}

                     <div className="page">
                        <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1" >
                            <input type="radio" name="pcss3t" checked  id="tab1"class="tab-content-first"/>
                            <label for="tab1">Node JS </label>

                            <input type="radio" name="pcss3t" id="tab2" class="tab-content-2"/>
                            <label for="tab2">PHP </label>
                            <input type="radio" name="pcss3t" id="tab3" class="tab-content-3"/>
                            <label for="tab3">Javascript </label>



                            <ul>
                                <li class="tab-content tab-content-first typography"  >
                                    <div className='recruiterContainerr' >
                                    <pre className="api-code-block">
                                        <code className="api-code-text" dangerouslySetInnerHTML={{ __html: this.getSampleScript() }} />
                                    </pre>

                                    </div>



                                </li>

                                <li class="tab-content tab-content-2 typography">

                                    <div className='recruiterContainerr'>

                                    <pre className="api-code-block">
                                       <code className="api-code-text" dangerouslySetInnerHTML={{ __html: this.getSamplePHPScript() }} />
                                    </pre>

                                    </div>


                                </li>
                                <li class="tab-content tab-content-3 typography">

                                    <div className='recruiterContainerr'>

                                    <pre className="api-code-block">
                                       <code className="api-code-text" dangerouslySetInnerHTML={{ __html: this.getSampleJavaScriptScript() }} />
                                    </pre>

                                    </div>


                                </li>


                            </ul>
                        </div>

                    </div>
                  </section>

                  <section className="api-section">
                    <h2 className="api-heading">Sample Response</h2>
                    <p className="api-paragraph">
                      Here is a sample response you can expect from the API:
                    </p>
                     <pre className="api-code-block">
                      <code className="api-code-text" dangerouslySetInnerHTML={{ __html: this.getSampleResponse() }} />
                    </pre>



                  </section>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {

    const {  dataFound,
        fetchedData} = state.landing;
  

    return {
        dataFound,
        fetchedData
    }
};

export default connect(mapStateToProps, { })(Developers);



const columns = [
    {
        title: 'Field',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
];

const data = [
    {
        key: '1',
        field: 'x-api-key',
        type: 'String',
        description: 'Token for authentication',
    },
    {
        key: '2',
        field: 'Content-Type',
        type: 'String',
        description: 'Type of the content being sent, application/json',
    },
  
];

