// CustomHeader.js
import React from 'react';

const CustomHeaderCard = ({ children }) => (
    <th style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {children}
    </th>
);

export default CustomHeaderCard;
