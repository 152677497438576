import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

//actions import

//components import
import AwardsCard from "./cards/AwardsCard";
import {Spinner} from "../../common/Spinner";

class Awards extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            awards: this.props.awards,
            awardsLoading: this.props.awardsLoading,
            profile: this.props.profile,
        }

        this.renderAwards = this.renderAwards.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                awards: nextProps.awards,
                awardsLoading: nextProps.awardsLoading,
                profile: nextProps.profile,
            })
        }
    }

    renderAwards() {
        const {awards, awardsLoading, selectedCandidate} = this.state;

        if (awardsLoading) {
            return <Spinner/>
        } else {
            if (!(_.isEmpty(selectedCandidate))) {
                return (
                    <div>
                        <AwardsCard awards={awards}/>
                    </div>
                );
            } else {
                return <h2 style={{ color: "black", margin: "2rem 0"  }}>Select a candidate to view</h2>;
            }
        }
    }


    checkUser() {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                return (
                    <div>
                        <div className={"settingsAssetsBox"}>
                            <div className={"addAssetsSettingsBox"}>
                                <h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Awards</h4>
                            </div>
                        </div>
                        <br/>
                        <div className={"infoBox"}>
                            <div className={"assetsDetailsBox"}>
                                <div>
                                    {this.renderAwards()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }
    }

    render() {
        return (
            <div className={'employeeToolContainer'}>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { awards, awardsLoading, selectedCandidate } = state.posts;
    const { profile } = state.profile;

    return{
        selectedCandidate,
        awards, awardsLoading,
        profile
    }
}

export default connect(mapStateToProps, {})(Awards);