import { combineReducers } from 'redux';

//-> reducers imports
import AuthenticationReducer from './AuthenticationReducer';
import PostsReducer from "./PostsReducer";
import SettingReducer from "./SettingReducer";
import ProfileReducer from "./ProfileReducer";
import HomeReducer from "./HomeReducer";
import NotificationsReducer from "./NotificationsReducer";
import SupportReducer from "./SupportReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import LandingReducer from './LandingReducer';
import ReportReducer from "./ReportReducer";
import SystemReducer from "./SystemReducer";
import SearchReducer from "./SearchReducer";


export default combineReducers({
    authentication: AuthenticationReducer,
    home: HomeReducer,
    posts: PostsReducer,

    profile: ProfileReducer,
    settings: SettingReducer,
    notifications: NotificationsReducer,
    support: SupportReducer,
    subscription: SubscriptionReducer,
    landing: LandingReducer,
    reports: ReportReducer,
    system: SystemReducer,

    search: SearchReducer
});