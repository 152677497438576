import React, { Component } from 'react';
import { Modal } from 'antd';
import { connect } from "react-redux";
import _ from "lodash";

// action imports
import { fetchPaymentStatus, subscriptionFieldUpdated } from "../../../../actions/SubscriptionActions";

//common imports
import { Spinner } from "../../../common/Spinner";


class PaymentModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			flwUrl: this.props.flwUrl,
			systemInfo: this.props.systemInfo,
			visible: this.props.visible,
			loading: true
		};

		this.webViewRef = React.createRef();
	}

	componentDidMount() {
		const { flwUrl } = this.state;

		try {
			//this.props.fetchPaymentStatus({ transactionID: payment.transactionID, fromWeb: true, });
			this.props.subscriptionFieldUpdated({ prop: "flwUrl", value: flwUrl });

		} catch (e) {
			console.log(e);
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				flwUrl: nextProps.flwUrl,
				systemInfo: nextProps.systemInfo,
				visible: nextProps.visible,
			});
		}
	}

	onNavigationStateChange = (state) => {
		const { url } = state;
		const { systemInfo } = this.state;

		if (!url) return;

		if (!(_.isEmpty(systemInfo))) {
			const callbackUrl = systemInfo.foreignCallback;

			if (url === callbackUrl && this.webViewRef) {
				//this.webViewRef.current.injectJavaScript('window.location = "' + callbackUrl + '"');
			}
		}

		if (url === 'https://standard.paystack.co/close') {
			alert("Close this browser");
		}
	};

	componentWillUnmount() {
		this.props.subscriptionFieldUpdated({ prop: "payment", value: {} });
	}


	handleCloseModal = () => {
		this.setState({ visible: false });
        this.props.subscriptionFieldUpdated({ prop: "flwUrl", value: '' });
	};

	handleLoadEnd = () => {
		console.log("called now?")
		this.setState({ loading: false });

		// Access the iframe contentWindow to attach event listeners
		const iframe = this.webViewRef.current;
		if (iframe && iframe.contentWindow) {
			iframe.contentWindow.postMessage('IframeLoaded', '*'); // '*': send to any origin
		}
	};

	handleIframeMessage = (event) => {
		// Handle messages received from the iframe content
		// e.g., Detect certain actions or navigation changes within the iframe
		const { data } = event;
		// Process the received data or perform actions based on iframe events
		if (data === 'IframeAction1') {
			// Perform actions based on the message received from the iframe
		}
	};


	render() {
		const { visible, flwUrl, loading } = this.state;

		return (
			<div>
				<Modal
					title="Payment Modal"
					open={visible}
					onCancel={this.handleCloseModal}
					footer={null}
					destroyOnClose
					width={800} // Adjust width as needed
				>
					<div>
						<iframe
							ref={this.webViewRef}
							src={flwUrl}
							onLoad={this.handleLoadEnd}
							onError={(error) => console.error('Iframe error:', error)}
							style={{ width: '100%', height: '100vh' }}
							title={"Paystack Payment Iframe"}
							allow={"payment"}
						/>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { flwUrl } = state.subscription;
	const { systemInfo } = state.system;

	return {
		flwUrl,
		systemInfo
	}
}

export default connect(mapStateToProps, { fetchPaymentStatus, subscriptionFieldUpdated  })(PaymentModal);