import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import { Tabs } from 'antd';

//action imports
import {fetchSelectedSeeker, fetchSeekerProfile, fetchSeekerPath} from "../../../actions/PostsActions";

//components import
import Summary from "./Summary";
import Education from "../education/Education";
import Employment from "../employment/Employment";
import Skills from "../skills/Skills";
import Languages from "../languages/Languages";
import Awards from "../awards/Awards";
import Certificates from "../certificates/Certificates";
import Links from "../links/Links";
import Licenses from "../licences/Licenses";
import Military from "../military/Military";
import SeekerDocuments from "../documents/SeekerDocuments";
import Interviews from "../interviews/Interviews";
import Referees from '../referees/Referees';


class SeekerProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            pushedSeeker: this.props.pushedSeeker,
        }

        this.populate = this.populate.bind(this);
    }

    componentDidMount() {
        //extract state
        this.populate();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                pushedSeeker: nextProps.pushedSeeker,
            });
        }

        if (nextProps.pushedSeeker !== this.props.pushedSeeker) {
            this.populate();
        }
    }

    populate() {
        //extract state
        const { pushedSeeker } = this.state;

        console.log("Pushed seeker", pushedSeeker)

        if (!(_.isEmpty(pushedSeeker))) {
            const seekerID = pushedSeeker.userID;
            this.props.fetchSelectedSeeker({ seekerID });
            this.props.fetchSeekerProfile({ seekerID });
            this.props.fetchSeekerPath({ seekerID, path: "interviews" });
            this.props.fetchSeekerPath({ seekerID, path: "certificates" });
            this.props.fetchSeekerPath({ seekerID, path: "education" });
            this.props.fetchSeekerPath({ seekerID, path: "employment" });
            this.props.fetchSeekerPath({ seekerID, path: "languages" });
            this.props.fetchSeekerPath({ seekerID, path: "awards" });
            this.props.fetchSeekerPath({ seekerID, path: "licenses" });
            this.props.fetchSeekerPath({ seekerID, path: "hobbies" });
            this.props.fetchSeekerPath({ seekerID, path: "military" });
            this.props.fetchSeekerPath({ seekerID, path: "skills" });
            this.props.fetchSeekerPath({ seekerID, path: "links" });
            this.props.fetchSeekerPath({ seekerID, path: "referees" });
        }
    }

    checkUser() {
        //extract tabPane
        const { TabPane } = Tabs;

        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if (profile.role === "admin" || profile.role === "superAdmin") {
                return (
                    <div style={{margin: '1.2rem 1.2rem 0.5rem 1.2rem', paddingTop: '3rem'}}>
                        <Tabs defaultActiveKey="1" style={{fontFamily: 'ramblaBold, sans-serif', color: "#000"}}>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Summary</span>} key="1">
                                <Summary/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Education</span>} key="2">
                                <Education/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Employment</span>} key="3">
                                <Employment/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Skills</span>} key="4">
                                <Skills/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Languages</span>} key="5">
                                <Languages/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Certificates</span>} key="6">
                                <Certificates/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Awards</span>} key="7">
                                <Awards/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Links</span>} key="8">
                                <Links/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Licenses</span>} key="9">
                                <Licenses/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Military</span>} key="10">
                                <Military/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Documents</span>} key="11">
                                <SeekerDocuments/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Interviews</span>} key="12">
                                <Interviews/>
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Referees</span>} key="13">
                                <Referees/>
                            </TabPane>
                        </Tabs>
                    </div>
                );
            } else {
                return (
                    <div style={{margin: '1.2rem 1.2rem 0.5rem 1.2rem'}}>
                        <p style={{ color: "#000"}}>Unauthorized access</p>
                    </div>
                );
            }
        } else {
            return <p style={{ color: "#fff"}}>No profile</p>
        }
    }

    render() {
        return(
            <div>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { pushedSeeker } = state.posts;
    const { profile } = state.profile;

    return {
        profile,
        pushedSeeker
    }
};

export default connect(mapStateToProps, { fetchSelectedSeeker, fetchSeekerProfile, fetchSeekerPath })(SeekerProfile);