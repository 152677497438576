// import React from 'react';
// import { Input, AutoComplete } from 'antd';
// import { connect } from 'react-redux';
// import { searchFieldChanged, fetchSkills } from '../../../actions/SearchActions';

// class SkillsFilter extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             skillsSearchQuery: this.props.skillsSearchQuery,
//             skills: this.props.skills
//         };
//     }

//     componentWillReceiveProps(nextProps, nextContext) {
//         if (nextProps !== this.props) {
//             this.setState({
//                 skillsSearchQuery: nextProps.skillsSearchQuery,
//                 skills: nextProps.skills
//             });
//         }
//     }

//     handleSearch = (value) => {
//         this.props.searchFieldChanged({ prop: "skillsSearchQuery", value });
//         this.props.fetchSkills({ searchQuery: value });
//     };

//     handleSelect = (value, option) => {
//         const { objectID } = option.data;
//         this.props.searchFieldChanged({ prop: "skillsSearchQuery", value });
//         this.props.searchFieldChanged({ prop: "skill", value: objectID });
//     };

//     render() {
//         const { skillsSearchQuery, skills } = this.state;

//         return (
//             <div>
//                 <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Skills</p>
//                 <AutoComplete
//                     value={skillsSearchQuery}
//                     onChange={this.handleSearch}
//                     onSelect={this.handleSelect}
//                     options={skills.map((result) => ({
//                         value: result.title,
//                         label: result.title,
//                         data: {
//                             title: result.title,
//                             objectID: result.objectID,
//                         },
//                     }))} // Convert search results to options format
//                     style={{ width: "100%", borderColor: '#000' }}
//                 >
//                     <Input.Search placeholder="Search Skills" />
//                 </AutoComplete>
//             </div>
//         );
//     }
// }

// const mapStateToProps = (state) => {
//     const { skillsSearchQuery, skills } = state.search;
//     return {
//         skillsSearchQuery, skills
//     };
// };

// const mapDispatchToProps = {
//     searchFieldChanged, fetchSkills,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(SkillsFilter);


import React from "react";
import { connect } from "react-redux";
import {message, Select} from "antd";

// Action imports
import { searchFieldChanged, fetchSkills } from "../../../actions/SearchActions";

//property declarations
const { Option } = Select;

class SkillsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: this.props.skills,
            skill: this.props.skill,
        }

        this.handleScheduleChange = this.handleScheduleChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchSkills();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                skills: nextProps.skills,
                skill: nextProps.skill
            });
        }
    }

    handleScheduleChange = (selectedSchedules) => {
        if (selectedSchedules.length === 0) {
            // Dispatch an action to set skill to null
            this.props.searchFieldChanged({ prop: "skill", value: null });
        } else {
            // Otherwise, proceed as usual
            this.props.searchFieldChanged({ prop: "skill", value: selectedSchedules });
        }
    };

    render() {
        const { skills, skill } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Skills</p>
                <Select
                    mode="multiple"
                    placeholder="Search Skills"
                    value={skill}
                    onChange={this.handleScheduleChange}
                    allowClear
                    style={{ width: "100%", borderColor: '#000' }}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {skills.map((option) => (
                        <Option key={option.objectID} value={option.objectID} label={option.title}>
                            {option.title}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { skills, skill } = state.search;

    return {
        skills,
        skill
    };
};

export default connect(mapStateToProps, { searchFieldChanged, fetchSkills })(SkillsFilter);
