import React, { Component } from 'react';
import firebase from 'firebase'; // Adjust this import based on your file structure
import { Card, Typography, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

class BlogListBase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogs: [],
		};
	}

	componentDidMount() {
		this.fetchBlogs();
	}

	fetchBlogs = () => {
		firebase.firestore().collection('blogs').limit(10).get().then((querySnapshot) => {
			const blogs = [];
			querySnapshot.forEach((doc) => {
				blogs.push({ id: doc.id, ...doc.data() });
			});
			this.setState({ blogs });
		}).catch((error) => {
			message.error("Error fetching blogs: ", error).then(() => null);
		});
	};

	navigateToBlog = (id) => {
		this.props.navigate(`/blog/${id}`);
	};

	deleteBlog = (id) => {
		firebase.firestore().collection('blogs').doc(id).delete().then(() => {
			message.success("Document successfully deleted!").then(() => null);
			this.fetchBlogs(); // Refresh the list after deletion
		}).catch((error) => {
			message.error("Error removing document: ", error).then(() => null);
		});
	};

	render() {
		return (
			<div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
				{this.state.blogs.map((blog) => (
					<Card
						key={blog.id}
						style={{ width: 300, margin: '20px' }}
						onClick={() => this.navigateToBlog(blog.id)}
						actions={[
							<EditOutlined key="edit" onClick={(event) => { event.stopPropagation(); this.props.history.push(`/edit-blog/${blog.id}`); }} />,
							<DeleteOutlined key="delete" onClick={(event) => { event.stopPropagation(); this.deleteBlog(blog.id); }} />,
						]}
					>
						<Typography.Title level={4}>
							{blog.title}
						</Typography.Title>
						<Typography.Text type="secondary">
							{new Date(blog.createdAt.seconds * 1000).toDateString()}
						</Typography.Text>
						<Typography.Paragraph style={{ marginTop: '12px', color: "#000" }}>
							{blog.description}
						</Typography.Paragraph>
					</Card>
				))}
			</div>
		);
	}
}

// Wrapper functional component to use hooks
function BlogList(props) {
	const navigate = useNavigate();

	return <BlogListBase {...props} navigate={navigate} />;
}

export default BlogList;