import React, { useEffect } from 'react';

const MyPdfViewer = ({ pdfUrl }) => {
    useEffect(() => {
        const disableRightClick = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', disableRightClick);

        return () => {
            document.removeEventListener('contextmenu', disableRightClick);
        };
    }, []);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <object
                data={pdfUrl}
                type="application/pdf"
                width="100%"
                height="100%"
            >
                <p>
                    It appears you don't have a PDF plugin for this browser. You can{' '}
                    <a href={pdfUrl}>click here to download the PDF file</a>.
                </p>
            </object>
        </div>
    );
};

export default MyPdfViewer;
