import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import arraySort from 'array-sort';
import _ from 'lodash';
//import moment from 'moment';
import NotificationCard from "../../notifications/cards/NotificationCard";

//-> action imports

//-> components imports


class Announcements extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            notifications: this.props.notifications,
            accountInfo: this.props.accountInfo,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.notifications !== this.props.notifications) {
            this.setState({ notifications: nextProps.notifications })
        }

        if (nextProps.accountInfo !== this.props.accountInfo) {
            this.setState({ accountInfo: nextProps.accountInfo })
        }
    }

    renderNotificationsQuantity(){
        const { notifications } = this.state;

        const notificationsQuantity = _.map(notifications);

        if (notificationsQuantity.length !== 0){
            return <h3>{notificationsQuantity.length}</h3>
        } else {
            return <h3>0</h3>
        }
    }

    renderNotifications() {
        //extract state
        const { notifications } = this.state;

        //check that notifications is not empty
        if (!(_.isEmpty(notifications))) {
            //sort notifications by time
            const notificationsArray = _.map(notifications, notification => notification);
            const sortedNotifications = arraySort(notificationsArray, "notificationTime", {reverse: true});

            //only show 6 notifications
            const sixNotifications = sortedNotifications.slice(0, 6);
            return(
                <div>
                    {
                        sixNotifications.map(notification => {
                            return (
                                <div key={notification.notificationID}><NotificationCard notification={notification}/></div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return <h3 className={"adminAlert"}>There are currently no notifications</h3>;
        }
    }

    render(){

        return (
            <div className={"parentContainer"}>
                <div className={"notificationHeaderBox"}>
                    <h4>Whats happening!</h4>
                    <h5>Announcements</h5>
                </div>

                <div className={"notificationInfoBox"}>
                    {/* Render notification Announcements Details here */}
                    {this.renderNotifications()}
                </div>

                {/* Render older notifications quantity */}
                <div className={"olderAnnouncementsTrigger"}>
                    <div className={"announcementBoxOne"}>
                        {this.renderNotificationsQuantity()}
                    </div>
                    <div className={"announcementBoxTwo"}>
                        <h4 >Announcement(s)</h4>
                        {/* <h5><FontAwesomeIcon icon="bullhorn" flip={"horizontal"} className={"olderNotificationTriggerButton"}/></h5> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { notifications } = state.notifications;
    const { accountInfo } = state.settings;

    return {
        notifications,
        accountInfo,
        
    }
};


export default connect(mapStateToProps, )(Announcements);