import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

//actions import
import {fetchAdminPosts} from "./../../../actions/PostsActions";

//components import
import {ClipLoader} from "react-spinners";
import AdminJobPosts from './cards/AdminJobPosts';


class AdminPost extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			profile: this.props.profile,
			adminPostLoading: this.props.adminPostLoading,
			adminJobs: this.props.adminJobs,
		}
	}

	componentDidMount() {
		this.props.fetchAdminPosts();
	}

	componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState ({profile: nextProps.profile})
        }

		if (nextProps.adminPostLoading !== this.props.adminPostLoading) {
            this.setState ({adminPostLoading: nextProps.adminPostLoading})
        }

		if (nextProps.adminJobs !== this.props.adminJobs) {
            this.setState ({adminJobs: nextProps.adminJobs})
        }
	}

	renderPosts() {
		const {adminJobs, adminPostLoading} = this.state;

		if (adminPostLoading) {
			return <ClipLoader/>
		} else {
			return (
				<div>
					<AdminJobPosts/>
				</div>
			);
		}
	}


	checkUser() {
		//extract state
		const { profile } = this.state;

		if(!(_.isEmpty(profile))) {
            return (
                <div>
                    <div className={"settingsAssetsBox"}>
                        <div className={"addAssetsSettingsBox"}>
                            <h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Job Posts</h4>
                        </div>
                    </div>
                    <br/>
                    <div className={"infoBox"}>
                        <div className={"assetsDetailsBox"}>
                            <div>
                                {this.renderPosts()}
                            </div>
                        </div>
                    </div>
                </div>
            );
		}
	}

	render() {
		return (
			<div>
				{ this.checkUser() }
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	//-> extract state
	const { adminPostLoading, adminJobs } = state.settings;
	const { profile } = state.profile;

	return{
		profile,
		adminPostLoading,
		adminJobs
	}
}

export default connect(mapStateToProps, {fetchAdminPosts})(AdminPost);