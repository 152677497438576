import React from 'react';
import { Bar } from '@ant-design/charts';

function EducationChart({candidateLevel}) {

    const data = candidateLevel.map(({educationLevelTitle, number}) => ({
        educationLevelTitle: educationLevelTitle,
        number: number
    }))
    
    const config = {
        data,
        xField: 'educationLevelTitle',
        yField: 'number',
        title: {
            text: 'Education Level Distribution',
        },
    };
    return (
        <div>
                <Bar {...config} />
                <p style={{color: '#000'}}>Education Level Distribution</p>
        </div>
    );
}

export default EducationChart;
