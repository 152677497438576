import React from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import firebase from 'firebase';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
        };
        this.formRef = React.createRef();
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    hideModal = () => {
        this.setState({ visible: false });
        this.formRef.current.resetFields();
    };

    handleOk = async () => {
        try {
            const formValues = await this.formRef.current.validateFields();
            const { currentPassword, newPassword } = formValues;
            this.setState({ loading: true });

            // Re-authenticate the user before changing the password
            const user = firebase.auth().currentUser;
            const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
            await user.reauthenticateWithCredential(credential);

            // Change the password
            await user.updatePassword(newPassword);

            message.success('Password successfully changed!');
            this.setState({ loading: false });
            this.hideModal();
        } catch (error) {
            message.error('Failed to change password. Please check your current password and try again.');
            this.setState({ loading: false });
        }
    };

    render() {
        const { visible, loading } = this.state;

        return (
            <div>
                <Button type="link" onClick={this.showModal}>
                    <span style={{ fontFamily: "ramblaBold" }}>Forgot Password</span>
                </Button>
                <Modal
                    title="Change Password"
                    open={visible}
                    onOk={this.handleOk}
                    onCancel={this.hideModal}
                    confirmLoading={loading}
                    footer={[
                        <Button key="cancel" onClick={this.hideModal}>
                            <span style={{ fontFamily: "ramblaBold" }}>Cancel</span>
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={this.handleOk}
                        >
                            <span style={{ fontFamily: "ramblaBold" }}>Change Password</span>
                        </Button>,
                    ]}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Form.Item
                            name="currentPassword"
                            label="Current Password"
                            rules={[{ required: true, message: 'Please enter your current password' }]}
                            fieldContext={this.formRef.current}
                        >
                            <Input.Password placeholder="Enter your current password" />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label="New Password"
                            rules={[
                                { required: true, message: 'Please enter your new password' },
                                { min: 6, message: 'Password must be at least 6 characters long' },
                            ]}
                            fieldContext={this.formRef.current}
                        >
                            <Input.Password placeholder="Enter your new password" />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Confirm Password"
                            dependencies={['newPassword']}
                            rules={[
                                { required: true, message: 'Please confirm your new password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords do not match'));
                                    },
                                }),
                            ]}
                            fieldContext={this.formRef.current}
                        >
                            <Input.Password placeholder="Confirm your new password" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default ChangePassword;