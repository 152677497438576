import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/charts';
import { Spinner } from '../../common/Spinner';
const DoubleBarGraph = ({industryCounts, fetchingGenderInfo}) => {

    
    const data = Object.entries(industryCounts).flatMap(([field, counts]) => [
      { industry: field, count: counts.male, type: 'male' },
      { industry: field, count: counts.female, type: 'female' }
    ]);
    
    const config = {
      data,
      isStack: true,
      xField: 'industry',
      yField: 'count',
      isPercent: true,
    
      meta: {
        count: {
        min: 0,
        max: 100,
        },
      },
 
    colorField: 'type', // or seriesField in some cases
    color:['#19CDD7','#DDB27C'],

  };
  return( 
  
    <div>
      {fetchingGenderInfo? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner/> 
        </div>
      ) : (
        <>
          <h3 style={{ textAlign: "center" }}>Distribution of Gender-Based Interview Offers by Industry</h3>
          <Bar {...config} /> 
        </>
      )}
    </div>
  

)
};
export default DoubleBarGraph


