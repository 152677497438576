import React from 'react';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { CustomerSupportIcon, JusticeScale01Icon , MarketingIcon, PowerSocket01Icon } from 'hugeicons-react';

class Footer extends React.Component {
    render(){
        const year = moment().format("YYYY");
        return(
            // <div className={"footer"}>
            //     <div>
            //         <h4 style={{ textAlign: "center", color:'black' }}> Land Your Job.</h4>
            //     </div>
                
            //     <a href="https://nafasi.io/download" target="_blank" rel="noreferrer noopener" style={{ textAlign:"center", fontFamily: "ramblaBold", color: "#000", textDecoration: "none" }}><span style={{ color: "#000"}}>&copy; Copyright  {year}</span>  ®  Nafasi</a>
            //     <li style={{ textAlign: "center", color:'black',textDecoration:"none"}}><NavLink to={'/terms'}><span>Terms & Conditions</span></NavLink></li>
            //     <li style={{ textAlign: "center", color:'black', textDecoration:"none"}}><NavLink to={'/privacy'}><span>Privacy</span></NavLink></li>
            //     <li style={{ textAlign: "center", color:'black', textDecoration:"none"}}><NavLink to={'/disclosure'}><span>Disclosure</span></NavLink></li>
               

            //     <div style={{ margin: "0.5rem", display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1rem' }}>
            //         <div><a href={"https://twitter.com/nafasiio"} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={['fab', 'twitter']} style={{fontSize: '1.2rem', marginTop: '0.2rem'}} color={'#ffffff'} /></a></div>
            //         <div><a href={"https://www.linkedin.com/company/nafasiio"} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={['fab', 'linkedin']} style={{fontSize: '1.2rem', marginTop: '0.2rem'}} color={'#ffffff'} /></a></div>
            //         <div><a href={"https://instagram.com/nafasi.io?igshid=MzRlODBiNWFlZA=="} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={['fab', 'instagram']} style={{fontSize: '1.2rem', marginTop: '0.2rem'}} color={'#ffffff'} /></a></div>
            //     </div>
            // </div>

            <div className="footer">
            <div className="footer-about">
            <h4 style={{ textAlign: "center", color:'black' }}> Don't Miss Out. </h4>
            <p>
                    Nafasi is dedicated to connecting job seekers with their dream careers. 
                    Our platform offers the tools and resources needed to land the perfect job.
                    Join us to start your journey today. We provide a comprehensive suite of job search tools and resources, including resume building, interview preparation, and job matching.
                     Our mission is to empower job seekers to achieve their career goals by providing them with the most relevant and up-to-date job listings, company information, and career advice.
                     Nafasi is available on the App Store and Play Store, and our website offers exclusive features for recruiters to manage their hiring process efficiently.
                </p>
            </div>
            <div className="footer-columns">
                <div className="footer-column">
                    <h5>Services <MarketingIcon size={20}  /></h5>
                    <ul>
                        <li><NavLink to="/pricing">Pricing</NavLink></li>
                        <li><NavLink to="/developers">API</NavLink></li>
                      
                    </ul>
                </div>
                <div className="footer-column">
                    <h5>Customer Support <CustomerSupportIcon size={20}  />  </h5>
                    <ul>
                        
                        <li><NavLink to="/faq">FAQ</NavLink></li>
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                        <li><NavLink to="/contact-us">Location</NavLink></li>
                       
                    </ul>
                </div>
                <div className="footer-column">
                    <h5>Legal <JusticeScale01Icon size={20}  />  </h5>
                    <ul>
                        <li><NavLink to="/terms">Terms & Conditions</NavLink></li>
                        <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
                        <li><NavLink to="/disclosure">Disclosure</NavLink></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h5>Social Media  <PowerSocket01Icon size={20}  /></h5>
                    <ul>
                    
                        <li><a href="https://twitter.com/nafasiio" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={['fab', 'twitter']} /></a> Twitter</li>
                        <li> <a href="https://www.linkedin.com/company/nafasiio" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a> LinkedIn</li>
                        <li><a href="https://instagram.com/nafasi.io?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={['fab', 'instagram']} /></a> Instagram</li>
                        
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-copyright">
                    <a href="https://nafasi.io/download" target="_blank" rel="noreferrer noopener">
                        <span>&copy; Copyright {year}</span> ® Nafasi
                    </a>
                </div>
               
            </div>
        </div>
        )
    }
}

export default Footer;