import React from 'react';
import { connect } from 'react-redux';

//badge imports
import AppleBadge from "../../assets/img/app-store-badge.svg";
import GoogleBadge from "../../assets/img/google-play-badge.svg";
import Ratings from "../../assets/images/ratings.jpeg";
// import PortraitBlack from "../../assets/img/portrait_black.png";
import LandingImage from "../../assets/images/nafasi_landing.png"
import Logo from "../../assets/logos/taglineLogo.png";
import Screenshot from "../../assets/images/screenshot.jpeg";
import Universe from "../../assets/images/global.svg";
import Benefits from "../../assets/images/benefits.png";
import Preferences from "../../assets/images/preferences.png";
import RoadMap from './cards/RoadMap';
import BenefitsPage from './cards/Benefits';
import { NavLink } from 'react-router-dom';

class SplitLanding extends React.Component {
    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentWillReceiveProps(nextProps, nextContext){

    }

    render(){

        return (
            <body id="page-top">
                <header className="masthead">
                    <div className="container px-5">
                        <div className="row gx-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-5 mb-lg-0 text-center text-lg-start">
                                    <img src={Logo} alt="..." style={{ height: "10rem" }}/>
                                    <h3 className="display-3 lh-1 mb-3" style={{ backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>Matching Recruiters with Top Talent</h3>
                                    <p className="text-muted mb-0" style={{textAlign: 'left', fontSize:"15px"}}>Try <span style={{ fontFamily: "ramblaBold"}}>Nafasi now</span> for the better results.</p>
                                    <NavLink to={'/login'}><button style={{ backgroundColor: '#ffb400', color: '#fff', padding:"15px 25px", boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)', border: 'none', borderRadius: '5px', width: "20rem" }} className="btn btn-primary mt-3"><span style={{color: "black", fontFamily: "ramblaBold", fontSize: "1.5rem"}}>Get Started</span></button></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-5 mb-lg-0 text-center text-lg-start split-landing" style={{ backgroundColor: '#e9ecef', padding: '20px', borderRadius: '10px' }}>
                                    <img src={LandingImage} alt="..."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <aside className=" bg-gradient-primary-to-secondary">
                    <div className="container px-5 text-center">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-xl-8">
                                <div className="h2 fs-1 text-white mb-4">"An intuitive solution to a common problem that
                                    we all face, wrapped up in a single app!"
                                </div>
                            </div>
                        </div>
                    </div>
                    <RoadMap/>
                </aside>

                <section id="features">
                    {/* <div className="container px-5">
                        <div className="row gx-5 align-items-center">
                            <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
                                <div className="container-fluid px-5">
                                    <div className="row gx-5">
                                        <div className="col-md-6 mb-5">

                                            <div className="text-center">
                                                <i className="bi-lightbulb icon-feature text-gradient d-block mb-3"> </i>
                                                <h3 className="font-alt">Extensive Opportunities</h3>
                                                <p className="text-muted mb-0">Nafasi provides access to job openings across various industries, in and outside the country.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-5">

                                            <div className="text-center">
                                                <i className="bi-file-earmark-text icon-feature text-gradient d-block mb-3"> </i>
                                                <h3 className="font-alt">Effortless Application Process</h3>
                                                <p className="text-muted mb-0">Upload your CV once and we will deliver it to multiple vacant positions</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-5 mb-md-0">
                                            <div className="text-center">
                                                <i className="bi-patch-check icon-feature text-gradient d-block mb-3"> </i>
                                                <h3 className="font-alt">Assessment Ratings</h3>
                                                <p className="text-muted mb-0">Nafasi's innovative rating system allows you to evaluate your competence based on previous assessments, giving you a competitive edge</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="text-center">
                                                <i className="bi-speedometer2 icon-feature text-gradient d-block mb-3"> </i>

                                                <h3 className="font-alt">Instant CV Updates</h3>
                                                <p className="text-muted mb-0">Effortlessly update your CV in real time, ensuring a fresh and up-to-date copy even previous recruiters who shortlisted you</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-lg-0">

                                <div className="features-device-mockup">
                                    <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                                                <stop className="gradient-start-color" offset="0%"> </stop>
                                                <stop className="gradient-end-color" offset="100%"> </stop>
                                            </linearGradient>
                                        </defs>
                                        <circle cx="50" cy="50" r="50"> </circle>
                                    </svg
                                    >
                                    <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03"
                                              transform="translate(120.42 -49.88) rotate(45)"> </rect>
                                        <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03"
                                              transform="translate(-49.88 120.42) rotate(-45)"> </rect>
                                    </svg
                                    >
                                    <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50" cy="50" r="50"> </circle>
                                    </svg>
                                    <div className="device-wrapper">
                                        <div className="device" data-device="iPhoneX" data-orientation="portrait"
                                             data-color="black">
                                            <div className="screen bg-black">
                                                <img src={Ratings} alt="..."
                                                     style={{ maxWidth: "100%", height: "100%" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <BenefitsPage/>
                </section>

                <section className =" bg-gradient-primary-to-secondary">
                    <div className="container px-5">
                        <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-12 col-lg-5">
                                <h2 className="display-4 lh-1 mb-4">Local & International Opportunities</h2>
                                <p className="lead fw-normal mb-5 mb-lg-0" >
                                    Tired of endless searches and getting lost in a sea of resume applications?
                                </p>
                                <p className="lead fw-normal  mb-5 mb-lg-0" >
                                    With just ONE tap, access thousands of options tailored to your preferences. From full-time jobs, and part-time gigs to internships, student fieldwork, and more, we have it all.
                                </p>
                            </div>
                            <div className="col-sm-8 col-md-6">
                                <div className="px-5 px-sm-0">
                                    <img className="img-fluid rounded-circle"
                                         src={Universe}
                                         alt="..."/></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="cta">
                    <div className="cta-content">
                        <div className="px-5 px-sm-0">
                            <img className="img-fluid"
                                 src={Benefits}
                                 alt="..."
                            />
                        </div>
                        <div className="px-5 px-sm-0">
                            <img className="img-fluid"
                                 src={Preferences}
                                 alt="..."
                            />
                        </div>
                    </div>
                </section> */}


                <div className="modal fade" id="feedbackModal" tabIndex="-1" aria-labelledby="feedbackModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header bg-gradient-primary-to-secondary p-4">
                                <h5 className="modal-title font-alt text-white" id="feedbackModalLabel">Send
                                    feedback</h5>
                                <button className="btn-close btn-close-white" type="button" data-bs-dismiss="modal"
                                        aria-label="Close"> </button>
                            </div>
                            <div className="modal-body border-0 p-4">

                                <div id="contactForm">

                                    <div className="form-floating mb-3">
                                        <input className="form-control" id="name" type="text"
                                               placeholder="Enter your name..." data-sb-validations="required"/>
                                        <label htmlFor="name">Full name</label>
                                        <div className="invalid-feedback" data-sb-feedback="name:required">A name is
                                            required.
                                        </div>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input className="form-control" id="email" type="email"
                                               placeholder="name@example.com" data-sb-validations="required,email"/>
                                        <label htmlFor="email">Email address</label>
                                        <div className="invalid-feedback" data-sb-feedback="email:required">An email is
                                            required.
                                        </div>
                                        <div className="invalid-feedback" data-sb-feedback="email:email">Email is not
                                            valid.
                                        </div>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input className="form-control" id="phone" type="tel"
                                               placeholder="(123) 456-7890" data-sb-validations="required"/>
                                        <label htmlFor="phone">Phone number</label>
                                        <div className="invalid-feedback" data-sb-feedback="phone:required">A phone
                                            number is required.
                                        </div>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <textarea className="form-control" id="message"
                                        placeholder="Enter your message here..." style={{ height: "10rem"}}
                                        data-sb-validations="required"/>
                                        <label htmlFor="message">Message</label>
                                        <div className="invalid-feedback" data-sb-feedback="message:required">A message
                                            is required.
                                        </div>
                                    </div>

                                    <div className="d-none" id="submitSuccessMessage">
                                        <div className="text-center mb-3">
                                            <div className="fw-bolder">Form submission successful!</div>
                                            To activate this form, sign up at
                                            <br/>
                                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                        </div>
                                    </div>
                          
                                    <div className="d-none" id="submitErrorMessage">
                                        <div className="text-center text-danger mb-3">Error sending message!</div>
                                    </div>

                                    <div className="d-grid">
                                        <button className="btn btn-primary rounded-pill btn-lg disabled"
                                                id="submitButton" type="submit">Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        )
    }
}

export default connect(null, { })(SplitLanding);