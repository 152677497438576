import React, { useEffect, useState } from 'react';
import {Button, message, Modal, Typography} from 'antd';
import { VerifiedOutlined } from '@ant-design/icons';
import firebase from 'firebase'; // Make sure to import the correct Firebase module

const { Text } = Typography;

const VerifyUser = () => {
	const [showModal, setShowModal] = useState(false);
	const [email, setEmail] = useState('');

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				const userID = user.uid;
				firebase.firestore().collection("adminBucket").doc(userID)
					.onSnapshot((doc) => {
						if (doc.exists) {
							const data = doc.data();
							if ("verified" in data) {
								const verified = data.verified;
								if (verified) {
									setShowModal(false);
									message.success("You have been verified");
								} else {
									setShowModal(true);
									const userEmail = user.email;
									setEmail(userEmail);
								}
							} else {
								setShowModal(true);
								const userEmail = user.email;
								setEmail(userEmail);
							}
						}
					}, (e) => {
						console.log(e);
					})
			}
		});

		// Cleanup the event listener when component unmounts
		return () => unsubscribe();
	}, []);

	const handleCloseModal = () => {
		//setShowModal(false);
	};

	return (
		<Modal
			open={showModal}
			title={
				<>
					<VerifiedOutlined style={{ marginRight: 10 }} />
					Account Verification
				</>
			}
			onCancel={handleCloseModal}
			footer={null}
		>
			<p style={{ textAlign: 'center', marginBottom: 10, color: "black", fontSize: 20 }}>
				Please wait while we verify your details
			</p>
			{email && (
				<Text style={{ textAlign: 'center', display: 'block', color: '#0793d9', marginBottom: 10 }}>
					{email}
				</Text>
			)}
			<Button onClick={() => firebase.auth().signOut()} textAlign={"center"}>
				Sign Out
			</Button>
		</Modal>
	);
};

export { VerifyUser };