import firebase from "firebase";

//-> Message Alert Icons

import {
    FETCH_ANNOUNCEMENTS,
    FETCH_ANNOUNCEMENTS_FAILED,
    FETCH_ANNOUNCEMENTS_SUCCESSFUL,
    FETCH_TRAININGS,
    FETCH_TRAININGS_FAILED,
    FETCH_TRAININGS_SUCCESSFUL,

    HOME_LOCATION_CHANGED,
    FETCH_RECENT_SEARCHES,
    FETCH_RECENT_SEARCHES_SUCCESSFUL,
    FETCH_RECENT_SEARCHES_FAILED,
    TOGGLE_MENU
} from "./Types";


export const homeFieldChanged = ({prop, value}) => {
    return {
        type: HOME_LOCATION_CHANGED,
        payload: {prop, value}
    }
}

export const toggleMenu = (isToggled) => {

    return(dispatch) => {  
        dispatch({type:TOGGLE_MENU, payload:isToggled})
      }
};

export const fetchRecentSearches = () => {

    return(dispatch) => {

        dispatch({ type: FETCH_RECENT_SEARCHES });

        let recentSearches = [];
        const user = firebase.auth().currentUser;
        firebase.firestore().collection("adminBucket").doc(user.uid).collection("recentSearches").limit(5)
            .onSnapshot(function(snapshot) {
                if (snapshot.size === 0) {
                    dispatch({ type: FETCH_RECENT_SEARCHES_FAILED })
                } else {
                    snapshot.forEach(function(doc) {
                        recentSearches.push(doc.data());
                    });

                    dispatch({
                        type: FETCH_RECENT_SEARCHES_SUCCESSFUL,
                        payload: recentSearches
                    });
                }
            }, function (error) {
                console.log("Error fetching recent searches");
                console.log(error);
                dispatch({ type: FETCH_RECENT_SEARCHES_FAILED });
            });
    }
};

export const fetchAnnouncements = () => {

    return(dispatch) => {

        dispatch({ type: FETCH_ANNOUNCEMENTS });

        let fetchedAnnouncements = {};

        firebase.firestore().collection("")
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {

                    fetchedAnnouncements[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_ANNOUNCEMENTS_SUCCESSFUL,
                    payload: fetchedAnnouncements
                });

            }, function (error) {
                console.log("Error fetching announcements");
                console.log(error);
                dispatch({ type: FETCH_ANNOUNCEMENTS_FAILED })
            });
    }
};

//------------------------------------------------------------ CELEBRATIONS --------------------------------------------------------

//-------------------------------------------------------------------------------------------------------------------------------------

export const fetchMyTrainings = () => {

    return(dispatch) => {

        dispatch({ type: FETCH_TRAININGS });

        let fetchedTrainings = {};

        firebase.firestore().collection("")
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {

                    fetchedTrainings[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_TRAININGS_SUCCESSFUL,
                    payload: fetchedTrainings
                });

            }, function (error) {
                console.log("Error fetching trainings");
                console.log(error);
                dispatch({ type: FETCH_TRAININGS_FAILED })
            });
    }
};