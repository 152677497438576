//Types import
import {
    ADD_TO_SHORTLIST, ADD_TO_SHORTLIST_FAILED, ADD_TO_SHORTLIST_SUCCESSFUL,
    CLEAR_SEARCH, DELETE_FROM_SHORTLIST, DELETE_FROM_SHORTLIST_FAILED, DELETE_FROM_SHORTLIST_SUCCESSFUL,
    FETCH_CERTIFICATES,
    FETCH_CERTIFICATES_FAILED,
    FETCH_CERTIFICATES_SUCCESSFUL,
    FETCH_EDUCATION_INDUSTRY,
    FETCH_EDUCATION_INDUSTRY_FAILED,
    FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
    FETCH_EDUCATION_LEVELS,
    FETCH_EDUCATION_LEVELS_FAILED,
    FETCH_EDUCATION_LEVELS_SUCCESSFUL,
    FETCH_JOB_TITLES,
    FETCH_JOB_TITLES_FAILED,
    FETCH_JOB_TITLES_SUCCESSFUL,
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILED,
    FETCH_LANGUAGES_SUCCESSFUL,
    FETCH_LICENSES, FETCH_LICENSES_FAILED,
    FETCH_LICENSES_SUCCESSFUL, FETCH_LINKS, FETCH_LINKS_FAILED, FETCH_LINKS_SUCCESSFUL,
    FETCH_SKILLS,
    FETCH_SKILLS_FAILED,
    FETCH_SKILLS_SUCCESSFUL,
    SAVE_SEARCHES,
    SAVE_SEARCHES_FAIL,
    SAVE_SEARCHES_SUCCESS,
    SEARCH,
    SEARCH_FAILED,
    SEARCH_FIELD_CHANGED,
    SEARCH_SUCCESSFUL,
    FETCH_ADMIN_HIGHLIGHTS,
    FETCH_ADMIN_HIGHLIGHTS_FAIL,
    FETCH_ADMIN_HIGHLIGHTS_SUCCESS,
} from "../actions/Types";

const INITIAL_STATE = {
    packageIndex: 3,
    openForRelocation: null,
    country: null,
    region: null,
    jobTypeArray: [],
    workScheduleArray: [],
    gender: null,
    maritalStatus: null,
    startBirthDate: null,
    endBirthDate: null,
    currentEnrollment: null,
    studiedAbroad: null,
    yearOfCompletion: null,

    currentAtWork: null,

    experienceID: null,
    experienceYears: null,
    foreignJob: null,

    militaryCountry: null,
    results: [],
    resultsSearchLimit: 100,
    loading: false,

    educationLevels: [],
    educationLevel: null,
    educationLevelsSearchQuery: "",
    educationLevelsLoading: false,

    educationIndustries: [],
    industryID: null,
    educationIndustriesSearchQuery: "",
    educationIndustriesLoading: false,

    experiencePrimeIndustriesSearchQuery: "",
    experienceSecIndustriesSearchQuery: "",
    jobTitlePrimeSearchQuery: "",
    jobTitleSecSearchQuery: "",

    industryPrime: null,
    industrySec: null,
    positionPrime: null,
    positionSec: null,

    jobTitles: [],
    jobTitle: null,
    jobTitlesSearchQuery: "",
    jobTitleLoading: false,

    skills: [],
    skill: null,
    skillsSearchQuery: "",
    skillsLoading: false,

    certificates: [],
    certificateID: null,
    certificatesSearchQuery: "",
    certificatesLoading: false,

    languages: [],
    language: null,
    languagesSearchQuery: "",
    languagesLoading: false,

    licenses: [],
    license: null,
    licensesSearchQuery: "",
    licensesLoading: false,

    links: [],
    link: null,
    linksSearchQuery: "",
    linksLoading: false,

    shortlistLoading: false,

    saveSearchLoading: false,

    seekerHighlights: []
};

const SearchReducer = (state = INITIAL_STATE, action) => {
    //
    switch (action.type) {
        //search types
        case SEARCH_FIELD_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value };

        case SEARCH:
            return { ...state, loading: true };
        case SEARCH_SUCCESSFUL:
            console.log({ limit: action.payload.resultsSearchLimit });
            return { ...state, loading: false, results: action.payload.seekers, resultsSearchLimit: action.payload.resultsSearchLimit };
        case SEARCH_FAILED:
            return { ...state, loading: false };

        case SAVE_SEARCHES:
            return { ...state, saveSearchLoading: true };
        case SAVE_SEARCHES_SUCCESS:
            return { ...state, saveSearchLoading: false};
        case SAVE_SEARCHES_FAIL:
            return { ...state, saveSearchLoading: false };

        case FETCH_JOB_TITLES:
            return { ...state, jobTitleLoading: true };
        case FETCH_JOB_TITLES_SUCCESSFUL:
            return { ...state, jobTitleLoading: false, jobTitles: action.payload };
        case FETCH_JOB_TITLES_FAILED:
            return { ...state, jobTitleLoading: false };

        case FETCH_EDUCATION_LEVELS:
            return { ...state, educationLevelsLoading: true };
        case FETCH_EDUCATION_LEVELS_SUCCESSFUL:
            return { ...state, educationLevelsLoading: false, educationLevels: action.payload };
        case FETCH_EDUCATION_LEVELS_FAILED:
            return { ...state, educationLevelsLoading: false };

        case FETCH_EDUCATION_INDUSTRY:
            return { ...state, educationIndustriesLoading: true };
        case FETCH_EDUCATION_INDUSTRY_SUCCESSFUL:
            return { ...state, educationIndustriesLoading: false, educationIndustries: action.payload };
        case FETCH_EDUCATION_INDUSTRY_FAILED:
            return { ...state, educationIndustriesLoading: false };

        case FETCH_SKILLS:
            return { ...state, skillsLoading: true };
        case FETCH_SKILLS_SUCCESSFUL:
            return { ...state, skillsLoading: false, skills: action.payload };
        case FETCH_SKILLS_FAILED:
            return { ...state, skillsLoading: false };

        case FETCH_CERTIFICATES:
            return { ...state, certificatesLoading: true };
        case FETCH_CERTIFICATES_SUCCESSFUL:
            return { ...state, certificatesLoading: false, certificates: action.payload };
        case FETCH_CERTIFICATES_FAILED:
            return { ...state, certificatesLoading: false };

        case FETCH_LANGUAGES:
            return { ...state, languagesLoading: true };
        case FETCH_LANGUAGES_SUCCESSFUL:
            return { ...state, languagesLoading: false, languages: action.payload };
        case FETCH_LANGUAGES_FAILED:
            return { ...state, languagesLoading: false };

        case FETCH_LICENSES:
            return { ...state, licensesLoading: true };
        case FETCH_LICENSES_SUCCESSFUL:
            return { ...state, licensesLoading: false, licenses: action.payload };
        case FETCH_LICENSES_FAILED:
            return { ...state, licensesLoading: false };

        case FETCH_LINKS:
            return { ...state, linksLoading: true };
        case FETCH_LINKS_SUCCESSFUL:
            return { ...state, linksLoading: false, links: action.payload };
        case FETCH_LINKS_FAILED:
            return { ...state, linksLoading: false };


            //shortlist
        case ADD_TO_SHORTLIST:
            return { ...state, shortlistLoading: true };
        case ADD_TO_SHORTLIST_SUCCESSFUL:
            return { ...state, shortlistLoading: false };
        case ADD_TO_SHORTLIST_FAILED:
            return { ...state, shortlistLoading: false };

        case DELETE_FROM_SHORTLIST:
            return { ...state, shortlistLoading: true };
        case DELETE_FROM_SHORTLIST_SUCCESSFUL:
            return { ...state, shortlistLoading: false };
        case DELETE_FROM_SHORTLIST_FAILED:
            return { ...state, shortlistLoading: false };


        case CLEAR_SEARCH:
            return {
                ...state,
                packageIndex: 3,
                openForRelocation: null,
                country: null,
                region: null,
                jobTypeArray: [],
                workScheduleArray: [],
                gender: null,
                maritalStatus: null,
                startBirthDate: null,
                endBirthDate: null,
                currentEnrollment: null,
                studiedAbroad: null,
                yearOfCompletion: null,
                currentAtWork: null,
                experienceID: null,
                experienceYears: null,
                foreignJob: null,
                militaryCountry: null,
                resultsSearchLimit: 50,
                loading: false,

                educationLevels: [],
                educationLevel: null,
                educationLevelsSearchQuery: "",
                educationLevelsLoading: false,

                educationIndustries: [],
                industryID: null,
                educationIndustriesSearchQuery: "",
                educationIndustriesLoading: false,

                experiencePrimeIndustriesSearchQuery: "",
                experienceSecIndustriesSearchQuery: "",
                jobTitlePrimeSearchQuery: "",
                jobTitleSecSearchQuery: "",

                industryPrime: null,
                industrySec: null,
                positionPrime: null,
                positionSec: null,

                jobTitles: [],
                jobTitle: null,
                jobTitlesSearchQuery: "",
                jobTitleLoading: false,

                skills: [],
                skill: null,
                skillsSearchQuery: "",
                skillsLoading: false,

                certificates: [],
                certificateID: null,
                certificatesSearchQuery: "",
                certificatesLoading: false,

                languages: [],
                language: null,
                languagesSearchQuery: "",
                languagesLoading: false,

                licenses: [],
                license: null,
                licensesSearchQuery: "",
                licensesLoading: false,

                links: [],
                link: null,
                linksSearchQuery: "",
                linksLoading: false,
            };

        case FETCH_ADMIN_HIGHLIGHTS:
            return {...state }
        case FETCH_ADMIN_HIGHLIGHTS_SUCCESS:
            console.log(action.payload)
            return {...state, seekerHighlights: action.payload}
        case FETCH_ADMIN_HIGHLIGHTS_FAIL:
            return {...state }

        default:
            return state;
    }
};

export default SearchReducer;