export const projectArray = [
    {
        privateID: "0000",
        serverUrl: "https://us-central1-nafasiio.cloudfunctions.net/",
        config: {
            apiKey: "AIzaSyDJugXJ593M37xMb_6FuxUPEZ-boG0Nsow",
            authDomain: "nafasiio.firebaseapp.com",
            projectId: "nafasiio",
            storageBucket: "nafasiio.appspot.com",
            messagingSenderId: "849321740104",
            appId: "1:849321740104:web:f898d624aa65370591d57d",
            measurementId: "G-LN0267W1TL"
        }
    },
];

export const generalConfig = {
    privateID: "0000",
    serverUrl: "https://us-central1-nafasiio.cloudfunctions.net/",
    config: {
        apiKey: "AIzaSyDJugXJ593M37xMb_6FuxUPEZ-boG0Nsow",
        authDomain: "nafasiio.firebaseapp.com",
        projectId: "nafasiio",
        storageBucket: "nafasiio.appspot.com",
        messagingSenderId: "849321740104",
        appId: "1:849321740104:web:f898d624aa65370591d57d",
        measurementId: "G-LN0267W1TL"
    }
};

// GOOGLE CALENDAR API CONFIGS

// export const  CLIENT_ID = "322185554144-a0cnbls3jrqk8rssctgqq083ognl13o2.apps.googleusercontent.com"
// export const  API_KEY = "AIzaSyAqTQdnTTM4LwyDP-P_QrXFXyD7OJqU5QE"
// export const  DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
// export const SCOPES = "https://www.googleapis.com/auth/calendar.events"


export const  CLIENT_ID = "849321740104-okt6sl86r03uq70r62ktmournvslcqtp.apps.googleusercontent.com"
export const  API_KEY = "AIzaSyAPMstdHWRBLjkhBAUX-gH6siKshO2YYBg"
export const  DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
export const SCOPES = "https://www.googleapis.com/auth/calendar.events"

