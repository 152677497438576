import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import arraySort from 'array-sort';
import _ from 'lodash';
import moment from 'moment';
//import moment from 'moment';
import NotificationCard from "../../notifications/cards/NotificationCard";
import { Calendar01Icon } from 'hugeicons-react';

//-> action imports

//-> components imports


class UpcomingInterviews extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            notifications: this.props.notifications,
            accountInfo: this.props.accountInfo,
            upcomingInterviews:this.props.upcomingInterviews,
            fetchingUpcomingInterviews:this.props.fetchingUpcomingInterviews,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.notifications !== this.props.notifications) {
            this.setState({ notifications: nextProps.notifications })
        }

        if (nextProps.accountInfo !== this.props.accountInfo) {
            this.setState({ accountInfo: nextProps.accountInfo })
        }
        if (nextProps.upcomingInterviews !== this.props.upcomingInterviews) {
            this.setState({ upcomingInterviews: nextProps.upcomingInterviews })
        }
        if (nextProps.fetchingUpcomingInterviews !== this.props.fetchingUpcomingInterviews) {
            this.setState({ fetchingUpcomingInterviews: nextProps.fetchingUpcomingInterviews })
        }
    }

    renderNotificationsQuantity(){
        const { notifications } = this.state;

        const notificationsQuantity = _.map(notifications);

        if (notificationsQuantity.length !== 0){
            return <h3>{notificationsQuantity.length}</h3>
        } else {
            return <h3>0</h3>
        }
    }

    renderNotifications() {
        //extract state
        const {  upcomingInterviews, fetchingUpcomingInterviews } = this.state;
            

        //check that notifications is not empty
        if (!(_.isEmpty(upcomingInterviews))) {
            
            return(
                <div>
                    {
                        upcomingInterviews.map(interview => {

                            let seconds;
                            interview.interviewDate.seconds ? seconds = interview.interviewDate.seconds : seconds = interview.interviewDate._seconds;
                    
                            const interviewDateSec = moment.unix(seconds);
                            const interviewDate = interviewDateSec.format("ddd, Do MMM YYYY");
                            
                            return (
                                <div key={interview.interviewID} >

                            <div className={"trainingCardContainer"} style={{marginLeft:"20px"}}>
                                    {/* render posts name */}
                                    <h4> {interview.name}<span style={{ color: "darkGray", fontSize: "0.8rem" }}>  | {interview.jobPostName}</span></h4>
                                    <div className={"trainingListStatus"}>
                                        <Calendar01Icon className={"calendarIcon"}/>                             
                                        <p id={"cardDates"}>{interviewDate}</p>
                                       
                                    </div>
                                </div>
                                    
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return <h3 className={"adminAlert"}>There are currently no interviews scheduled</h3>;
        }
    }

    render(){

        return (
            <div className={"parentContainer"}>
                <div className={"notificationHeaderBox"}>
                    <h4>Upcoming Interviews</h4>
                    {/* <h5>Upcoming Interviews</h5> */}
                </div>

                <div className={"notificationInfoBox"}>
                    {/* Render notification UpcomingInterviews Details here */}
                    {this.renderNotifications()}
                </div>   
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { notifications } = state.notifications;
    const { accountInfo } = state.settings;
    const { upcomingInterviews,fetchingUpcomingInterviews } = state.posts;
        

    return {
        notifications,
        accountInfo,
        upcomingInterviews,
        fetchingUpcomingInterviews
        
    }
};


export default connect(mapStateToProps, )(UpcomingInterviews);