import React from "react";
import { connect } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import _ from "lodash";
import {message, Popconfirm} from "antd";
import { Home01Icon, NotificationCircleIcon, PropertyDeleteIcon } from 'hugeicons-react';

//action imports
import { deleteNotification } from "../../../actions/NotificationsActions";

class NotificationCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: this.props.notification,
            profile: this.props.profile
        }

        this.deleteNotification = this.deleteNotification.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.notification !== this.props.notification) {
            this.setState({ notification: nextProps.notification });
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    deleteNotification(notificationID) {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            //extract role of profile
            const { role } = profile;

        }
    }

    onCancel() {
        message.info("Deletion is canceled");
    }

    checkAccess() {
        //extract state
        const { profile, notification } = this.state;

        if(!(_.isEmpty(profile))) {
            //extract role of profile
            const { role } = profile;

            if(role === "admin") {
                return (
                    <Popconfirm
                        title="Are you sure to delete this notification?"
                        onConfirm={() => this.deleteNotification(notification.notificationID)}
                        onCancel={() => this.onCancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{textAlign: 'center'}}><PropertyDeleteIcon size={30} className={"trashIcon2"}/></div>
                    </Popconfirm>
                );
            }
        }
    }

    render() {
        //extract state
        const { notification } = this.state;


       if (!(_.isEmpty(notification))) {
           let seconds;
           notification.date.seconds ? seconds = notification.date.seconds : seconds = notification.date._seconds;
           const time = moment.unix(seconds);

           return(
               <div className={"notificationInfo"}>
                   <div><h5> <NotificationCircleIcon size={30} color="#0793d9" /></h5></div>

                   <div>
                       <h4>{notification.notificationTitle}</h4>
                       <p>{notification.description}</p>
                       <p id={"cardDates"}>{time.format("ddd, Do MMM YYYY")}</p>
                   </div>

                   { this.checkAccess() }

                   {/*<div><h5><FontAwesomeIcon onClick={() => this.deleteNotification(notification.notificationID)} icon="times" size="1x" color="#ff0000" className={"notificationClearButton"} /></h5></div>*/}
               </div>
           );
       }
    }
}

const mapStateToProps = state => {
    const { profile } = state.profile;

    return {
        profile
    }
};

export default connect(mapStateToProps, { deleteNotification })(NotificationCard);