import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import _ from "lodash";

//actions import
import { fetchGenderBasedInterviewOffersByIndustry, findCommonSkills, fetchJobPostStats, fetchInterviewsInfo,fetchInterviewsGradingRatio, fetchInterviewResponseTime,  averageTimeToHire, fetchCandidatesGender } from '../../actions/ReportActions';


//common imports
import {Spinner} from "../common/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DoubleBarGraph from './subComponents/DoubleBarGraph';
import {AnalyticsUpIcon} from 'hugeicons-react';
import LineGraph from './subComponents/SingleBarGraph';
import GeneralTab from './subComponents/GeneralTab';
import DistributionLevel from './subComponents/DistributionLevel';

//components import


class Analytics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            industryCounts: this.props.industryCounts,
            profile: this.props.profile,
            commonSkills:this.props.commonSkills,
            postsPerPosition:this.props.postsPerPosition,
            jobCounts:this.props.jobCounts,
            interviewedCandidates:this.props.interviewedCandidates,
            offeredInterviews:this.props.offeredInterviews,
            fetchingGenderInfo:this.props.fetchingGenderInfo,
            fetchingCommonSkills:this.props.fetchingCommonSkills,
            fetchingCandidatesCount:this.props.fetchingCandidatesCount,
            generalAverageTimeToHire: this.props.generalAverageTimeToHire,
            candidatesGender: this.props.candidatesGender,
            averageResponseTime:this.props.averageResponseTime,
            isFetchingTime:this.props.isFetchingTime
        }
    }

    componentDidMount() {

    this.props.fetchGenderBasedInterviewOffersByIndustry();
    this.props.findCommonSkills();
    this.props.fetchJobPostStats();
    this.props.fetchInterviewsInfo()
    this.props.fetchInterviewResponseTime()
    this.props.averageTimeToHire();
    this.props.fetchCandidatesGender();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                industryCounts:nextProps.industryCounts,
                profile: nextProps.profile,
                commonSkills:nextProps.commonSkills,
                postsPerPosition:nextProps.postsPerPosition,
                jobCounts:nextProps.jobCounts,
                interviewedCandidates:nextProps.interviewedCandidates,
                offeredInterviews:nextProps.offeredInterviews,
                fetchingGenderInfo:nextProps.fetchingGenderInfo,
                fetchingCommonSkills:nextProps.fetchingCommonSkills,
                fetchingCandidatesCount:nextProps.fetchingCandidatesCount,
                generalAverageTimeToHire: nextProps.generalAverageTimeToHire,
                candidatesGender: nextProps.candidatesGender,
                averageResponseTime:nextProps.averageResponseTime,
                isFetchingTime:nextProps.isFetchingTime
            });
        }
    }

    renderComponent() {
        //extract tabPane
        const { TabPane } = Tabs;

        //extract state
        const { profile ,interviewedCandidates,offeredInterviews, fetchingGenderInfo,fetchingCommonSkills, fetchingCandidatesCount, generalAverageTimeToHire, candidatesGender, isFetchingTime, averageResponseTime } = this.state;
    

        if(!(_.isEmpty(profile))) {
            if (profile.role === "admin" || profile.role === "superAdmin") {
                return (
                    <div style={{margin: '1.2rem 1.2rem 0.5rem 1.2rem'}}>
                        <Tabs defaultActiveKey="1" style={{fontFamily: 'ramblaBold, sans-serif', color: "#000"}}>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>General</span>} key="1">
                                <GeneralTab 
                                    totalJobPosts={ this.state.jobCounts}
                                    postsPerPosition={this.state.postsPerPosition}
                                    interviewedCandidates={interviewedCandidates}
                                    offeredInterviews = {offeredInterviews}
                                    fetchingCandidatesCount = {fetchingCandidatesCount}
                                    generalAverageTimeToHire = {generalAverageTimeToHire}
                                    candidatesGender = {candidatesGender}
                                    isFetchingTime ={isFetchingTime}
                                    averageResponseTime = {averageResponseTime}
                                />
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Distribution-Level</span>} key="2">
                                <h3 style={{textAlign:"center", }}>Distribution Level</h3>
                                <DistributionLevel />
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Industry-Gender</span>} key="3">
                            <DoubleBarGraph industryCounts ={this.state.industryCounts} fetchingGenderInfo={this.state.fetchingGenderInfo} />
                            </TabPane>
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Education</span>} key="4">
                                {/* <Education/> */}
                            </TabPane>
                            
                            <TabPane tab={<span style={{ fontSize: '17px', fontWeight: 'bold', fontFamily: 'ramblaBold', height: '23px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Common Skills</span>} key="5">
                                <LineGraph mostCommonSkills = {this.state.commonSkills.mostCommonSkills} fetchingCommonSkills ={fetchingCommonSkills} />
                            </TabPane>
                        </Tabs>
                    </div>
                );
            } else {
                return (
                    <div style={{margin: '1.2rem 1.2rem 0.5rem 1.2rem'}}>
                        <p style={{ color: "#000"}}>Unauthorized access</p>
                    </div>
                );
            }
        } else {
            return <p style={{ color: "#fff"}}>No profile</p>
        }
    }

    render() {
        return(
            <div className={'reportsContainer'}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <AnalyticsUpIcon size={30} style={{ color: "#000"}} />
                        <h3>Analytics</h3>
                    </div>
                </div>

                <div className={"reportsTicketsBox"}>
                    {this.renderComponent()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { industryCounts, commonSkills, postsPerPosition, jobCounts ,interviewedCandidates, candidatesGender,
        offeredInterviews, fetchingGenderInfo,fetchingCommonSkills, fetchingCandidatesCount, generalAverageTimeToHire, isFetchingTime,
        averageResponseTime,} = state.reports
    const { profile } = state.profile;

    return {
        profile,
        industryCounts,
        commonSkills,
        postsPerPosition,
        jobCounts,
        interviewedCandidates,
        offeredInterviews, 
        fetchingGenderInfo,
        fetchingCommonSkills,
        fetchingCandidatesCount,
        generalAverageTimeToHire,
        candidatesGender,
        averageResponseTime,
        isFetchingTime

    }
};

export default connect(mapStateToProps,{fetchGenderBasedInterviewOffersByIndustry, findCommonSkills,averageTimeToHire,fetchCandidatesGender, fetchJobPostStats, fetchInterviewsInfo, fetchInterviewsGradingRatio, fetchInterviewResponseTime})(Analytics);

