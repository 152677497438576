import {
    FETCH_SYSTEM_INFO_SUCCESSFUL,
    FETCH_SYSTEM_INFO,
    FETCH_SYSTEM_INFO_FAILED, FETCH_SYSTEM_INFO_EMPTY, SET_GENERAL_APP,
} from "../actions/Types";


const INITIAL_STATE = {
    generalApp: null,
    generalDatabase: null,
    generalStorage: null,

    //fetched objects
    systemInfo: {},
    systemInfoIsEmpty: null,
    systemInfoLoading: false,

};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const SystemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_GENERAL_APP:
            return { ...state, generalApp: action.payload.generalApp, generalDatabase: action.payload.generalDatabase, generalStorage: action.payload.generalStorage };

        //fetch reducers
        case FETCH_SYSTEM_INFO:
            return {...state, systemInfoLoading: true };
        case FETCH_SYSTEM_INFO_SUCCESSFUL:
            return {...state, systemInfoLoading: false, systemInfo: deepClone(action.payload )};
        case FETCH_SYSTEM_INFO_EMPTY:
            return {...state, systemInfoLoading: false, systemInfoIsEmpty: true };
        case FETCH_SYSTEM_INFO_FAILED:
            return {...state, systemInfoLoading: false };
        default:
            return state;
    }
};

export default SystemReducer;
