import React, { Component } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import firebase from 'firebase';
import {SEO} from "../../common";

class BlogPostBase extends Component {
	state = {
		title: '',
		content: '',
		description: '',
		createdAt: null,
	};

componentDidMount() {
		const { id } = this.props;
		this.fetchBlog(id);
	}

	fetchBlog = (id) => {
		firebase.firestore().collection('blogs').doc(id).get().then((doc) => {
			if (doc.exists) {
				this.setState({ ...doc.data() });
			} else {
				console.log("No such document!");
			}
		}).catch((error) => {
			console.log("Error getting document:", error);
		});
	};

	render() {
		const { title, content, description, createdAt } = this.state;
		return (
			<div className={"blog"}>
				<SEO title={title} description={description}/>
				<h1>{title}</h1>
				<p>{createdAt && new Date(createdAt.seconds * 1000).toLocaleDateString()}</p>
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		);
	}
}

// Wrapper functional component to use hooks
function BlogPost(props) {
	const navigate = useNavigate();
	const { id } = useParams();

	return <BlogPostBase {...props} navigate={navigate} id={id} />;
}

export default BlogPost;