import firebase from "firebase";
import "firebase/firestore";
import {
    FETCH_MY_PROFILE,
    FETCH_MY_PROFILE_FAILED,
    FETCH_MY_PROFILE_SUCCESSFUL,
    EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED,
    EDIT_PROFILE_EMPLOYEE_DETAILS,
    EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL,
    EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED,
} from "./Types";

import {message} from "antd";


//------------------------------------------------------------------------ PROFILE ------------------------------------------------------------------------------

//changes on some information on the profile
export const profileFieldChanged = ({prop, value}) => {

    return {
        type: EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED,
        payload: {prop, value}
    }
};


export const fetchMyProfile = () => {

    return(dispatch) => {
        //fetch customer profile
        dispatch({ type: FETCH_MY_PROFILE });

        //fetch employee id
        const userID = firebase.auth().currentUser.uid;

        firebase.firestore().collection("users").doc("admins").collection(userID).doc("public").collection("account").doc("info")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();

                    dispatch({
                        type: FETCH_MY_PROFILE_SUCCESSFUL,
                        payload: data
                    });
                } else {
                    console.log("account doc doesn't exists");
                    dispatch({ type: FETCH_MY_PROFILE_FAILED })
                }
            }, function (e) {
                console.log(e);
                console.log("we have a profile error");
            })

    }
};

export const updateProfileDetails = ({ userID, bankName,accountName,accountNumber, address, phone,homePhone, workEmail, email, emergencyName, emergencyAddress,emergencyPhoneNumber, emergencyRelationship, linkedinUrl }) => {

    return(dispatch) => {

        dispatch({type: EDIT_PROFILE_EMPLOYEE_DETAILS});

        firebase.firestore().collection("users").doc("admins").collection(userID).doc("public").collection("account").doc("info")
            .update({ bankName, accountName, accountNumber, address, phone, homePhone, workEmail, email, emergencyName, emergencyAddress, emergencyPhoneNumber, emergencyRelationship, linkedinUrl })
            .then(() => {
                firebase.firestore().collection("adminBucket").doc(userID)
                .update({  email })
                .then(() => {
                    dispatch({type: EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL});
                    message.success("updated profile info");
                })
            }).catch((error) => {
                    console.log(error);
                    dispatch({ type: EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED, payload: `Failed to create ${userID}'s account` });
        });
    }
};