import React, { useState } from "react";
import { Modal, Typography, Button ,message} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { View } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { Input } from "../../common/Input";
import { signInDev } from "../../../actions/LandingActions";
// import { addDevelopers } from "../../../actions/LandingActions";

const { Text } = Typography;

const LoginModal = ({  }) => {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [apiToken, setapiToken] = useState('');
    const [email, setEmail] = useState('');

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleSubmit = () => {
    if (!apiToken || !email) {
            message.error("Fields cannot be empty");
            return;
        }
        dispatch(signInDev(apiToken, email));
        setapiToken("");
        setEmail("");
    };
    

    const handleCancel = () => {
        setIsModalVisible(!isModalVisible);
    };


    const renderText = () => {
        return (
            <div style={{ alignItems: "center", textAlign: "center" }}>
            <span onClick={handleToggleModal} style={{ cursor: 'pointer', textDecoration:'underline', color:'whitesmoke' }}>Already registered ?</span>
        </div>
        
            
        );
    };

    const renderInputFields = () => {
        console.log('info', apiToken, email)
        return (
           
            <div>
                 <Input
                    placeholder="API Token"
                    value={apiToken}
                    onChange={(e) => setapiToken(e.target.value)}
                    style={{ marginBottom: 5 }}
                />
                <Input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                        console.log("Email value:", e.target.value);
                        setEmail(e.target.value);
                    }}
                    style={{ marginBottom: 10 }}
                />
            </div>
            
        );
    };


    return (
        <div>
            {renderText()}
            <Modal
                title=""
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={700}
                footer={[
                    <Button key="submit" type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>,
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}
            >
                {renderInputFields()}

        
            </Modal>
        </div>
    );
};

export default LoginModal;