import React from 'react';
import {connect} from 'react-redux';
import _, { bind } from "lodash";
import { List, Avatar, Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

//actions import
import { searchDB, saveSearches } from '../../actions/SearchActions';
import { Spinner } from '../common/Spinner';
import moment from 'moment';

//components import
// import Avatar from 'antd/lib/avatar/avatar';

//file imports

class RecentSearches extends React.Component {

    constructor(props){
        super(props);
        this.scrollRef = React.createRef();

        this.state = {
            profile: this.props.profile,
            recentSearches: this.props.recentSearches,
            recentSearchesLoading: this.props.recentSearchesLoading,
            isClicked: false,
            saveSearchLoading: this.props.saveSearchLoading,
            loading: this.props.loading
        };

        this.onSave = this.onSave.bind(this);
        this.onSearch = this.onSearch.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                recentSearches: nextProps.recentSearches,
                recentSearchesLoading: nextProps.recentSearchesLoading,
                saveSearchLoading: nextProps.saveSearchLoading,
                loading: nextProps.loading
            });
        }
    }

    handleScrollUp = () => {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollTop -= 100;
        }
    };

    handleScrollDown = () => {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollTop += 100;
        }
    };

    onSave(item) {
        const {
            packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
            startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
            foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
            industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
        } = item;

        if (startBirthDate !== null) {
            startBirthDate = moment(startBirthDate);
        }

        if (endBirthDate !== null) {
            endBirthDate = moment(endBirthDate);
        }

        if (yearOfCompletion !== null) {
            yearOfCompletion = moment(yearOfCompletion);
        }
        
        // console.log({
        //     packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
        //     startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
        //     foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
        //     industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
        // })
        this.props.saveSearches({
            packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
            startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
            foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
            industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
        });
    }

    onSearch(item) {
        let {
            packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
            startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
            foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
            industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,
        } = item;

        if (startBirthDate !== null) {
            startBirthDate = moment(startBirthDate);
        }

        if (endBirthDate !== null) {
            endBirthDate = moment(endBirthDate);
        }

        if (yearOfCompletion !== null) {
            yearOfCompletion = moment(yearOfCompletion);
        }

        // console.log({
        //     packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
        //     startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
        //     foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
        //     industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit
        // })
        this.props.searchDB({
            packageIndex, openForRelocation, country, region,
            jobTypeArray, workScheduleArray, gender,
            maritalStatus, startBirthDate,
            endBirthDate, certificateID, jobTitle,
            educationLevel,
            currentEnrollment, studiedAbroad, yearOfCompletion, industryID,
            currentAtWork, experience, foreignJob, language, license, militaryCountry,
            industryPrime,
            industrySec,
            positionPrime,
            positionSec,
            skill, 
            link, 
            resultsSearchLimit
        });
    }

    renderRecentSearches() {
        //extract state
        const { recentSearches, saveSearchLoading, loading } = this.state;

        if (recentSearches.length === 0) {
            return(
                <div className={"employeeDaysSummaryBox"}>
                    <p style={{  fontFamily: 'ramblaRegular', color: "#000"}}>Your recent searches will appear here</p>
                </div>
            );
        } else {
            const data = recentSearches.map((item, index) => ({
                title: item.country? item.country : "All Countries",
                description: item.skill? `${item.skill}, ${item.certificateID? `, ${item.certificateID}` : ""}, ${item.industryID? item.industryID : "Any Industry"}` : "Any Skills, Any Certification, Any Industry"
              }));
            return (
                <div className={"employeeDaysSummaryBox"}>
                    <div className="scroll-list" ref={this.scrollRef}>
                        <div className="scroll-content">
                            <List
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={(item, index) => (
                                <List.Item
                                    className="custom-list-item"
                                    actions={[
                                    <Button key="search" type="primary" onClick={() => this.onSearch(recentSearches[index])}>Search {loading? <Spinner /> : null}</Button>,
                                    <Button key="save" type="primary" onClick={() => this.onSave(recentSearches[index])}>Save {saveSearchLoading? <Spinner /> : null}</Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                    title={
                                        // <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{item.title}</div>
                                        <div style={{ fontSize: '18px', fontFamily: 'ramblaRegular', fontWeight: 'bold', height: '25px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.title}</div>
                                    }
                                    description={
                                        // <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.description}</div>
                                        <div style={{ fontSize: '17px', fontFamily: 'ramblaRegular', fontWeight: 'bold', height: '25px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</div>
                                    }
                                    />
                                </List.Item>
                                )}
                            />
                        </div>

                        {/* <div className="scroll-icons">
                            <div className="scroll-icon scroll-up" onClick={this.handleScrollUp}>
                                <FaChevronUp size={24} color="#ffb400" />
                            </div>
                            <div className="scroll-icon scroll-down" onClick={this.handleScrollDown}>
                                <FaChevronDown size={24} color="#ffb400" />
                            </div>
                        </div> */}
                    </div>
                </div>
            )
        }
    }


    renderSavedSearches() {
        //extract state
        const { recentSearches, loading } = this.state;

        if (recentSearches.length === 0) {
            return(
                <div className={"employeeDaysSummaryBox"}>
                    <p style={{  fontFamily: 'ramblaRegular', color: "#000"}}>Your recent searches will appear here</p>
                </div>
            );
        } else {
            const data = recentSearches.map((item, index) => ({
                title: item.country? item.country : "All Countries",
                description: item.skill? `${item.skill}, ${item.certificateID? `, ${item.certificateID}` : ""}, ${item.industryID? item.industryID : "Any Industry"}` : "Any Skills, Any Certification, Any Industry"
              }));
            return (
                <div className={"employeeDaysSummaryBox"}>
                    <div className="scroll-list" ref={this.scrollRef}>
                        <div className="scroll-content">
                            <List
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={(item, index) => (
                                <List.Item
                                    className="custom-list-item"
                                    actions={[
                                        <Button key="search" type="primary" onClick={() => this.onSearch(recentSearches[index])}>Search {loading? <Spinner /> : null}</Button>,
                                    ]}
                                >
                                    <List.Item.Meta
                                    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                    title={
                                        // <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{item.title}</div>
                                        <div style={{ fontSize: '18px', fontFamily: 'ramblaRegular', fontWeight: 'bold', height: '25px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.title}</div>
                                    }
                                    description={
                                        // <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.description}</div>
                                        <div style={{ fontSize: '17px',  fontFamily: 'ramblaRegular', fontWeight: 'bold', height: '25px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</div>
                                    }
                                    />
                                </List.Item>
                                )}
                            />
                        </div>

                        {/* <div className="scroll-icons">
                            <div className="scroll-icon scroll-up" onClick={this.handleScrollUp}>
                                <FaChevronUp size={24} color="#ffb400" />
                            </div>
                            <div className="scroll-icon scroll-down" onClick={this.handleScrollDown}>
                                <FaChevronDown size={24} color="#ffb400" />
                            </div>
                        </div> */}
                    </div>
                </div>
            )
        }
    }

    handleDivClick = () => {
        this.setState(prevState => ({
          isClicked:!prevState.isClicked,
        }));
    };
      

    render() {
        //extract state
        const { profile } = this.state;

        let companyName = '';
        //check that my profile is not empty
        if (!(_.isEmpty(profile))) {
            //check that initials is present
            if (`companyName` in profile) {
                companyName = profile.companyName;
            }
        }

        return (
            <div className={"employeeSummaryContainer"}>
                <div className={"employeeInfoBox"}>
                    {
                        this.state.isClicked ?
                        <div className={"employeeNameBox"} onClick={this.handleDivClick}>
                            <Avatar style={{backgroundColor: '#056899',  margin: "1rem 0" }}>
                                {companyName[0]}
                            </Avatar>
                            <h4 style={{  margin: "1.3rem 0", fontSize: '20px', }}>Recent Search</h4>
                            <span style={{ float: 'right', marginLeft: 'auto', alignItems: 'center', fontSize: '30px' }}>
                                <DownOutlined />
                            </span>
                        </div> :
                        <div className={"employeeNameBox"} onClick={this.handleDivClick}>
                            <Avatar style={{backgroundColor: '#056899',  margin: "1rem 0" }}>
                                {companyName[0]}
                            </Avatar>
                            <h4 style={{  margin: "1.3rem 0", fontSize: '20px', }}>Saved Search</h4>
                            <span style={{ float: 'right', marginLeft: 'auto', alignItems: 'center', fontSize: '30px' }}>
                                <DownOutlined/>
                            </span>
                        </div>
                    }

                    {
                        this.state.isClicked ?
                        this.renderRecentSearches() :
                        this.renderSavedSearches()
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { recentSearches, recentSearchesLoading } = state.home;
    const {saveSearchLoading, loading} = state.search;
    const { profile } = state.profile;

    return {
        profile,
        recentSearches,
        recentSearchesLoading,
        saveSearchLoading,
        loading
    }
};

export default connect(mapStateToProps, { searchDB, saveSearches })(RecentSearches);