import React from "react";
import { Table, Button, Modal, message } from 'antd';

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class CertificatesCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            certificates: this.props.certificates,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                certificates: nextProps.certificates,
            });
        }
    }

    renderCertificate(){
        const { certificates } = this.state;

        const columns = [
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.title}</div>
                ),
            }
        ];

         return (
            <Table dataSource={certificates} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderCertificate()}
            </div>
        );
    }
}

export default CertificatesCard;