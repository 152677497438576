import {
    FETCH_MY_SUPPORT_TICKETS_SUCCESSFUL, FETCH_SUPPORT_TICKETS_SUCCESSFUL,
    SEND_SUPPORT_TICKET,
    SEND_SUPPORT_TICKET_FAILED,
    SEND_SUPPORT_TICKET_SUCCESSFUL,
    SUPPORT_DETAILS_CHANGED,
    UPDATE_SUPPORT_TICKET,
    UPDATE_SUPPORT_TICKET_FAILED,
    UPDATE_SUPPORT_TICKET_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {
    supportLoading: false,
    ticketSubject: "",
    relatedService: "",
    priority: "",
    ticketMessage: "",

    manageStatusLoading:false,

    //fetch properties
    mySupportTickets: {},
    supportTickets: {},
};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const SupportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUPPORT_DETAILS_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value };


            //send ticket reducers
        case SEND_SUPPORT_TICKET:
            return { ...state, supportLoading: true };
        case SEND_SUPPORT_TICKET_SUCCESSFUL:
            return { ...state, supportLoading: false, ticketSubject: "", relatedService: "", priority: "", ticketMessage: "" };
        case SEND_SUPPORT_TICKET_FAILED:
            return { ...state, supportLoading: false };

        case UPDATE_SUPPORT_TICKET:
            return { ...state, manageStatusLoading: true };
        case UPDATE_SUPPORT_TICKET_SUCCESSFUL:
            return { ...state, manageStatusLoading: false,};
        case UPDATE_SUPPORT_TICKET_FAILED:
            return { ...state, manageStatusLoading: false };


            //fetch my support tickets
        case FETCH_MY_SUPPORT_TICKETS_SUCCESSFUL:
            return { ...state, mySupportTickets: deepClone(action.payload) };

            //fetch all support tickets
        case FETCH_SUPPORT_TICKETS_SUCCESSFUL:
            return { ...state, supportTickets: deepClone(action.payload) };
        default:
            return state;
    }
};

export default SupportReducer;