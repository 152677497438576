import React from 'react';
import {connect} from 'react-redux';
import {message, Modal} from "antd";
import {ClipLoader} from "react-spinners";
import _ from 'lodash';

//actions import
import { termsAndConditionsChanged, saveTermsAndConditions} from "../../../actions/SettingsActions";

//components import
import { Input } from "../../common/Input";
import {Button} from "../../common/Button";

class AddTermsAndConditions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            docTitle: this.props.docTitle,
			docFile: this.props.docFile,
            profile: this.props.profile,

            fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
        };

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                docTitle: nextProps.docTitle,
                docFile: nextProps.docFile,
                termsLoading: nextProps.termsLoading,
                termsSuccess: nextProps.termsSuccess,
                termsFailed: nextProps.termsFailed,
                fetchedTermsAndConditions: nextProps.fetchedTermsAndConditions
            })
        }
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onSaveTermsAndConditions() {
        //extract state
        const { docTitle, docFile } = this.state;

        if (docTitle && docFile) {
            this.props.saveTermsAndConditions({ docTitle, docFile});
        } else {
            message.warning("Kindly fill all required fields");
        }
    }

    renderTermAndConditionsButton() {
        const {termsLoading} = this.state;

        if (termsLoading) {
            return (
                <div>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={"Save"}
                        onPress={() => this.onSaveTermsAndConditions() }
                    />
                </div>
            );
        }
    }

    checkAccess(){
        //-> extract state
        const { profile, fetchedTermsAndConditions } = this.state;
        //- check access level
        if(!(_.isEmpty(profile))) {
            //-> extract user role
            const {role} = this.state.profile;

            if (role === 'superAdmin') {
                return (
                    <div className={'settingsAssetsBox'}>
                        <div className={"addAssetsSettingsBox"}>
                            <h4 style={{ color: "black", fontFamily: "ramblaBold" }} >Add Terms and Conditions</h4>

                            <div className={""}>
                                <div>
                                    <Input
                                        label={"Terms & Conditions Title"}
                                        type={'text'}
                                        onChange={e => this.props.termsAndConditionsChanged({prop: 'docTitle', value: e.target.value})}
                                        value={this.state.docTitle}
                                    />
                                </div>

                                <div className={"addFileInputFile"}>
                                    <input
                                        type={'file'}
                                        accept={"application/pdf"}
                                        onChange={e => this.props.termsAndConditionsChanged({prop: 'docFile', value: e.target.files[0]})}
                                        style={{ margin:"20px" }}
                                        // value={this.state.docFile }
                                    />
                                </div>
                            </div>

                            <div className={'termsButtonBox'}>
                                {this.renderTermAndConditionsButton()}
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    renderShowButton() {
        //-> extract state
        const { profile } = this.state;
        //- check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if( role === 'superAdmin') {
                return(
                    <div className={"addJobOpeningBox"}>
                        <div style={{ margin: "1rem"}}>
                            <Button
                                text={"Add Legal File"}
                                onPress={() => this.showModal()}
                            />
                        </div>
                    </div>
                );
            }
        }
    }

    render() {

        return (
            <div>
                {this.renderShowButton()}
                <Modal
                    title={""}
                    open={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}

                    footer={null}
                    width={900}
                >
                    {this.checkAccess()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { docTitle, docFile, termsLoading,termsSuccess,termsFailed,fetchedTermsAndConditions} = state.settings;
    const { profile } = state.profile;

    return {
        docTitle,
        profile,
        termsLoading,
        termsSuccess,
        termsFailed,
        docFile,
        fetchedTermsAndConditions,
    }
};

export default connect(mapStateToProps, { termsAndConditionsChanged, saveTermsAndConditions})(AddTermsAndConditions);