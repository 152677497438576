import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class InternationalExperience extends Component {
	constructor(props) {
		super(props);
		this.state = {
			foreignJob: this.props.foreignJob
		}

		this.handleChange = this.handleChange.bind(this);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				foreignJob: nextProps.foreignJob
			});
		}
	}

	handleChange(value) {
		if (value === undefined || value === "") {
			// Dispatch an action to set foreignJob to null
			this.props.searchFieldChanged({ prop: "foreignJob", value: null });
		} else {
			// Otherwise, proceed as usual
			this.props.searchFieldChanged({ prop: "foreignJob", value });
		}
	};

	render() {
		const { foreignJob } = this.state;

		return (
			<div>
				<p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>International Experience</p>
				<Select value={foreignJob} allowClear onChange={this.handleChange} placeholder="International Job" style={{ width: "100%", borderColor: '#000' }}>
					<Option value={true}>Yes</Option>
					<Option value={false}>No</Option>
				</Select>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { foreignJob } = state.search;

	return {
		foreignJob
	};
};

const mapDispatchToProps = {
	searchFieldChanged, // Assuming you have an action creator to update the status
};

export default connect(mapStateToProps, mapDispatchToProps)(InternationalExperience);