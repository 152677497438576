import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

//action imports
import { fetchNotifications } from "../../actions/NotificationsActions";

//component imports
import ManageNotifications from './subComponents/ManageNotifications';
import ViewNotifications from './subComponents/ViewNotifications';
import {Button} from "../common/Button";
import {NotificationCircleIcon} from "hugeicons-react";

class Notifications extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            notificationFetchLimit: this.props.notificationFetchLimit,
            profile: this.props.profile,
        };

        this.viewMore = this.viewMore.bind(this);
    }

    componentWillMount() {
        //extract state
        const { notificationFetchLimit } = this.state;
        const { profile} = this.state;

        if(!(_.isEmpty(profile))){

            this.props.fetchNotifications({notificationFetchLimit});
        }
       
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.notificationFetchLimit !== this.props.notificationFetchLimit) {
            this.setState({ notificationFetchLimit: nextProps.notificationFetchLimit });
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    viewMore() {
        //extract state
        const { notificationFetchLimit } = this.state;
        const { profile} = this.state;
        const addedLimit = notificationFetchLimit + 10;
        if(!(_.isEmpty(profile))){
            //fetch without location
            this.props.fetchNotifications({notificationFetchLimit: addedLimit});
        }
    }

    render() {
        return(
            <div className={"notificationContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <NotificationCircleIcon size={30} style={{color: '#000'}} />
                        <h3>Notifications</h3>
                    </div>
                </div>

                <div className={"notificationManageBox"}>
                    <ManageNotifications/>
                </div>

                <div className={"notificationViewBox"}>
                    <ViewNotifications/>
                    <div className={"allCelebrationsButtonBox"}>
                        <Button
                            text={"View More"}
                            onPress={ () => this.viewMore() }
                        />
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { notificationFetchLimit } = state.notifications;
    const { profile } = state.profile;
    return {
        notificationFetchLimit,
        profile,
    }
}

export default connect(mapStateToProps, { fetchNotifications })(Notifications);