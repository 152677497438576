import React from "react";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import { Calendar01Icon } from "hugeicons-react";

class PostCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            post: this.props.post
        };

        this.renderStatus = this.renderStatus.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                post: nextProps.post
            });
        }
    }

    renderStatus() {
        //extract state
        const { datePosted, deadline } = this.state.post;

        let startSeconds;
        datePosted.seconds ? startSeconds = datePosted.seconds : startSeconds = datePosted._seconds;
        const start = moment.unix(startSeconds);
        const today = moment();

        let endSeconds;
        deadline.seconds ? endSeconds = deadline.seconds : endSeconds = deadline._seconds;
        const end = moment.unix(endSeconds);

        if(start.isAfter(today)) {
            return <h5>Upcoming</h5>
        }

        if((today.isSameOrAfter(start) && (today.isSameOrBefore(end)))) {
            return <h5>Ongoing</h5>
        }

        if(end.isBefore(today)) {
            return <h5>Past Deadline</h5>
        }
    }


    render() {
        //extract state
        const { post } = this.state;

        let seconds;
        post.deadline.seconds ? seconds = post.deadline.seconds : seconds = post.deadline._seconds;

        const deadlineDateSec = moment.unix(seconds);
        const deadlineDate = deadlineDateSec.format("ddd, Do MMM YYYY");

        return(
            <div className={"trainingCardContainer"}>
                {/* render posts name */}
                <h4>{post.postTitle}<span style={{ color: "darkGray", fontSize: "0.8rem" }}>  | {post.companyLocation.country}, {post.companyLocation.region}</span></h4>
                <h4 style={{ color: "#ffb400"}}>{post.companyName}</h4>

                <div className={"trainingListStatus"}>
                    <Calendar01Icon className={"calendarIcon"}/>
                    {/* render posts date here */}
                    <p id={"cardDates"}>{deadlineDate}</p>
                    {/* render posts status here */}
                    { this.renderStatus() }
                </div>
            </div>
        );
    }

}

export default PostCard;